@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-credit-form {
  width: 383px;
  min-height: 330px;
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 48px 40px; }
  .r-credit-form .title {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.78);
    text-align: center;
    line-height: 22px;
    margin-bottom: 11px; }
  .r-credit-form .tag {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #a3a3a3;
    text-align: center;
    line-height: 14px; }
  .r-credit-form .img-verify {
    overflow: hidden;
    margin: 6px 0px; }
    .r-credit-form .img-verify .brush-code {
      width: 142px;
      float: left; }
    .r-credit-form .img-verify .brush-wrapper {
      width: 140px;
      height: 40px;
      display: inline-block;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin-left: 6%; }
      .r-credit-form .img-verify .brush-wrapper .brush-img {
        width: 100px;
        float: left; }
      .r-credit-form .img-verify .brush-wrapper .brush-refresh {
        width: 38px;
        height: 38px;
        float: right;
        background: url(//img1.rrcimg.com/_next/static/src/assets/refresh_8gdJ5.png);
        cursor: pointer; }
  .r-credit-form .phone,
  .r-credit-form .code {
    height: 48px;
    margin-bottom: 10px; }
  .r-credit-form .phone {
    margin-top: 31px; }
    .r-credit-form .phone .ant-input-suffix .send {
      color: #ff6b23;
      font-size: 14px;
      cursor: pointer; }
  .r-credit-form .error-msg {
    color: #ff6b23;
    font-size: 14px;
    margin-top: -10px; }
  .r-credit-form .submit-btn {
    background: #fd521d;
    border-radius: 3px;
    height: 48px;
    font-size: 18px;
    color: #fff;
    line-height: 48px; }
