@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-credit-apply {
  width: 289px;
  height: 110px;
  padding: 60px 0 0 10px;
  background-color: #fff;
  border: 4px solid #fff;
  box-shadow: 2px 2px #eee;
  background-image: url(//img1.rrcimg.com/_next/static/src/assets/installment_1OmTE.png);
  background-position: 0px 0px;
  background-size: 289.000px 110.000px; }
  .r-credit-apply.apply-fixed {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1; }
  .r-credit-apply .phone {
    width: 139px;
    height: 38px;
    line-height: 38px;
    padding-left: 6px;
    vertical-align: bottom; }
  .r-credit-apply .err {
    position: absolute;
    z-index: 1;
    top: 26px;
    left: 120px;
    color: #fd6f1a;
    font-size: 12px;
    margin: 10px 0; }
  .r-credit-apply .btn {
    height: 38px;
    line-height: 38px;
    background-color: #fd521d;
    font-size: 16px;
    border-radius: 0px; }
