@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-container___32_EZ {
  width: 1200px;
  margin: 0 auto; }
  .r-container___32_EZ .r-container__inner {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #848f9a; }
  .r-container___32_EZ .r-container__aboutus {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__aboutus h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__aboutus p {
      font-size: 14px;
      color: #000;
      padding-top: 20px; }
  .r-container___32_EZ .r-container__license {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__license a {
      display: inline-block; }
    .r-container___32_EZ .r-container__license img {
      display: block;
      width: 600px;
      user-select: none; }
  .r-container___32_EZ .r-container__law {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__law h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__law p {
      font-size: 14px;
      color: #000; }
    .r-container___32_EZ .r-container__law .term {
      font-size: 14px; }
      .r-container___32_EZ .r-container__law .term .readme {
        font-weight: bold; }
      .r-container___32_EZ .r-container__law .term span {
        margin-left: 2em;
        display: block; }
  .r-container___32_EZ .r-container__contact {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__contact h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__contact .contact-content {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 40px; }
      .r-container___32_EZ .r-container__contact .contact-content .contact-info .label {
        width: 5em;
        display: inline-block;
        text-align: right; }
    .r-container___32_EZ .r-container__contact .weixin {
      float: left;
      width: 300px;
      padding: 13px 0 15px; }
      .r-container___32_EZ .r-container__contact .weixin span {
        float: left;
        padding-right: 130px; }
      .r-container___32_EZ .r-container__contact .weixin img {
        float: left;
        margin-right: 10px;
        width: 57px;
        height: 57px; }
    .r-container___32_EZ .r-container__contact .weibo {
      float: left; }
    .r-container___32_EZ .r-container__contact .map {
      width: 500px;
      height: 400px; }
  .r-container___32_EZ .r-container__probity {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__probity h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__probity .info {
      font-size: 14px;
      color: #000; }
      .r-container___32_EZ .r-container__probity .info p {
        margin: 30px 0 20px; }
    .r-container___32_EZ .r-container__probity .jubao {
      height: 82px;
      width: 422px;
      padding: 20px;
      font-size: 15px;
      box-sizing: border-box;
      border: 1px solid #ececec;
      border-radius: 3px;
      margin-bottom: 10px; }
      .r-container___32_EZ .r-container__probity .jubao .label {
        height: 40px;
        line-height: 40px;
        text-align: left;
        width: 100px;
        float: left; }
      .r-container___32_EZ .r-container__probity .jubao .jubao-email {
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 280px;
        float: right;
        font-size: 24px;
        font-weight: 500; }
      .r-container___32_EZ .r-container__probity .jubao .jubao-btn {
        height: 40px;
        line-height: 40px;
        text-align: right;
        width: 280px;
        float: right; }
        .r-container___32_EZ .r-container__probity .jubao .jubao-btn a {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          text-align: center;
          width: 100px;
          color: #fff;
          background-color: #fd521d;
          border-radius: 3px; }
    .r-container___32_EZ .r-container__probity .jubao-notice {
      display: inline-block;
      margin-top: 10px;
      color: #fd521d;
      font-size: 14px;
      margin-bottom: 100px; }
  .r-container___32_EZ .r-container__privacy {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__privacy p,
    .r-container___32_EZ .r-container__privacy div {
      font-size: 14px;
      color: #000; }
    .r-container___32_EZ .r-container__privacy h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__privacy strong {
      font-weight: bold; }
    .r-container___32_EZ .r-container__privacy .term h4 {
      padding: 5px 0;
      font-weight: bold;
      font-size: 14px; }
    .r-container___32_EZ .r-container__privacy .readme {
      margin-bottom: 15px; }
    .r-container___32_EZ .r-container__privacy .table {
      border: 1px solid #333;
      width: 100%;
      margin-bottom: 20px;
      max-width: 100%;
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0; }
      .r-container___32_EZ .r-container__privacy .table th {
        font-weight: bold;
        text-align: center;
        border: 1px solid #333;
        vertical-align: middle !important; }
      .r-container___32_EZ .r-container__privacy .table th,
      .r-container___32_EZ .r-container__privacy .table td {
        line-height: 20px;
        padding: 8px;
        text-align: left;
        vertical-align: top; }
      .r-container___32_EZ .r-container__privacy .table td {
        border: 1px solid #333;
        word-break: break-all; }
    .r-container___32_EZ .r-container__privacy .highlight {
      color: #ff0000;
      display: inline;
      margin-left: 0;
      border-bottom: 1px solid #ff0000; }
  .r-container___32_EZ .r-container__terms {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__terms p,
    .r-container___32_EZ .r-container__terms div {
      margin-bottom: 0;
      font-size: 14px;
      color: #000; }
    .r-container___32_EZ .r-container__terms h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__terms .content-sy-f {
      text-align: right;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__terms .term .readme,
    .r-container___32_EZ .r-container__terms .term strong {
      font-weight: bold; }
    .r-container___32_EZ .r-container__terms .term span {
      display: block;
      margin-left: 2em; }
    .r-container___32_EZ .r-container__terms .term h4 {
      font-weight: bold;
      padding: 5px 0;
      font-size: 14px; }
  .r-container___32_EZ .r-container__career {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__career h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__career img {
      max-width: 100%; }
    .r-container___32_EZ .r-container__career p {
      padding-top: 20px;
      font-size: 14px;
      color: #000; }
    .r-container___32_EZ .r-container__career h2 {
      font-size: 16px;
      margin: 10px 0;
      font-weight: 400; }
    .r-container___32_EZ .r-container__career h3 {
      padding-top: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 16px; }
    .r-container___32_EZ .r-container__career .job-details {
      margin-bottom: 20px;
      padding: 10px 20px;
      border-left: 4px solid #dc3c00;
      font-size: 14px; }
      .r-container___32_EZ .r-container__career .job-details h4 {
        font-size: 16px;
        color: #dc3c00;
        margin: 0; }
      .r-container___32_EZ .r-container__career .job-details h5 {
        padding-top: 6px;
        margin: 0;
        font-weight: initial; }
  .r-container___32_EZ .r-container__intellectual {
    background-color: #fff;
    float: left;
    width: 1060px;
    min-height: 720px;
    padding: 30px 8px 60px 60px; }
    .r-container___32_EZ .r-container__intellectual p,
    .r-container___32_EZ .r-container__intellectual div {
      margin-bottom: 0;
      font-size: 14px;
      color: #000; }
    .r-container___32_EZ .r-container__intellectual ul,
    .r-container___32_EZ .r-container__intellectual li {
      font-size: 14px;
      color: #000; }
      .r-container___32_EZ .r-container__intellectual ul.strong,
      .r-container___32_EZ .r-container__intellectual li.strong {
        font-weight: bold; }
    .r-container___32_EZ .r-container__intellectual p.strong {
      font-weight: bold;
      margin: 10px 0; }
    .r-container___32_EZ .r-container__intellectual p.publish {
      margin: 0px 0 30px 0; }
    .r-container___32_EZ .r-container__intellectual h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
    .r-container___32_EZ .r-container__intellectual h3 {
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0; }
