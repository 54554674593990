@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-bread-crumb {
  width: 1200px;
  height: 69px;
  line-height: 69px;
  margin: 0 auto;
  overflow: hidden; }
  .r-bread-crumb .r-bread-crumb__crumbs {
    height: 69px;
    line-height: 69px;
    float: left; }
  .r-bread-crumb .r-bread-crumb__search {
    width: 263px;
    float: right;
    margin-right: 10px; }
    .r-bread-crumb .r-bread-crumb__search .search input {
      height: 36px;
      border-radius: 18px; }
    .r-bread-crumb .r-bread-crumb__search .search .ant-input-suffix {
      right: 0px; }
    .r-bread-crumb .r-bread-crumb__search .search .certain-category-icon {
      font-size: 18px;
      width: 42px;
      height: 36px;
      line-height: 40px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px; }
      .r-bread-crumb .r-bread-crumb__search .search .certain-category-icon:hover {
        background: #f96b25;
        color: #fff; }

.search-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-title .history-label i, .search-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-title .hot-label i {
  vertical-align: 0px;
  font-size: 13px;
  margin-right: 8px; }

.search-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-title .history-label .remove-all-icon, .search-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-title .hot-label .remove-all-icon {
  float: right;
  cursor: pointer; }

.search-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item.history-option:hover .remove-icon {
  display: block; }

.search-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item.history-option .remove-icon {
  display: none;
  float: right; }
  .search-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item.history-option .remove-icon i {
    font-size: 14px; }
