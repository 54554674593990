@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-intent-bargain {
  padding: 20px 80px; }
  .r-intent-bargain .logo {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin: -60px auto 16px;
    background-color: #fff;
    text-align: center; }
    .r-intent-bargain .logo .iconfont {
      font-size: 54px;
      color: #ff5317; }
    .r-intent-bargain .logo .dollar {
      display: inline-block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin: 10px;
      font-size: 50px;
      background-color: #fbd764;
      color: #fff;
      border-radius: 30px; }
  .r-intent-bargain .title {
    text-align: center;
    font-size: 24px; }
  .r-intent-bargain .car-title {
    text-align: center;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 24px 20px;
    color: #222; }
    .r-intent-bargain .car-title i {
      border-left: 2px solid #ff5317;
      margin-right: 10px; }
  .r-intent-bargain .img-verify .brush-code {
    width: 200px;
    float: left; }
  .r-intent-bargain .img-verify .brush-wrapper {
    width: 140px;
    height: 40px;
    display: inline-block;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-left: 20px; }
    .r-intent-bargain .img-verify .brush-wrapper .brush-img {
      width: 100px;
      float: left; }
    .r-intent-bargain .img-verify .brush-wrapper .brush-refresh {
      width: 38px;
      height: 38px;
      float: right;
      background: url(//img1.rrcimg.com/_next/static/src/assets/refresh_8gdJ5.png);
      cursor: pointer; }
  .r-intent-bargain .msg {
    text-align: center;
    color: #999;
    margin: 12px 0 8px 0; }
    .r-intent-bargain .msg .price {
      color: #e5601f; }
  .r-intent-bargain .priceTo,
  .r-intent-bargain .phone {
    margin-bottom: 12px; }
  .r-intent-bargain .err-msg {
    color: #f00; }
  .r-intent-bargain .submit {
    font-size: 18px; }
  .r-intent-bargain .tip {
    margin-top: 18px; }
    .r-intent-bargain .tip .check {
      float: left;
      margin-right: 5px; }
    .r-intent-bargain .tip .txt {
      overflow: hidden; }
