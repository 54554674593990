@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-search___3nPIs .r-search__content {
  width: 1200px;
  margin: 0 auto;
  min-height: 600px;
  color: #222; }
  .r-search___3nPIs .r-search__content-filter {
    position: relative;
    min-height: 203px; }
    .r-search___3nPIs .r-search__content-filter .selected {
      margin: 20px 0 0 20px; }
      .r-search___3nPIs .r-search__content-filter .selected .item {
        border: 1px solid #ffb39b;
        border-radius: 0px;
        color: #222;
        background-color: #fff;
        padding-right: 26px;
        position: relative; }
        .r-search___3nPIs .r-search__content-filter .selected .item .anticon-close {
          background-color: #ffb39b;
          color: #fff;
          position: absolute;
          font-size: 18px;
          padding: 2px;
          right: -1px;
          top: -1px; }
        .r-search___3nPIs .r-search__content-filter .selected .item:hover {
          border: 1px solid #fd521d;
          color: #fd521d; }
          .r-search___3nPIs .r-search__content-filter .selected .item:hover .anticon-close {
            background-color: #fd521d; }
      .r-search___3nPIs .r-search__content-filter .selected .remind {
        height: 25px;
        line-height: 24px;
        padding: 0 6px;
        border-radius: 0;
        background: #fd521d; }
        .r-search___3nPIs .r-search__content-filter .selected .remind img {
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          position: top;
          position: relative;
          top: -1px; }
      .r-search___3nPIs .r-search__content-filter .selected .reset {
        color: #222; }
        .r-search___3nPIs .r-search__content-filter .selected .reset:hover {
          color: #fd521d; }
    .r-search___3nPIs .r-search__content-filter .filter-sort {
      display: block;
      margin: 20px 0 10px;
      background-color: transparent;
      border: none; }
      .r-search___3nPIs .r-search__content-filter .filter-sort-item {
        float: left;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #ddd;
        border-left: none;
        padding: 0 15px; }
        .r-search___3nPIs .r-search__content-filter .filter-sort-item:first-child {
          border-left: 1px solid #ddd; }
        .r-search___3nPIs .r-search__content-filter .filter-sort-item.active {
          color: #fd521d; }
        .r-search___3nPIs .r-search__content-filter .filter-sort-item:hover {
          background-color: #eee; }
    .r-search___3nPIs .r-search__content-filter .filter-tag {
      display: block;
      line-height: 40px;
      height: 40px;
      margin-top: 10px; }
      .r-search___3nPIs .r-search__content-filter .filter-tag .ant-checkbox-wrapper {
        font-size: 14px; }
    .r-search___3nPIs .r-search__content-filter .test-h {
      height: 1000px; }
    .r-search___3nPIs .r-search__content-filter .car-list-shallow {
      min-height: 6380px; }
    .r-search___3nPIs .r-search__content-filter .empty {
      text-align: center;
      font-size: 14px; }
      .r-search___3nPIs .r-search__content-filter .empty .err-img {
        width: 351px;
        height: 122px;
        background: url(//img1.rrcimg.com/_next/static/src/assets/404_Ydjus.png) no-repeat;
        background-size: contain;
        margin: 30px auto 0; }
      .r-search___3nPIs .r-search__content-filter .empty .other {
        margin: 40px 0; }
    .r-search___3nPIs .r-search__content-filter .pagination {
      margin: 40px 0;
      text-align: center; }
