@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.config___p3pzZ {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px; }
  .config___p3pzZ .paramBox.auto {
    height: auto; }
  .config___p3pzZ .paramBox {
    position: relative;
    height: 588px;
    overflow: hidden; }
    .config___p3pzZ .paramBox .paramLink {
      float: left; }
      .config___p3pzZ .paramBox .paramLink .title {
        font-size: 18px;
        border-left: 6px solid #fd521d;
        padding-left: 5px;
        margin: 20px 0px; }
      .config___p3pzZ .paramBox .paramLink .anchor {
        width: 131px;
        overflow: hidden; }
        .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-link {
          width: 100px;
          padding: 0 0 0 0;
          text-align: center;
          line-height: 0;
          height: 32px; }
        .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-link-title {
          transition: none; }
        .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-link-active {
          font-weight: 700;
          background-color: #fd521d;
          position: relative; }
          .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-link-active:after {
            content: '';
            width: 0;
            height: 0;
            border-top: 16px solid transparent;
            border-left: 17px solid #fd521d;
            border-bottom: 16px solid transparent;
            z-index: 2;
            position: absolute;
            right: -17px; }
          .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-link-active .ant-anchor-link-title {
            color: #fff; }
        .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-ink::before,
        .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-ink-ball {
          display: none; }
        .config___p3pzZ .paramBox .paramLink .anchor .ant-anchor-link-title {
          display: inline-block;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          cursor: pointer; }
    .config___p3pzZ .paramBox .absloute {
      position: absolute;
      bottom: 0; }
    .config___p3pzZ .paramBox .paramTable {
      float: right;
      width: 1068px; }
      .config___p3pzZ .paramBox .paramTable .adviceCar {
        padding: 16px 0px;
        overflow: hidden; }
        .config___p3pzZ .paramBox .paramTable .adviceCar .adviceBtn {
          float: right;
          margin-right: 10px;
          border: 1px solid #fd521d;
          font-size: 14px;
          color: #222; }
      .config___p3pzZ .paramBox .paramTable .params_table {
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea; }
        .config___p3pzZ .paramBox .paramTable .params_table .title {
          height: 38px;
          line-height: 38px; }
        .config___p3pzZ .paramBox .paramTable .params_table .title {
          font-size: 14px;
          color: #fd521d;
          font-weight: 700;
          border-bottom: 1px solid #eaeaea;
          padding-left: 10px;
          background-color: #f9f9f9; }
        .config___p3pzZ .paramBox .paramTable .params_table .params_table_con:nth-child(odd) {
          background-color: #f9f9f9; }
        .config___p3pzZ .paramBox .paramTable .params_table .params_table_con {
          display: inline-block;
          width: 100%;
          border-bottom: 1px solid #eaeaea;
          box-sizing: border-box; }
          .config___p3pzZ .paramBox .paramTable .params_table .params_table_con .table_td {
            display: inline-block;
            box-sizing: border-box; }
          .config___p3pzZ .paramBox .paramTable .params_table .params_table_con .table_td:hover {
            background-color: #f7eded; }
          .config___p3pzZ .paramBox .paramTable .params_table .params_table_con li {
            width: 177.5px;
            height: 38px;
            line-height: 38px; }
          .config___p3pzZ .paramBox .paramTable .params_table .params_table_con .left {
            display: inline-block;
            text-align: right;
            color: #666; }
          .config___p3pzZ .paramBox .paramTable .params_table .params_table_con .right {
            display: inline-block;
            padding-left: 15px;
            color: #222;
            line-height: 38px;
            max-width: 177.5px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom; }
          .config___p3pzZ .paramBox .paramTable .params_table .params_table_con .blur {
            color: #c4c3c3;
            font-weight: 400; }
    .config___p3pzZ .paramBox .multLayers {
      width: 125px;
      height: 540px;
      position: absolute;
      top: 48px;
      z-index: 10;
      background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0.6));
      opacity: 0.6; }
    .config___p3pzZ .paramBox .multLayers.invalidBtn {
      display: none; }
    .config___p3pzZ .paramBox .seachAll.invalidBtn {
      display: none; }
    .config___p3pzZ .paramBox .seachAll {
      position: absolute;
      width: 1200px;
      height: 100px;
      background-color: #fff;
      bottom: 0;
      line-height: 100px;
      text-align: center; }
      .config___p3pzZ .paramBox .seachAll .seachAllBtn {
        display: inline-block;
        width: 214px;
        height: 36px;
        width: 212px;
        line-height: 36px;
        font-size: 16px;
        border: 1px solid #fd521d;
        color: #fd521d; }
      .config___p3pzZ .paramBox .seachAll .seachAllBtn:hover {
        background-color: #fd521d;
        color: #fff; }
      .config___p3pzZ .paramBox .seachAll .seachAllIcon {
        font-size: 8px;
        transform: translateY(-2px) rotate(-90deg); }
