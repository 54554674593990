@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-search-car-list___3qfme {
  margin-top: 10px; }
  .r-search-car-list___3qfme .car-item-card {
    float: left; }
    .r-search-car-list___3qfme .car-item-card:not(:nth-child(4n)) {
      margin-right: 12px; }
    .r-search-car-list___3qfme .car-item-card.sell {
      border: 1px solid transparent;
      padding: 1px;
      display: inline-block;
      cursor: pointer;
      margin-top: 12px; }
      .r-search-car-list___3qfme .car-item-card.sell:hover {
        border: 1px solid #fd521d; }
