@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-quality {
  width: 100%;
  font-size: 14px; }
  .r-quality-content {
    border: 10px solid #ebe3d4;
    width: 1190px;
    margin: 10px auto;
    padding: 10px;
    box-sizing: border-box; }
    .r-quality-content-inner {
      border: 1px solid #ebe3d4;
      padding: 50px; }
      .r-quality-content-inner .part1 {
        color: #c59e5a;
        text-align: center; }
        .r-quality-content-inner .part1 .icon-tools {
          display: inline-block;
          font-size: 136px;
          margin: 50px auto 30px; }
        .r-quality-content-inner .part1 .title {
          text-align: center;
          font-size: 32px;
          font-weight: 700; }
        .r-quality-content-inner .part1 .message {
          text-align: center;
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 50px;
          line-height: 26px; }
      .r-quality-content-inner .part2 {
        border-top: 1px dashed #ebe3d4;
        padding-bottom: 40px;
        color: #222222; }
        .r-quality-content-inner .part2 .title {
          text-align: center;
          font-size: 26px;
          margin-top: 40px;
          font-weight: 700; }
        .r-quality-content-inner .part2 .title-h2 {
          display: inline-block;
          font-size: 18px;
          margin: 20px 0 10px;
          font-weight: 700; }
          .r-quality-content-inner .part2 .title-h2 .icon-bangzhang {
            font-size: 28px;
            color: #c69e65;
            position: relative;
            top: 4px;
            left: -6px; }
        .r-quality-content-inner .part2 .title-h3 {
          font-size: 18px;
          font-weight: 350;
          margin: 10px 0 5px 30px; }
        .r-quality-content-inner .part2 .quality-table {
          width: 97%;
          table-layout: fixed;
          margin-left: 30px; }
          .r-quality-content-inner .part2 .quality-table tr td {
            padding: 10px;
            line-height: 26px;
            border: 1px solid #dbd1c3;
            color: #666; }
          .r-quality-content-inner .part2 .quality-table .highlight {
            background-color: #fefcf7; }
          .r-quality-content-inner .part2 .quality-table.col-2 td:nth-child(1), .r-quality-content-inner .part2 .quality-table.col-3 td:nth-child(1) {
            box-sizing: border-box;
            width: 90px; }
          .r-quality-content-inner .part2 .quality-table.col-2 td.head, .r-quality-content-inner .part2 .quality-table.col-3 td.head {
            text-align: center;
            vertical-align: top;
            color: inherit; }
          .r-quality-content-inner .part2 .quality-table .tri-corner {
            position: relative;
            overflow: hidden; }
            .r-quality-content-inner .part2 .quality-table .tri-corner::before {
              content: '';
              width: 1px;
              height: 200px;
              position: absolute;
              left: 0;
              top: 0;
              transform-origin: top;
              transform: rotate(-44deg);
              background-color: #dbd1c3; }
            .r-quality-content-inner .part2 .quality-table .tri-corner .left {
              position: absolute;
              bottom: 20px;
              left: 10px; }
            .r-quality-content-inner .part2 .quality-table .tri-corner .right {
              position: absolute;
              top: 20px;
              right: 10px; }
        .r-quality-content-inner .part2 .message {
          font-size: 14px;
          margin-left: 30px;
          color: #666;
          line-height: 26px; }
      .r-quality-content-inner .flow {
        margin: 0 auto;
        margin-bottom: 0;
        width: 500px;
        display: block; }
