@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-detail-middle-bargain {
  width: 1200px;
  height: 100px;
  border: 1px solid #dedede;
  margin: 30px auto 0;
  padding: 10px 0; }
  .r-detail-middle-bargain .r-detail-middle-bargain__car-info {
    float: left;
    margin-left: 30px; }
    .r-detail-middle-bargain .r-detail-middle-bargain__car-info h1 {
      font-size: 20px;
      font-weight: 700;
      margin: 10px 0; }
    .r-detail-middle-bargain .r-detail-middle-bargain__car-info ul {
      color: #999897;
      font-size: 14px;
      margin-top: 15px; }
      .r-detail-middle-bargain .r-detail-middle-bargain__car-info ul li {
        float: left;
        height: 15px;
        line-height: 15px;
        margin: 0 10px 10px 0; }
        .r-detail-middle-bargain .r-detail-middle-bargain__car-info ul li.info {
          font-weight: bold; }
          .r-detail-middle-bargain .r-detail-middle-bargain__car-info ul li.info:not(:first-child) {
            border-left: 1px solid #999897;
            padding-left: 10px; }
        .r-detail-middle-bargain .r-detail-middle-bargain__car-info ul li.tag {
          font-size: 12px;
          text-align: center;
          margin: 0 2px;
          padding: 0 6px;
          float: left;
          color: #fff; }
  .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area {
    float: right;
    margin-top: 14px;
    margin-right: 40px; }
    .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area .buy {
      float: right;
      color: #222;
      margin-right: 15px; }
      .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area .buy .buy-price {
        font-size: 14px; }
        .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area .buy .buy-price span {
          color: #fd521d;
          font-size: 16px;
          font-weight: 700; }
      .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area .buy .buy-subscript {
        font-size: 12px;
        margin-right: 0;
        color: #777573;
        line-height: 10px;
        height: 10px;
        margin-top: 4px; }
    .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area .ant-input-search {
      width: 320px;
      float: right; }
      .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area .ant-input-search input {
        width: 180px;
        padding-right: 11px; }
      .r-detail-middle-bargain .r-detail-middle-bargain__bargain-area .ant-input-search button {
        width: 140px;
        background-color: #fa521d;
        font-size: 16px; }
