@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-sell-footer___x_BXv {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1; }
  .r-sell-footer___x_BXv .content {
    margin: 0 auto;
    width: 635px;
    min-height: 119px;
    background: linear-gradient(180deg, #ffba6b 0%, #ffa14d 100%);
    border-radius: 20px 20px 0px 0px;
    position: relative; }
    .r-sell-footer___x_BXv .content .boy {
      width: 214px;
      height: 141px;
      background: url(https://wos.58cdn.com.cn/cDazYxWcDHJ/picasso/ct6r694i.png) no-repeat;
      background-position: left bottom;
      position: absolute;
      left: 0;
      bottom: 0; }
    .r-sell-footer___x_BXv .content .main {
      padding: 16px 35px 16px 172px; }
      .r-sell-footer___x_BXv .content .main .form {
        position: relative; }
        .r-sell-footer___x_BXv .content .main .form .items {
          width: 273px;
          float: left; }
          .r-sell-footer___x_BXv .content .main .form .items .item {
            width: 273px;
            height: 44px;
            line-height: 44px;
            border: none;
            background-color: #fff;
            font-size: 16px;
            color: #333;
            margin-bottom: 10px;
            position: relative;
            border-radius: 4px;
            overflow: hidden; }
            .r-sell-footer___x_BXv .content .main .form .items .item input,
            .r-sell-footer___x_BXv .content .main .form .items .item input:focus {
              border: none;
              height: 44px;
              line-height: 44px;
              box-shadow: none;
              font-size: 16px; }
            .r-sell-footer___x_BXv .content .main .form .items .item .brush-wrapper {
              border: none;
              height: 30px; }
              .r-sell-footer___x_BXv .content .main .form .items .item .brush-wrapper .brush-refresh {
                line-height: 30px; }
        .r-sell-footer___x_BXv .content .main .form .submit-btn {
          float: left;
          width: 130px;
          height: 44px;
          line-height: 44px;
          background: linear-gradient(108.8deg, #ff7f17 0%, #ff301d 100%);
          border-radius: 4px;
          margin-left: 25px;
          color: #fff;
          font-size: 18px;
          border: none;
          position: absolute;
          bottom: 10px; }
      .r-sell-footer___x_BXv .content .main .err-msg {
        color: #fd521d;
        font-size: 12px;
        margin-bottom: 10px; }
      .r-sell-footer___x_BXv .content .main .note .check {
        float: left;
        margin-right: 5px; }
      .r-sell-footer___x_BXv .content .main .note .txt {
        overflow: hidden;
        color: #fff;
        opacity: 0.7; }
  .r-sell-footer___x_BXv .r-sell-footer__title-main {
    font-size: 30px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.8); }
