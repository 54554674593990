@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-c-inspect-wraper .r-c-inspect-head {
  height: 58px;
  line-height: 58px; }
  .r-c-inspect-wraper .r-c-inspect-head .acc_name {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.78); }
  .r-c-inspect-wraper .r-c-inspect-head .hg_sign {
    display: inline-block;
    width: 47px;
    height: 26px;
    margin-left: 25px;
    background: #71ceb5;
    border-radius: 3px;
    font-size: 14px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    vertical-align: text-bottom; }
  .r-c-inspect-wraper .r-c-inspect-head .title_des {
    display: inline-block;
    max-width: 850px;
    margin-left: 25px;
    white-space: nowrap;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 14px; }

.r-c-inspect-wraper .column {
  width: 590px;
  float: left; }
  .r-c-inspect-wraper .column:nth-of-type(2) {
    margin-right: 20px; }
  .r-c-inspect-wraper .column .r-c-inspect-item__content-item {
    margin-left: 0px; }
    .r-c-inspect-wraper .column .r-c-inspect-item__content-item:nth-child(3n + 3) {
      margin-left: 20px; }

.r-c-inspect-wraper .r-c-inspect-item {
  background: #fff;
  border: 1px solid #ececec;
  padding: 30px 26px;
  margin-bottom: 20px; }
  .r-c-inspect-wraper .r-c-inspect-item:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03); }
  .r-c-inspect-wraper .r-c-inspect-item.col1 .r-c-inspect-item__content-item:nth-child(6n + 5) {
    margin-left: 50px; }
  .r-c-inspect-wraper .r-c-inspect-item.col1 .r-c-inspect-item__content-item:nth-child(2n + 2) {
    margin-left: 20px; }
  .r-c-inspect-wraper .r-c-inspect-item.col2 {
    width: 50%;
    float: left; }
    .r-c-inspect-wraper .r-c-inspect-item.col2:nth-of-type(3) {
      margin-right: 0px; }
    .r-c-inspect-wraper .r-c-inspect-item.col2 .r-c-inspect-item__content-item:nth-child(3n) {
      margin-left: 20px; }
  .r-c-inspect-wraper .r-c-inspect-item.col3 {
    width: 386px;
    float: left;
    margin-right: 20px; }
    .r-c-inspect-wraper .r-c-inspect-item.col3:nth-of-type(4) {
      margin-right: 0px; }
  .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-icon {
    width: 66px;
    height: 66px;
    float: left; }
    .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-icon .iconfont {
      font-size: 66px;
      color: #848494; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-icon .iconfont.icon-xuanguaxitong {
        font-size: 56px; }
  .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-info {
    float: left;
    margin-left: 26px; }
    .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-info_title {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.78);
      line-height: 20px; }
    .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-info_num {
      margin-top: 20px;
      height: 20px;
      line-height: 20px; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-info_num .icon-test_icon {
        vertical-align: middle;
        color: #71ceb5; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-info_num .test-num {
        display: inline-block;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.78);
        line-height: 18px;
        margin-left: 11px;
        margin-right: 30px;
        vertical-align: middle; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__title .title-info_num .defect {
        vertical-align: middle;
        color: #ffa14d;
        margin-left: 20px; }
  .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content {
    margin-top: 30px; }
    .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item {
      width: 166px;
      height: 40px;
      float: left; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item .iconfont {
        margin-left: 3px;
        margin-right: 4px;
        font-size: 18px;
        line-height: 21px;
        vertical-align: middle;
        display: inline-block;
        float: left; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item.anomaly {
        color: #ffa14d; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item.fail {
        color: #a3a3a3; }
        .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item.fail:hover {
          color: #ff6b23; }
        .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item.fail .line {
          margin-right: 10px;
          font-size: 14px;
          vertical-align: middle;
          float: left; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item .icon-test_smicon {
        color: #71ceb5;
        vertical-align: middle;
        margin-left: 3px;
        margin-right: 6px;
        font-size: 15px;
        display: inline-block;
        float: left; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item .pass_item_name {
        vertical-align: middle;
        font-size: 14px;
        overflow: hidden; }
      .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item .report-case-des-box {
        position: absolute;
        bottom: 39px;
        left: -38px;
        width: 239px;
        padding-bottom: 10px;
        z-index: 9999; }
        .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item .report-case-des-box .report-case-des-wraper {
          position: absolute;
          bottom: 10px;
          left: 0;
          width: 199px;
          padding: 20px 20px 26px;
          background: #ffffff;
          box-shadow: 0 0 8px 0 rgba(221, 221, 221, 0.5); }
          .r-c-inspect-wraper .r-c-inspect-item .r-c-inspect-item__content-item .report-case-des-box .report-case-des-wraper .report-case-des-content:not(:first-of-type) {
            margin-top: 20px; }
