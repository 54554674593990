@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-filter-main___1nmNV {
  color: #222;
  background-color: #f8f8f8;
  padding: 0 20px; }
  .r-filter-main___1nmNV .line {
    position: relative;
    padding: 10px 0; }
    .r-filter-main___1nmNV .line:not(:last-of-type) {
      border-bottom: 1px solid #e5e5e5; }
    .r-filter-main___1nmNV .line .label {
      width: 40px;
      margin: 5px 0;
      float: left;
      color: #999; }
    .r-filter-main___1nmNV .line .items {
      width: 1100px;
      float: left; }
      .r-filter-main___1nmNV .line .items .item {
        float: left;
        padding: 0 8px;
        margin: 5px 0;
        line-height: 20px;
        cursor: pointer; }
        .r-filter-main___1nmNV .line .items .item:hover {
          color: #fd521d; }
        .r-filter-main___1nmNV .line .items .item.hasChecked {
          background-color: #fd521d;
          color: #fff; }
    .r-filter-main___1nmNV .line .brnads-more {
      position: absolute;
      right: 38px;
      z-index: 3;
      top: 16px;
      cursor: pointer; }
      .r-filter-main___1nmNV .line .brnads-more:hover .more-txt {
        color: #fd521d; }
      .r-filter-main___1nmNV .line .brnads-more .more-txt {
        margin-right: 14px; }
      .r-filter-main___1nmNV .line .brnads-more-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 300px;
        border: 1px solid #fd521d;
        background: #fff;
        z-index: 2; }
        .r-filter-main___1nmNV .line .brnads-more-list__col {
          width: 33.33%;
          float: left;
          padding: 28px; }
          .r-filter-main___1nmNV .line .brnads-more-list__col .col-item {
            line-height: 24px;
            margin-bottom: 10px; }
            .r-filter-main___1nmNV .line .brnads-more-list__col .col-item:hover .col-item__letter {
              color: #fff;
              background-color: #fd521d; }
            .r-filter-main___1nmNV .line .brnads-more-list__col .col-item .col-item__letter {
              float: left;
              font-size: 14px;
              width: 20px;
              margin-right: 14px;
              color: #fd521d;
              text-align: center; }
            .r-filter-main___1nmNV .line .brnads-more-list__col .col-item .col-item__brands {
              overflow: hidden; }
              .r-filter-main___1nmNV .line .brnads-more-list__col .col-item .col-item__brands-item {
                cursor: pointer;
                display: inline-block;
                margin: 0 8px; }
                .r-filter-main___1nmNV .line .brnads-more-list__col .col-item .col-item__brands-item:hover {
                  color: #fd521d; }
    .r-filter-main___1nmNV .line .filter-price button {
      color: #666;
      padding: 0 10px; }
      .r-filter-main___1nmNV .line .filter-price button:hover {
        color: #fd521d; }
      .r-filter-main___1nmNV .line .filter-price button.hasChecked {
        height: 20px;
        background-color: #fd521d;
        color: #fff; }
    .r-filter-main___1nmNV .line .filter-price-cus {
      float: left;
      position: relative;
      z-index: 0;
      margin-left: 10px;
      height: 26px;
      top: -2px; }
      .r-filter-main___1nmNV .line .filter-price-cus__item {
        width: 90px;
        padding-right: 6px; }
    .r-filter-main___1nmNV .line.filter-more .filter-more-item {
      display: inline-block;
      min-width: 70px;
      padding: 0 10px;
      margin-right: 10px;
      line-height: 26px;
      background: #fff;
      border: 1px solid #ddd; }
      .r-filter-main___1nmNV .line.filter-more .filter-more-item.hasChecked {
        color: #fd521d; }
      .r-filter-main___1nmNV .line.filter-more .filter-more-item .icon {
        float: right; }
        .r-filter-main___1nmNV .line.filter-more .filter-more-item .icon.up {
          display: none; }
        .r-filter-main___1nmNV .line.filter-more .filter-more-item .icon.down {
          display: block; }
      .r-filter-main___1nmNV .line.filter-more .filter-more-item.ant-popover-open .txt {
        color: #fd521d; }
      .r-filter-main___1nmNV .line.filter-more .filter-more-item.ant-popover-open .icon.up {
        display: block !important; }
      .r-filter-main___1nmNV .line.filter-more .filter-more-item.ant-popover-open .icon.down {
        display: none !important; }

.filter-more-item-overlay {
  padding: 0; }
  .filter-more-item-overlay .ant-popover-content .ant-popover-arrow {
    top: -3px; }
  .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content {
    padding: 0; }
    .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content {
      width: 450px;
      padding: 10px 14px; }
      .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.level, .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.age {
        width: 290px; }
      .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content .ant-row {
        line-height: 30px; }
      .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content .ant-checkbox-inner {
        width: 12px;
        height: 12px; }
        .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content .ant-checkbox-inner::after {
          top: 40%;
          left: 10%; }
      .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col {
        height: 30px;
        margin: 6px 0;
        text-align: center; }
        .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col:hover .label-txt {
          color: #fd521d; }
        .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col .checkbox-input {
          visibility: hidden;
          position: absolute; }
          .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col .checkbox-input + label {
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 20px;
            cursor: pointer;
            text-align: center; }
            .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col .checkbox-input + label .iconfont {
              display: none; }
          .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col .checkbox-input:checked + label .iconfont {
            display: block;
            color: #fd521d;
            font-size: 16px; }
          .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col .checkbox-input:checked + label + .label-txt {
            color: #fd521d; }
        .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-col .label-txt {
          cursor: pointer;
          display: inline-block;
          height: 30px;
          line-height: 30px;
          vertical-align: top;
          margin-left: 6px;
          font-size: 14px; }
      .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-checkbox-inner {
        width: 26px;
        height: 26px;
        border-radius: 13px; }
        .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-content.car_color .ant-checkbox-inner::after {
          top: 40%;
          left: 10%; }
    .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-btn {
      text-align: center;
      height: 42px;
      background: #f5f5f5;
      line-height: 42px; }
      .filter-more-item-overlay .ant-popover-content .ant-popover-inner-content .search-filter-mult-btn .btn {
        width: 58px;
        height: 24px;
        margin: 0 11px; }
