@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-installment-detail___2vjKp .r-installment-detail__banner {
  height: 479px;
  width: 100%;
  background: linear-gradient(-180deg, rgba(241, 232, 220, 0.4) 6%, rgba(207, 199, 197, 0.4) 98%);
  margin-top: -59px;
  position: relative; }
  .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box {
    width: 1190px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between; }
    .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box {
      float: left;
      width: 383px;
      height: 330px;
      background: #ffffff;
      box-shadow: 0 6px 10px 0 rgba(165, 151, 148, 0.56), 0 9px 18px 0 rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      margin-top: 48px;
      box-sizing: border-box; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .input-box {
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        border: 1px solid #ececec;
        border-radius: 3px;
        margin-bottom: 10px; }
        .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .input-box input {
          height: 100%;
          border: none; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .error-msg {
        color: #ff6b23;
        font-size: 14px;
        margin-top: -10px; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .phone-input input {
        float: left;
        width: 190px; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .phone-input .tips {
        float: left;
        width: 110px;
        text-align: center;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: #ff6b23;
        line-height: 48px;
        cursor: pointer; }
        .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .phone-input .tips:hover {
          text-decoration: underline; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .phone-input .gray {
        color: gray; }
        .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .phone-input .gray:hover {
          text-decoration: none;
          cursor: default; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .qrcode-input input {
        width: 100%; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .submit-btn {
        width: 100%;
        background: #fd521d;
        border-radius: 3px;
        height: 48px;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        line-height: 48px;
        cursor: pointer; }
        .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .submit-box .submit-btn:hover {
          background: #fd6f1a; }
    .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .banner-tags {
      float: left;
      margin-top: 120px; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .banner-tags .title {
        font-size: 48px;
        font-weight: bold;
        color: #834b40;
        line-height: 57px;
        margin-bottom: 15px;
        margin-right: -40px; }
        .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .banner-tags .title span {
          display: block; }
      .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .banner-tags .tag {
        font-family: SourceHanSansCN-Light;
        font-size: 16px;
        color: #834b40;
        line-height: 20px; }
        .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .banner-tags .tag span {
          margin-right: 10px; }
    .r-installment-detail___2vjKp .r-installment-detail__banner .inner-box .pic-box {
      height: 479px;
      width: 712px;
      padding-right: 80px;
      margin-right: -80px;
      background: url(//img1.rrcimg.com/_next/static/src/assets/credit-banner_1yNIR.png) no-repeat; }

.r-installment-detail___2vjKp .r-installment-detail__advantage {
  height: 98px;
  background-image: linear-gradient(-90deg, #557fbd 16%, #5892c5 34%, #5892c5 42%, #557fbd 84%); }
  .r-installment-detail___2vjKp .r-installment-detail__advantage .inner-box {
    width: 1190px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center; }
    .r-installment-detail___2vjKp .r-installment-detail__advantage .inner-box .item .iconfont {
      float: left;
      width: 60px;
      height: 51px;
      margin-right: 20px;
      font-size: 48px;
      color: #ececec; }
    .r-installment-detail___2vjKp .r-installment-detail__advantage .inner-box .item .tag {
      float: left;
      color: #fff; }
      .r-installment-detail___2vjKp .r-installment-detail__advantage .inner-box .item .tag span {
        display: block; }
      .r-installment-detail___2vjKp .r-installment-detail__advantage .inner-box .item .tag .font1 {
        font-size: 22px;
        color: #ffffff;
        line-height: 30px; }
      .r-installment-detail___2vjKp .r-installment-detail__advantage .inner-box .item .tag .font2 {
        font-size: 14px;
        color: #dde6f2;
        line-height: 20px; }

.r-installment-detail___2vjKp .r-installment-detail__car {
  width: 1190px;
  margin: 0 auto;
  padding-bottom: 50px; }
  .r-installment-detail___2vjKp .r-installment-detail__car .title {
    font-size: 30px;
    color: #393939;
    text-align: center;
    line-height: 110px; }
  .r-installment-detail___2vjKp .r-installment-detail__car .advice-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 270px; }
    .r-installment-detail___2vjKp .r-installment-detail__car .advice-list a {
      display: block;
      height: 100%; }
      .r-installment-detail___2vjKp .r-installment-detail__car .advice-list a .advice-item {
        width: 282px;
        height: 270px;
        background-color: rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 40px 30px 0; }
        .r-installment-detail___2vjKp .r-installment-detail__car .advice-list a .advice-item .advice-title {
          font-size: 20px;
          color: #be5b48;
          line-height: 18px;
          margin-bottom: 11px;
          font-weight: bold; }
        .r-installment-detail___2vjKp .r-installment-detail__car .advice-list a .advice-item .tag {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
          line-height: 14px;
          margin-bottom: 45px; }
        .r-installment-detail___2vjKp .r-installment-detail__car .advice-list a .advice-item .pic {
          width: 180px;
          height: 133px;
          overflow: hidden;
          margin: 0 auto;
          position: relative; }
          .r-installment-detail___2vjKp .r-installment-detail__car .advice-list a .advice-item .pic:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.03); }
          .r-installment-detail___2vjKp .r-installment-detail__car .advice-list a .advice-item .pic img {
            width: 100%;
            height: 100%; }
  .r-installment-detail___2vjKp .r-installment-detail__car .more-btn {
    width: 182px;
    height: 40px;
    margin: 0 auto;
    border: 1px solid #ff6b23;
    border-radius: 3px; }
    .r-installment-detail___2vjKp .r-installment-detail__car .more-btn a {
      display: block;
      width: 100%;
      height: 100%;
      color: #ff6b23;
      line-height: 40px;
      padding-left: 60px;
      box-sizing: border-box;
      position: relative; }
      .r-installment-detail___2vjKp .r-installment-detail__car .more-btn a .iconfont {
        font-size: 12px;
        margin-left: 6px; }
    .r-installment-detail___2vjKp .r-installment-detail__car .more-btn:hover {
      background-color: rgba(255, 82, 94, 0.03); }

.r-installment-detail___2vjKp .r-installment-detail__flow {
  height: 378px;
  width: 1190px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 65px;
  background: url(//img1.rrcimg.com/_next/static/src/assets/flow_xMX9D.png) no-repeat 155px 40px;
  box-sizing: border-box;
  padding-top: 49px;
  position: relative; }
  .r-installment-detail___2vjKp .r-installment-detail__flow:after {
    content: "";
    position: absolute;
    height: 15px;
    width: 1190px;
    background: linear-gradient(#fff, #f7f7f7);
    bottom: -15px;
    left: 0; }
  .r-installment-detail___2vjKp .r-installment-detail__flow:before {
    height: 15px;
    position: absolute;
    content: "";
    width: 1190px;
    background: linear-gradient(#f7f7f7, #fff);
    top: -15px;
    left: 0; }
  .r-installment-detail___2vjKp .r-installment-detail__flow .title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.78);
    text-align: center;
    line-height: 30px;
    margin-bottom: 9px; }
  .r-installment-detail___2vjKp .r-installment-detail__flow .tag {
    font-size: 14px;
    color: #a3a3a3;
    text-align: center;
    line-height: 14px;
    margin-bottom: 44px; }
  .r-installment-detail___2vjKp .r-installment-detail__flow .num-list {
    width: 653px;
    height: 53px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
    .r-installment-detail___2vjKp .r-installment-detail__flow .num-list .num-item {
      position: relative;
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      border-radius: 25px;
      background: #ffffff;
      border: 1px solid #2a58a5;
      font-size: 26px;
      color: #2a58a5;
      line-height: 50px;
      text-align: center;
      box-shadow: #eff7fe 0px 0px 0px 5px; }
      .r-installment-detail___2vjKp .r-installment-detail__flow .num-list .num-item:after {
        content: "";
        position: absolute;
        width: 154px;
        border-top: dashed 2px #698ac0;
        opacity: 0.6;
        left: 50px;
        top: 24px; }
      .r-installment-detail___2vjKp .r-installment-detail__flow .num-list .num-item:nth-last-of-type(1):after {
        display: none; }
  .r-installment-detail___2vjKp .r-installment-detail__flow .flow-list {
    width: 800px;
    height: 102px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto; }
    .r-installment-detail___2vjKp .r-installment-detail__flow .flow-list .flow-item {
      height: 100px;
      width: 181px;
      box-sizing: border-box;
      background-color: #eff7fe;
      border-radius: 5px;
      border: 1px solid rgba(105, 138, 192, 0.15);
      box-shadow: 8px 0 13px 0 rgba(42, 88, 165, 0.15);
      padding-top: 30px;
      position: relative; }
      .r-installment-detail___2vjKp .r-installment-detail__flow .flow-list .flow-item .arrow-icon {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: solid 8px transparent;
        border-bottom-color: #eff7fe;
        top: -16px;
        left: 50%;
        margin-left: -8px; }
      .r-installment-detail___2vjKp .r-installment-detail__flow .flow-list .flow-item:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: solid 9px transparent;
        border-bottom-color: rgba(105, 138, 192, 0.15);
        top: -18px;
        left: 50%;
        margin-left: -9px; }
      .r-installment-detail___2vjKp .r-installment-detail__flow .flow-list .flow-item .font1 {
        font-size: 18px;
        color: #2a58a5;
        text-align: center;
        line-height: 18px; }
      .r-installment-detail___2vjKp .r-installment-detail__flow .flow-list .flow-item .font2 {
        font-size: 14px;
        color: #698ac0;
        text-align: center;
        line-height: 30px; }

.r-installment-detail___2vjKp .r-installment-detail__ask {
  width: 986px;
  margin: 0 auto;
  margin-bottom: 49px; }
  .r-installment-detail___2vjKp .r-installment-detail__ask .title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.78);
    line-height: 30px;
    text-align: center;
    margin-bottom: 70px; }
  .r-installment-detail___2vjKp .r-installment-detail__ask .ask-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .r-installment-detail___2vjKp .r-installment-detail__ask .ask-list .ask-item {
      width: 470px;
      margin-bottom: 30px; }
      .r-installment-detail___2vjKp .r-installment-detail__ask .ask-list .ask-item .ask-title {
        font-size: 20px;
        color: #333333;
        line-height: 30px;
        margin-bottom: 14px; }
        .r-installment-detail___2vjKp .r-installment-detail__ask .ask-list .ask-item .ask-title .ask-icon {
          color: #999999;
          margin-right: 5px; }
      .r-installment-detail___2vjKp .r-installment-detail__ask .ask-list .ask-item .ask-answer {
        font-size: 14px;
        color: #999999;
        line-height: 28px;
        margin-left: 28px; }
