@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-search-car-item___29fSs:hover {
  border: 1px solid #fd521d; }
  .r-search-car-item___29fSs:hover .consult {
    background-color: #fd521d;
    color: #fff !important; }

.r-search-car-item___29fSs {
  width: 290px;
  margin: 10px 0;
  border: 1px solid transparent;
  padding: 1px 1px 10px;
  position: relative;
  cursor: pointer; }
  .r-search-car-item___29fSs .discount {
    background: url(//img1.rrcimg.com/_next/static/src/assets/bg_2NmCl.png) no-repeat;
    position: absolute;
    top: -6px;
    left: 14px;
    width: 62px;
    height: 59px;
    z-index: 1;
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding-top: 4px; }
  .r-search-car-item___29fSs .new {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 42px 42px 0;
    border-color: transparent #fd521d transparent transparent;
    z-index: 1; }
    .r-search-car-item___29fSs .new .text {
      position: relative;
      right: -24px;
      top: 3px;
      color: #fff;
      font-size: 14px; }
  .r-search-car-item___29fSs .img {
    width: 286px;
    height: 192px;
    background: url(//img1.rrcimg.com/_next/static/src/assets/default-car_FIKTT.png) no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative; }
    .r-search-car-item___29fSs .img img {
      transition: all 1s; }
    .r-search-car-item___29fSs .img:hover img {
      transform: scale(1.1); }
    .r-search-car-item___29fSs .img .position-bg-n {
      height: 22px;
      padding: 0 10px;
      color: #fcfcfc;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      left: 0;
      bottom: 0;
      line-height: 22px; }
    .r-search-car-item___29fSs .img .vr-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      right: 0px;
      bottom: 0px; }
      .r-search-car-item___29fSs .img .vr-icon .panorama-animation {
        width: 100%;
        height: 100%; }
        .r-search-car-item___29fSs .img .vr-icon .panorama-animation::after {
          content: "";
          background-image: url(//img1.rrcimg.com/web-www/common/panorama-2.png);
          background-repeat: no-repeat;
          animation: vrIcon___3wJU6 2s steps(34) infinite;
          width: 100%;
          height: 100%;
          display: block;
          background-size: 100% auto; }

@-webkit-keyframes vrIcon___3wJU6 {
  0% {
    background-position: 0 0; }
  50% {
    background-position: 0 0; }
  to {
    background-position: 0 100%; } }

@keyframes vrIcon___3wJU6 {
  0% {
    background-position: 0 0; }
  50% {
    background-position: 0 0; }
  to {
    background-position: 0 100%; } }
    .r-search-car-item___29fSs .img .video-icon {
      width: 36px;
      height: 36px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 20px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      line-height: 36px; }
      .r-search-car-item___29fSs .img .video-icon .triangle {
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 12px solid #fff;
        border-bottom: 8px solid transparent;
        position: relative;
        left: 14px;
        top: 4px; }
  .r-search-car-item___29fSs h3 {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #222;
    margin: 20px 10px 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none; }
    .r-search-car-item___29fSs h3 img {
      display: inline-block;
      height: 13px;
      width: auto;
      border-radius: 3px;
      margin-right: 4px;
      position: relative;
      top: 1px; }
  .r-search-car-item___29fSs .mileage {
    overflow: hidden;
    width: 100%;
    line-height: 32px;
    color: #999;
    font-size: 12px;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .r-search-car-item___29fSs .mileage-tag-box {
    padding: 0 10px 6px;
    min-height: 23px; }
    .r-search-car-item___29fSs .mileage-tag-box span {
      height: 16px;
      line-height: 11px;
      padding: 2px 4px; }
  .r-search-car-item___29fSs .price-box {
    padding: 0 10px; }
    .r-search-car-item___29fSs .price-box div {
      display: inline-block; }
    .r-search-car-item___29fSs .price-box .price {
      font-size: 24px;
      color: #fd521d;
      margin-right: 10px; }
      .r-search-car-item___29fSs .price-box .price span {
        font-size: 14px; }
    .r-search-car-item___29fSs .price-box .down-payment {
      color: #a3a3a3; }
    .r-search-car-item___29fSs .price-box .consult {
      width: 80px;
      height: 30px;
      border: 1px solid #fd521d;
      color: #fd521d;
      font-size: 14px;
      text-align: center;
      line-height: 28px;
      position: absolute;
      right: 8px;
      cursor: pointer;
      padding: 0; }
