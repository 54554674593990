@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-xingrenzheng___2Ywl1 {
  width: 1190px;
  border: 10px solid #ebe3d6;
  padding: 10px;
  margin: 10px auto; }
  .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper {
    border: 1px solid #ebe3d6;
    padding: 50px; }
    .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper h1 {
      height: 120px;
      line-height: 120px;
      color: #c59d64;
      font-size: 40px;
      text-align: center; }
    .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .info {
      color: #c79f65;
      font-size: 20px;
      line-height: 30px; }
    .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper h2 {
      height: 100px;
      line-height: 100px;
      color: #c59d64;
      font-size: 30px;
      text-align: center;
      margin-top: 50px; }
    .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper img {
      width: 545px;
      height: 765px;
      margin: 0 auto; }
    .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .question-title {
      height: 100px;
      padding-top: 30px; }
      .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .question-title .left {
        height: 35px;
        width: 40%;
        border-bottom: 1px solid #f0eae1;
        float: left; }
      .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .question-title .right {
        height: 35;
        width: 40%;
        border-bottom: 1px solid #f0eae1;
        float: right; }
      .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .question-title h3 {
        height: 70;
        line-height: 70px;
        width: 20%;
        float: left;
        font-size: 36px;
        text-align: center; }
    .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .question-content li {
      margin-bottom: 40px;
      color: #222; }
      .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .question-content li .question {
        line-height: 30px;
        margin-bottom: 15px;
        font-size: 20px; }
      .r-xingrenzheng___2Ywl1 .r-xingrenzheng__wrapper .question-content li .answer {
        line-height: 30px;
        font-size: 16px; }
