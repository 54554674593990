@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-credit-success {
  width: 460px;
  height: 420px; }
  .r-credit-success .green-logo {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    text-align: center;
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -40px;
    background: #fff; }
    .r-credit-success .green-logo .iconfont {
      font-size: 60px;
      color: #78b512; }
  .r-credit-success .confirm {
    height: 144px;
    border-bottom: dashed 1px #ddd;
    box-sizing: border-box;
    padding: 50px 0; }
    .r-credit-success .confirm .confirm-title {
      line-height: 48px;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 0;
      text-align: center; }
    .r-credit-success .confirm .confirm-message {
      font-size: 14px;
      text-align: center;
      color: #707070; }
  .r-credit-success .flow-info {
    width: 460px;
    height: 273px;
    background-color: #eff7fe; }
    .r-credit-success .flow-info .pic {
      width: 460px;
      height: 230px;
      background: url(//img1.rrcimg.com/_next/static/src/assets/flow-info_3Kliv.png);
      background-repeat: no-repeat; }
