@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-detail-process {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px; }
  .r-detail-process .r-detail-process__title {
    font-size: 22px;
    color: #333;
    margin-bottom: 40px; }
  .r-detail-process .r-detail-process__main .item {
    float: left;
    min-height: 80px;
    margin-bottom: 30px;
    margin-right: 130px;
    text-align: center; }
    .r-detail-process .r-detail-process__main .item i {
      font-size: 72px;
      color: #898999;
      width: 72px;
      height: 72px;
      display: inline-block;
      margin-bottom: 16px; }
      .r-detail-process .r-detail-process__main .item i.icon-jiantou1 {
        font-size: 14px;
        position: relative;
        top: -82px;
        left: 149px;
        color: #ccc; }
    .r-detail-process .r-detail-process__main .item h4 {
      font-size: 18px;
      margin-bottom: 10px; }
    .r-detail-process .r-detail-process__main .item p {
      font-size: 16px;
      color: #aaa; }
