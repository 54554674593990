@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-ietips___3rjH3 {
  background: #ccc; }

.ietips9___2eHPw {
  width: 1000px;
  height: 560px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -280px 0 0 -500px;
  z-index: 999999999;
  box-sizing: border-box; }
  .ietips9___2eHPw .inner {
    padding: 20px;
    background: #fff; }
    .ietips9___2eHPw .inner .checkcode {
      margin-bottom: 40px; }

.ietips___1ucE6 {
  width: 1200px;
  margin: auto; }
  .ietips___1ucE6 .inner {
    margin: 30px 30px 170px; }

.ietips___1ucE6 .ietips_mark, .ietips9___2eHPw .ietips_mark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1; }

.ietips___1ucE6 .checkcode, .ietips9___2eHPw .checkcode {
  padding: 0 20px;
  height: 35px;
  text-align: right;
  margin-bottom: 85px; }
  .ietips___1ucE6 .checkcode .code-value, .ietips___1ucE6 .checkcode .see-btn, .ietips___1ucE6 .checkcode .code-pic, .ietips9___2eHPw .checkcode .code-value, .ietips9___2eHPw .checkcode .see-btn, .ietips9___2eHPw .checkcode .code-pic {
    display: inline-block;
    vertical-align: middle; }
  .ietips___1ucE6 .checkcode .checkcode-con, .ietips9___2eHPw .checkcode .checkcode-con {
    display: inline-block;
    vertical-align: top;
    text-align: left; }
    .ietips___1ucE6 .checkcode .checkcode-con .error, .ietips9___2eHPw .checkcode .checkcode-con .error {
      text-align: left;
      text-indent: 3px;
      margin-top: 3px;
      color: #ff6b23; }
  .ietips___1ucE6 .checkcode .code-value .inp, .ietips9___2eHPw .checkcode .code-value .inp {
    width: 105px;
    height: 35px;
    line-height: 35px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000;
    text-align: left; }
    .ietips___1ucE6 .checkcode .code-value .inp:focus, .ietips9___2eHPw .checkcode .code-value .inp:focus {
      outline: none;
      border: 1px solid #ff6b23; }
    .ietips___1ucE6 .checkcode .code-value .inp::placeholder, .ietips9___2eHPw .checkcode .code-value .inp::placeholder {
      color: #c4c4c4; }
  .ietips___1ucE6 .checkcode .code-pic, .ietips9___2eHPw .checkcode .code-pic {
    margin: 0 10px;
    width: 90px;
    height: 35px; }
    .ietips___1ucE6 .checkcode .code-pic p, .ietips9___2eHPw .checkcode .code-pic p {
      font-size: 14px;
      color: #333;
      line-height: 35px;
      text-align: center; }
  .ietips___1ucE6 .checkcode .see-btn, .ietips9___2eHPw .checkcode .see-btn {
    width: 120px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    color: white;
    cursor: pointer; }

.ietips___1ucE6 .error-pic, .ietips9___2eHPw .error-pic {
  width: 342px;
  height: 214px;
  float: left;
  margin: 0 55px 0 130px; }

.ietips___1ucE6 .content, .ietips9___2eHPw .content {
  float: left;
  margin-top: 50px;
  color: #333; }
  .ietips___1ucE6 .content .title, .ietips9___2eHPw .content .title {
    font-size: 26px;
    line-height: 1.3em;
    text-align: left; }
  .ietips___1ucE6 .content .desc, .ietips9___2eHPw .content .desc {
    font-size: 16px;
    line-height: 1.5em;
    text-align: left;
    margin-bottom: 15px; }
  .ietips___1ucE6 .content .download-browser, .ietips9___2eHPw .content .download-browser {
    margin-bottom: 80px; }
    .ietips___1ucE6 .content .download-browser li, .ietips9___2eHPw .content .download-browser li {
      float: left;
      width: 165px;
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      border: 1px solid #dbdbdb;
      font-size: 16px;
      margin-right: 15px; }
      .ietips___1ucE6 .content .download-browser li a, .ietips9___2eHPw .content .download-browser li a {
        display: block;
        width: 100%;
        height: 100%;
        color: #333; }
        .ietips___1ucE6 .content .download-browser li a .browser-icon, .ietips9___2eHPw .content .download-browser li a .browser-icon {
          vertical-align: text-bottom;
          display: inline-block;
          width: 25px;
          height: 25px;
          background: #ccc;
          margin: 0 8px 0 35px; }
          .ietips___1ucE6 .content .download-browser li a .browser-icon.chrome, .ietips9___2eHPw .content .download-browser li a .browser-icon.chrome {
            margin-left: 18px;
            background: url(//img1.rrcimg.com/_next/static/src/assets/chrome_1oRRP.png) no-repeat;
            background-size: cover; }
          .ietips___1ucE6 .content .download-browser li a .browser-icon.edge, .ietips9___2eHPw .content .download-browser li a .browser-icon.edge {
            background: url(//img1.rrcimg.com/_next/static/src/assets/ie_25OtK.png) no-repeat;
            background-size: cover; }
  .ietips___1ucE6 .content .download-app h5, .ietips9___2eHPw .content .download-app h5 {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    text-align: left;
    margin-bottom: 10px; }
  .ietips___1ucE6 .content .download-app .download—pic li, .ietips9___2eHPw .content .download-app .download—pic li {
    float: left;
    margin-right: 25px;
    width: 140px;
    height: 140px; }

.ietips___1ucE6 .test, .ietips9___2eHPw .test {
  font-size: 12px; }
