@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-services {
  width: 600px;
  height: 500px;
  overflow-y: auto; }
  .r-services .title {
    text-align: center;
    font-size: 24px;
    color: #222; }
  .r-services .service .item-title {
    font-size: 16px;
    margin: 16px auto 12px; }
    .r-services .service .item-title .iconfont {
      font-size: 12px;
      margin-right: 6px;
      color: #ff6b23; }
  .r-services .service .clean-line {
    padding: 0 22px;
    font-size: 14px;
    color: #666666; }
    .r-services .service .clean-line.info-title {
      color: #333333;
      margin: 18px 0 10px; }
  .r-services .service .item-content {
    padding: 0 22px;
    font-size: 14px; }
  .r-services .service .payment {
    padding: 2px 12px;
    margin: 10px 20px;
    font-size: 14px;
    color: #ff6b23;
    background-color: #eee;
    border-radius: 13px;
    display: inline-block; }
  .r-services .service .payment-tips {
    color: #ff6b23;
    padding: 0 22px; }
