@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-login___1e3eO {
  padding: 94px 0 123px;
  background: url(https://img1.rrcimg.com/pc/login/login-bg.png) no-repeat #f5f5f8;
  background-position: center bottom; }
  .r-login___1e3eO .content {
    width: 824px;
    min-height: 450px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
    background: url(https://img1.rrcimg.com/pc/login/login-bg-left.png) no-repeat #fff;
    background-size: auto 100%;
    padding: 70px 55px 0 399px; }
    .r-login___1e3eO .content .title {
      font-size: 30px;
      color: #262333;
      font-weight: 500;
      text-align: center; }
    .r-login___1e3eO .content .login-submit .row {
      border-bottom: 1px solid rgba(0, 0, 0, 0.04); }
      .r-login___1e3eO .content .login-submit .row:focus, .r-login___1e3eO .content .login-submit .row:hover {
        border-bottom: 1px solid #ff791f; }
    .r-login___1e3eO .content .login-submit input {
      border: none;
      font-size: 18px;
      padding: 0; }
      .r-login___1e3eO .content .login-submit input .phone {
        color: #333; }
      .r-login___1e3eO .content .login-submit input.ant-input:focus {
        box-shadow: none; }
    .r-login___1e3eO .content .login-submit .brush-wrapper {
      border: none; }
