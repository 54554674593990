@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-phone___3zsVJ .row {
  margin-top: 8px;
  position: relative; }
  .r-phone___3zsVJ .row .phone, .r-phone___3zsVJ .row:nth-last-child() {
    margin-bottom: 20px; }
  .r-phone___3zsVJ .row input {
    height: 48px;
    line-height: 48px; }
  .r-phone___3zsVJ .row .getsmscode-btn {
    background: transparent;
    color: white;
    border: none;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    font-size: 18px;
    box-shadow: none;
    padding: 0; }
    .r-phone___3zsVJ .row .getsmscode-btn--default {
      color: #fd521d;
      background-color: transparent; }
    .r-phone___3zsVJ .row .getsmscode-btn--disabled {
      color: #a3a3a3; }
      .r-phone___3zsVJ .row .getsmscode-btn--disabled .seconds {
        width: 26px; }

.r-phone___3zsVJ .err-msg {
  color: #fd521d;
  font-size: 12px;
  margin: 5px 0 -15px; }

.r-phone___3zsVJ .submit-btn {
  height: 42px;
  font-size: 18px;
  color: #fff;
  background: #ff6b23;
  background: linear-gradient(to right, #ff6b23, #fd521d);
  margin: 20px auto 10px; }
  .r-phone___3zsVJ .submit-btn.disabled {
    opacity: 0.4; }
    .r-phone___3zsVJ .submit-btn.disabled:hover {
      color: #fff; }

.r-phone___3zsVJ .tip {
  color: #999999;
  position: relative; }
  .r-phone___3zsVJ .tip .check {
    float: left;
    margin-right: 5px; }
    .r-phone___3zsVJ .tip .check .ant-checkbox-inner {
      width: 14px;
      height: 14px;
      border: none; }
      .r-phone___3zsVJ .tip .check .ant-checkbox-inner::after {
        top: 48%;
        left: 20%; }
    .r-phone___3zsVJ .tip .check:not(.ant-checkbox-wrapper-checked) .ant-checkbox-inner {
      background-color: #ececec; }
  .r-phone___3zsVJ .tip > p {
    float: left;
    width: 350px; }
  .r-phone___3zsVJ .tip .txt {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.36); }

.phone-note-modal .ant-modal-content {
  border-radius: 8px !important; }

.phone-note-modal .tip {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 20px; }

.phone-note-modal .note {
  font-size: 20px;
  color: #a3a3a3; }

.phone-note-modal .btns {
  margin-top: 20px; }
  .phone-note-modal .btns .btn {
    width: 158px;
    height: 55px;
    line-height: 55px;
    border-radius: 4px;
    font-size: 20px; }
    .phone-note-modal .btns .btn:nth-of-type(1) {
      color: #b1b1b1;
      background-color: #f8f8f8;
      border: 1px solid #dcdcdc;
      margin-right: 22px; }
    .phone-note-modal .btns .btn:nth-of-type(2) {
      color: #fff;
      background-color: #ff6b23; }
