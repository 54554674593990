@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-search-partner-banner-s {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  height: 94px;
  width: 216px;
  overflow: hidden;
  background: url(https://img1.rrcimg.com/partner/left_img_v1.png);
  background-size: 100% 100%;
  transition: all 1.5s;
  cursor: pointer; }
  .r-search-partner-banner-s.close {
    left: -100%; }

.r-search-partner-banner-l {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  height: 85px;
  width: 100%;
  transition: all 1s;
  background: #f4490b; }
  .r-search-partner-banner-l .content-img {
    width: 1190px;
    height: 93px;
    position: relative;
    top: -8px;
    margin: 0 auto;
    background: url(https://img1.rrcimg.com/partner/content_img_v2.png);
    background-size: 100% 100%; }
  .r-search-partner-banner-l .close-btn {
    font-size: 38px;
    color: #9e5b08;
    position: absolute;
    right: 50px;
    top: 20px; }
  .r-search-partner-banner-l.close {
    left: calc(-100% - 1190px); }
