@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-banner___2ld5i {
  overflow: inherit;
  position: relative;
  margin-top: -60px;
  background-color: #666; }
  .r-banner___2ld5i .r-banner__container {
    width: 1190px;
    min-height: 450px;
    margin: 0 auto;
    background-color: #666; }
