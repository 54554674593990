@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-modal-success {
  padding: 40px 0 30px 0; }
  .r-modal-success .msg {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee; }
    .r-modal-success .msg .title {
      font-size: 19px;
      color: #38b361; }
      .r-modal-success .msg .title .iconfont {
        font-size: 19px;
        color: #38b361;
        font-weight: bold;
        margin-right: 10px; }
    .r-modal-success .msg .txt {
      padding: 0 20px;
      font-size: 14px;
      margin-top: 10px; }
  .r-modal-success .orderinfo {
    padding: 20px 30px;
    border-bottom: 1px solid #eee; }
    .r-modal-success .orderinfo h2 {
      font-size: 14px;
      color: #393939;
      margin-bottom: 10px; }
      .r-modal-success .orderinfo h2 > i {
        font-size: 14px;
        margin-right: 8px;
        color: #ccc;
        font-weight: bold;
        position: relative;
        top: 1px; }
      .r-modal-success .orderinfo h2 > span {
        display: inline-block;
        width: 300px;
        vertical-align: text-top;
        font-size: 12px; }
        .r-modal-success .orderinfo h2 > span p {
          margin-left: 20px;
          line-height: 22px; }
          .r-modal-success .orderinfo h2 > span p span {
            margin-right: 6px; }
            .r-modal-success .orderinfo h2 > span p span.day {
              margin-right: 10px; }
    .r-modal-success .orderinfo .car {
      width: 100%;
      background: #f2f2f2;
      border-radius: 5px;
      padding: 10px;
      margin: 10px 0;
      overflow: hidden; }
      .r-modal-success .orderinfo .car-pic {
        width: 75px;
        height: 50px;
        overflow: hidden;
        float: left;
        background: url(//img1.rrcimg.com/_next/static/src/assets/loading_TgPe0.png) no-repeat;
        background-size: cover;
        margin-right: 20px; }
        .r-modal-success .orderinfo .car-pic img {
          width: 100%; }
      .r-modal-success .orderinfo .car-intro .car-title {
        color: #222;
        margin-bottom: 5px; }
      .r-modal-success .orderinfo .car-intro .car-price {
        color: #ff6b23;
        font-size: 14px; }
  .r-modal-success .weixin {
    margin: 25px 25px 0 25px; }
    .r-modal-success .weixin .erweima {
      width: 86px;
      height: 86px;
      float: left;
      margin: 0 25px; }
      .r-modal-success .weixin .erweima img {
        width: 100%; }
    .r-modal-success .weixin .txt-info {
      display: inline-block;
      float: left; }
      .r-modal-success .weixin .txt-info .info-title {
        font-size: 20px;
        margin: -4px auto 10px;
        color: #393939; }
      .r-modal-success .weixin .txt-info .info-item {
        font-size: 15px;
        color: #666; }
        .r-modal-success .weixin .txt-info .info-item .iconfont {
          font-size: 15px;
          color: #ff5317;
          margin-right: 10px; }
      .r-modal-success .weixin .txt-info img {
        display: inline-block;
        width: 62px;
        height: 20px;
        margin: -2px 2px; }
