@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-nc-tip___YprsN {
  text-align: left;
  width: 1190px;
  height: 40px;
  line-height: 20px;
  padding: 10px;
  margin: 10px auto;
  background-color: #f8f8f8;
  position: relative;
  font-size: 14px;
  color: #333; }
  .r-nc-tip___YprsN .r-nc-tip__title {
    float: left;
    width: 590px;
    border-left: 4px solid #fd521d;
    padding-left: 8px;
    color: #222; }
    .r-nc-tip___YprsN .r-nc-tip__title .icon-dingwei {
      color: #fd521d;
      font-size: 16px;
      margin: 4px 2px 0 14px; }
    .r-nc-tip___YprsN .r-nc-tip__title .dingwei-city {
      color: #fd521d; }
    .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip {
      position: relative; }
      .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .txt {
        font-size: 12px;
        color: #999;
        margin-right: 20px; }
      .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select {
        position: absolute;
        top: 28px;
        width: 701px;
        height: 435px;
        overflow: hidden; }
        .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .city-select__change {
          width: 100%;
          height: 33px;
          background-color: #fff;
          border: 1px solid #ececec;
          border-bottom: none;
          text-align: left;
          padding-right: 20px; }
          .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .city-select__change-city-list {
            width: 95%;
            display: inline-block;
            padding-left: 10px; }
            .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .city-select__change-city-list li {
              display: inline;
              margin: 0 8px;
              cursor: pointer; }
              .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .city-select__change-city-list li a {
                color: #333; }
                .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .city-select__change-city-list li a:hover {
                  color: #e14800; }
          .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .city-select__change .close-btn {
            line-height: 33px;
            display: inline-block;
            cursor: pointer; }
            .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .city-select__change .close-btn:hover {
              color: #e14800; }
        .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city {
          width: 100%;
          height: 400px;
          overflow-y: auto;
          background-color: #fff;
          position: absolute;
          z-index: 1;
          border: 1px solid #ececec; }
          .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box {
            width: 100%;
            margin: 0 auto;
            background-color: #fff; }
            .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item {
              font-size: 14px;
              padding: 10px; }
              .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item .title {
                display: inline-block;
                width: 10px;
                margin-right: 10px;
                line-height: 20px;
                color: #f96b25;
                vertical-align: top; }
                .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item .title.zb {
                  width: 50px; }
              .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item .cities {
                width: 300px;
                display: inline-block;
                overflow: hidden;
                line-height: 20px;
                vertical-align: top; }
                .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item .cities span {
                  display: inline-block;
                  margin: 0 14px 4px 0; }
                  .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item .cities span:hover > a {
                    color: #f96b25; }
                  .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item .cities span a {
                    color: #333; }
                  .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .box-item .cities span .citySelect {
                    color: #f96b25; }
            .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .letter {
              position: relative;
              margin-top: 20px; }
              .r-nc-tip___YprsN .r-nc-tip__title .dingwei-tip .ip-address__city-select .change-city .change-city__box .letter-column {
                box-sizing: border-box;
                width: 50%;
                float: left; }
  .r-nc-tip___YprsN .r-nc-tip__result {
    float: left;
    position: relative; }
    .r-nc-tip___YprsN .r-nc-tip__result div {
      float: left; }
    .r-nc-tip___YprsN .r-nc-tip__result .choose {
      position: relative;
      margin-right: 20px; }
      .r-nc-tip___YprsN .r-nc-tip__result .choose .label {
        cursor: pointer;
        color: #fd521d; }

.r-nc-tip__result-pop {
  font-size: 14px; }
  .r-nc-tip__result-pop .ant-popover-title {
    position: relative;
    clear: both;
    overflow: hidden;
    padding-left: 28px; }
    .r-nc-tip__result-pop .ant-popover-title .choose-txt {
      color: #999;
      margin-right: 20px; }
    .r-nc-tip__result-pop .ant-popover-title .ant-tag {
      font-size: 14px;
      height: 32px;
      line-height: 32px; }
      .r-nc-tip__result-pop .ant-popover-title .ant-tag .anticon.anticon-close {
        font-size: 14px; }
    .r-nc-tip__result-pop .ant-popover-title button {
      font-size: 14px;
      float: right; }
  .r-nc-tip__result-pop .ant-popover-inner-content {
    padding: 10px 0 20px 0; }
    .r-nc-tip__result-pop .ant-popover-inner-content .panel {
      width: 390px;
      max-height: 500px;
      overflow: scroll;
      background-color: #fff;
      padding: 15px 30px 30px; }
      .r-nc-tip__result-pop .ant-popover-inner-content .panel .panel-line {
        position: relative;
        padding-left: 38px; }
        .r-nc-tip__result-pop .ant-popover-inner-content .panel .panel-line .panel-area {
          position: absolute;
          left: 0;
          line-height: 29px;
          color: #999; }
        .r-nc-tip__result-pop .ant-popover-inner-content .panel .panel-line .panel-city {
          cursor: pointer;
          color: #333;
          background-color: #fff;
          display: inline-block;
          height: 24px;
          line-height: 24px;
          padding: 0 10px;
          margin: 3px;
          text-decoration: none; }
          .r-nc-tip__result-pop .ant-popover-inner-content .panel .panel-line .panel-city:hover, .r-nc-tip__result-pop .ant-popover-inner-content .panel .panel-line .panel-city.active {
            color: #fd521d; }
