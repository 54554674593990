@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-slider-big {
  width: 1190px;
  height: 580px;
  overflow: hidden; }
  .r-slider-big .r-slider-big__main {
    width: 870px;
    height: 580px;
    float: left;
    position: relative; }
    .r-slider-big .r-slider-big__main.black {
      background-color: #000;
      padding-left: 282px; }
    .r-slider-big .r-slider-big__main .img-box {
      width: 870px;
      height: 580px;
      overflow: hidden;
      position: relative;
      background: url(//img1.rrcimg.com/_next/static/src/assets/loading_TgPe0.png) no-repeat;
      background-size: cover; }
      .r-slider-big .r-slider-big__main .img-box img {
        width: 100%; }
      .r-slider-big .r-slider-big__main .img-box .photo-num {
        position: absolute;
        right: 11px;
        bottom: 10px;
        color: #fff; }
        .r-slider-big .r-slider-big__main .img-box .photo-num .iconfont {
          font-size: 12px;
          margin-right: 6px; }
    .r-slider-big .r-slider-big__main .pre,
    .r-slider-big .r-slider-big__main .next {
      position: absolute;
      top: 262px;
      cursor: pointer; }
      .r-slider-big .r-slider-big__main .pre .iconfont,
      .r-slider-big .r-slider-big__main .next .iconfont {
        font-size: 56px;
        color: #fff; }
      .r-slider-big .r-slider-big__main .pre:hover .iconfont,
      .r-slider-big .r-slider-big__main .next:hover .iconfont {
        color: #f00; }
    .r-slider-big .r-slider-big__main .pre {
      left: 20px; }
    .r-slider-big .r-slider-big__main .next {
      right: 20px;
      transform: scale(-1); }
  .r-slider-big .r-slider-big__action {
    width: 320px;
    height: 580px;
    float: left;
    padding: 34px 20px 40px;
    position: relative; }
    .r-slider-big .r-slider-big__action .title {
      font-size: 20px;
      font-weight: 700;
      color: #222;
      line-height: 30px;
      margin-bottom: 15px; }
    .r-slider-big .r-slider-big__action .feature {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      color: #999; }
      .r-slider-big .r-slider-big__action .feature li {
        float: left;
        padding-right: 10px; }
        .r-slider-big .r-slider-big__action .feature li:not(:first-child) {
          padding-left: 10px;
          border-left: 1px solid #999; }
    .r-slider-big .r-slider-big__action .tags {
      margin: 13px 0;
      color: #fff; }
      .r-slider-big .r-slider-big__action .tags .tag {
        float: left;
        padding: 0 5px; }
        .r-slider-big .r-slider-big__action .tags .tag:not(:first-child) {
          margin-left: 4px; }
    .r-slider-big .r-slider-big__action .price {
      margin: 20px 0 28px;
      font-size: 28px;
      font-weight: 700;
      color: #fd521d; }
      .r-slider-big .r-slider-big__action .price .word {
        margin-left: 5px;
        font-size: 16px; }
      .r-slider-big .r-slider-big__action .price .newcar-price {
        font-size: 12px;
        color: #999;
        font-weight: 400; }
        .r-slider-big .r-slider-big__action .price .newcar-price s {
          text-decoration: line-through; }
    .r-slider-big .r-slider-big__action .consult-btn {
      width: 270px;
      height: 40px;
      margin-bottom: 15px;
      font-size: 16px;
      background-color: #fd521d; }
      .r-slider-big .r-slider-big__action .consult-btn .consult-icon {
        height: 20px;
        width: 20px;
        display: inline-block;
        overflow: hidden;
        vertical-align: sub;
        margin-right: 10px; }
        .r-slider-big .r-slider-big__action .consult-btn .consult-icon img {
          width: 100%; }
    .r-slider-big .r-slider-big__action .cut-price-to {
      width: 150px;
      height: 40px;
      border-radius: 0px; }
      .r-slider-big .r-slider-big__action .cut-price-to input {
        padding-right: 24px; }
        .r-slider-big .r-slider-big__action .cut-price-to input:hover, .r-slider-big .r-slider-big__action .cut-price-to input:focus {
          border-color: #fbb300;
          box-shadow: 0 0 0 0 #000; }
    .r-slider-big .r-slider-big__action .cut-btn {
      width: 120px;
      height: 40px;
      border: none;
      border-radius: 0px;
      background-color: #ffb300;
      font-size: 16px;
      color: #fff; }
    .r-slider-big .r-slider-big__action .tel {
      width: 100%;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.6);
      position: absolute;
      bottom: 40px;
      left: 0; }
      .r-slider-big .r-slider-big__action .tel .tel-icon {
        font-size: 20px;
        color: #fd521d;
        margin-right: 6px; }
