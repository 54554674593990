@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-order-time-table___j98g8 .inspect-table {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 14px; }
  .r-order-time-table___j98g8 .inspect-table tr {
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    overflow: hidden; }
    .r-order-time-table___j98g8 .inspect-table tr td {
      width: 140px;
      text-align: center;
      border-left: 1px solid #f0f0f0;
      border-top: 1px solid #f0f0f0; }
      .r-order-time-table___j98g8 .inspect-table tr td i {
        font-size: 15px; }
      .r-order-time-table___j98g8 .inspect-table tr td.time {
        color: #ccc; }
      .r-order-time-table___j98g8 .inspect-table tr td.title-checked {
        color: #27b255; }
      .r-order-time-table___j98g8 .inspect-table tr td.is-checked {
        background-color: #e7fbe6; }
      .r-order-time-table___j98g8 .inspect-table tr td.can-checked {
        color: #27b255;
        cursor: pointer; }

.r-order-time-table___j98g8 .ask-time {
  margin: 20px 0 40px;
  position: relative; }
  .r-order-time-table___j98g8 .ask-time .check-box {
    margin-right: 5px; }

.check-other-time-modal .tip {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px; }

.check-other-time-modal .btns .btn {
  width: 145px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #febaa5;
  cursor: pointer;
  color: #fd521d;
  font-size: 14px; }
  .check-other-time-modal .btns .btn:last-of-type {
    margin-left: 14px; }
