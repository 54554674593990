@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-legal___1Q56R .r-legal__banner {
  width: 100%;
  position: relative;
  top: -60px;
  background: #d4ddfc; }
  .r-legal___1Q56R .r-legal__banner .inner {
    height: 250px;
    width: 1200px;
    margin: 0 auto;
    background: url(//img1.rrcimg.com/_next/static/src/assets/legal-bg_3BjaU.png) no-repeat transparent;
    background-size: contain; }

.r-legal___1Q56R .r-legal__content {
  width: 1200px;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  position: relative;
  top: -90px;
  background: #fff; }
  .r-legal___1Q56R .r-legal__content .header {
    height: 80px;
    line-height: 80px;
    padding: 0 40px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #eee; }
  .r-legal___1Q56R .r-legal__content .menus {
    min-height: 400px;
    font-size: 16px; }
    .r-legal___1Q56R .r-legal__content .menus .menus-col {
      width: 33.33%;
      float: left;
      padding: 20px 40px; }
      .r-legal___1Q56R .r-legal__content .menus .menus-col li.item {
        margin: 20px 0; }
        .r-legal___1Q56R .r-legal__content .menus .menus-col li.item a {
          color: #333; }
          .r-legal___1Q56R .r-legal__content .menus .menus-col li.item a:hover {
            color: #fd521d; }
