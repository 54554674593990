@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-inspectOuter {
  width: 1200px;
  min-height: 644px;
  margin: 0 auto 51px auto;
  border: 2px solid #fee8ca;
  background: #fffbf9;
  padding: 7px;
  box-sizing: border-box; }
  .r-inspectOuter .r-inspectOuter__inspect {
    width: 100%;
    min-height: 644px;
    background: #fffefd;
    border: 1px solid #fee8ca;
    padding: 33px 48px 0px;
    position: relative; }
    .r-inspectOuter .r-inspectOuter__inspect .jiaobiao {
      display: block;
      position: absolute;
      font-size: 30px;
      color: #fddfc5; }
      .r-inspectOuter .r-inspectOuter__inspect .jiaobiao.jiaobiao-0 {
        top: -2px;
        left: -2px; }
      .r-inspectOuter .r-inspectOuter__inspect .jiaobiao.jiaobiao-1 {
        top: -3px;
        right: -1px;
        transform: rotate(90deg); }
      .r-inspectOuter .r-inspectOuter__inspect .jiaobiao.jiaobiao-2 {
        bottom: -3px;
        right: -2px;
        transform: rotate(180deg); }
      .r-inspectOuter .r-inspectOuter__inspect .jiaobiao.jiaobiao-3 {
        bottom: -3px;
        left: -1px;
        transform: rotate(-90deg); }
    .r-inspectOuter .r-inspectOuter__inspect .car-level {
      width: 80px;
      height: 80px;
      overflow: hidden;
      position: absolute;
      top: -10px;
      right: 100px; }
      .r-inspectOuter .r-inspectOuter__inspect .car-level img {
        width: 100%; }
    .r-inspectOuter .r-inspectOuter__inspect .header {
      height: 116px;
      line-height: 96px;
      text-align: center;
      border-bottom: 1px solid #ffc694;
      font-size: 40px;
      color: #ff6b23; }
      .r-inspectOuter .r-inspectOuter__inspect .header-img {
        width: 83px;
        height: 76px;
        overflow: hidden;
        margin-right: 16px;
        display: inline-block;
        vertical-align: middle;
        background-image: url(//img1.rrcimg.com/_next/static/src/assets/buy_GT9IJ.png);
        background-position: 0px 0px;
        background-size: 154.000px 115.000px; }
        .r-inspectOuter .r-inspectOuter__inspect .header-img img {
          width: 100%; }
    .r-inspectOuter .r-inspectOuter__inspect .content {
      margin-top: 45px; }
      .r-inspectOuter .r-inspectOuter__inspect .content .chekuang {
        width: 250px;
        min-height: 342px;
        float: left; }
        .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .tester-img {
          width: 66px;
          height: 66px;
          border-radius: 33px;
          margin: auto;
          overflow: hidden;
          background-image: url(//img1.rrcimg.com/_next/static/src/assets/buy_GT9IJ.png);
          background-position: -88px 0px;
          background-size: 154.000px 115.000px; }
        .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .tester-title {
          margin-top: 10px;
          text-align: center;
          font-size: 16px;
          color: #8a5b35;
          line-height: 16px; }
        .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .tester-name {
          margin-top: 10px;
          text-align: center;
          font-size: 14px;
          color: #9b7353;
          line-height: 14px;
          min-height: 14px; }
        .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .video {
          text-align: center;
          margin-top: 31px;
          font-size: 14px;
          color: #ff6b23;
          cursor: pointer; }
          .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .video .iconfont {
            font-size: 14px;
            margin-right: 8px; }
          .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .video.sold {
            margin-bottom: 20px; }
        .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .freeconsult-btn {
          margin: 20px 0 36px;
          height: 40px;
          border: 1px solid #ff6b23;
          font-size: 16px;
          color: #ff6b23; }
          .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .freeconsult-btn:hover {
            background: #ff6b23;
            color: #fff; }
        .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .signature {
          color: #c58833;
          text-align: center; }
          .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .signature-img {
            width: 33px;
            height: 33px;
            overflow: hidden;
            display: inline-block;
            vertical-align: -12px;
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/buy_GT9IJ.png);
            background-position: 0px -81px;
            background-size: 154.000px 115.000px; }
          .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .signature-info {
            font-size: 13px;
            height: 33px;
            line-height: 33px;
            margin-left: 4px; }
            .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .signature-info a {
              color: #c58833;
              text-decoration-line: underline; }
        .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .signature-note {
          color: #c58833;
          text-align: center;
          margin-top: 22px; }
          .r-inspectOuter .r-inspectOuter__inspect .content .chekuang .signature-note a {
            color: #c58833;
            text-decoration-line: underline; }
      .r-inspectOuter .r-inspectOuter__inspect .content .result {
        width: 770px;
        min-height: 400px;
        float: right;
        color: #8a5b35; }
        .r-inspectOuter .r-inspectOuter__inspect .content .result > p {
          font-size: 16px;
          line-height: 28px; }
        .r-inspectOuter .r-inspectOuter__inspect .content .result .danger {
          display: inline-block;
          margin: 20px 0 22px; }
          .r-inspectOuter .r-inspectOuter__inspect .content .result .danger li {
            display: inline-block;
            width: 253px;
            float: left; }
            .r-inspectOuter .r-inspectOuter__inspect .content .result .danger li .iconfont {
              font-size: 50px;
              color: #cd9a67; }
            .r-inspectOuter .r-inspectOuter__inspect .content .result .danger li span {
              height: 50px;
              line-height: 50px;
              vertical-align: bottom;
              margin-left: 16px;
              font-size: 16px;
              color: #cd9a67; }
        .r-inspectOuter .r-inspectOuter__inspect .content .result .test {
          display: inline-block;
          color: #8a5b35; }
          .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li {
            width: 223px;
            display: inline-block;
            float: left; }
            .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li:not(:last-child) {
              margin-right: 50px; }
            .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li > div > .title {
              font-size: 16px;
              height: 40px;
              line-height: 40px;
              position: relative;
              padding-left: 16px; }
              .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li > div > .title::before {
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                background-color: #8a5b35;
                border-radius: 3px;
                position: absolute;
                top: 17px;
                left: 6px; }
              .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li > div > .title .title {
                float: left; }
              .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li > div > .title .num {
                float: right;
                color: #a3a3a3;
                font-size: 14px; }
                .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li > div > .title .num.defect {
                  color: #ffa14d; }
              .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li > div > .title i {
                float: right;
                font-size: 13px;
                margin-left: 4px;
                color: #71ceb5; }
                .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li > div > .title i.icon-type-part-type-icon {
                  color: #ffa14d;
                  font-size: 12px;
                  margin-right: 4px; }
            .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li .item {
              padding-left: 16px; }
              .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li .item li {
                font-size: 14px;
                height: 32px;
                line-height: 32px; }
                .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li .item li .title {
                  float: left; }
                .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li .item li .num {
                  float: right;
                  color: #a3a3a3;
                  font-size: 14px; }
                  .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li .item li .num.defect {
                    color: #ffa14d; }
                .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li .item li i {
                  float: right;
                  font-size: 13px;
                  margin-left: 4px;
                  color: #71ceb5; }
                  .r-inspectOuter .r-inspectOuter__inspect .content .result .test > li .item li i.icon-type-part-type-icon {
                    color: #ffa14d;
                    font-size: 12px;
                    margin-right: 4px; }
    .r-inspectOuter .r-inspectOuter__inspect .show-more {
      display: block;
      margin: 40px auto 30px auto;
      width: 157px;
      height: 46px;
      border: 1px solid #c58833;
      border-radius: 4px;
      font-size: 14px;
      color: #c58833; }

.r-detail-video-wraper {
  width: 854px;
  height: 512px; }
  .r-detail-video-wraper .ant-modal-close {
    top: -18px;
    right: -18px;
    background-color: aliceblue;
    border-radius: 18px;
    border: 1px solid #fff; }
  .r-detail-video-wraper .ant-modal-close-x {
    width: 34px;
    height: 34px;
    line-height: 34px; }
  .r-detail-video-wraper .ant-modal-content {
    height: 512px; }
