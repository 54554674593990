@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.img-erweima___3yroN {
  width: 94px;
  height: 94px; }

.r-side-share___1KFQ3 {
  position: fixed;
  transition: all 0.4s;
  right: 0;
  width: 40px;
  height: 330px;
  padding: 26px 0;
  background-color: #fcfcfc;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  user-select: none;
  z-index: 999; }
  .r-side-share___1KFQ3 .shareicon___37R7J {
    font-size: 30px; }
  .r-side-share___1KFQ3 .common-sale-fixed___wD5tF {
    display: block;
    height: 60px;
    color: #333;
    text-align: center;
    text-decoration: none !important;
    margin-bottom: 10px;
    transform: scale(0.9); }
    .r-side-share___1KFQ3 .common-sale-fixed___wD5tF:hover {
      color: #f97203; }
  .r-side-share___1KFQ3 .common-share-app___2qNez {
    height: 50px;
    margin-bottom: 5px;
    box-sizing: border-box;
    text-align: center;
    transform: scale(0.9); }
    .r-side-share___1KFQ3 .common-share-app___2qNez:hover {
      color: #f97203; }
  .r-side-share___1KFQ3 .common-share-erweima___1erFq {
    height: 50px;
    box-sizing: border-box;
    margin-bottom: 5px;
    text-align: center;
    transform: scale(0.8); }
    .r-side-share___1KFQ3 .common-share-erweima___1erFq:hover {
      color: #f97203; }
  .r-side-share___1KFQ3 .common-share-top___28GKk {
    display: block;
    width: 100%;
    height: 50px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 7px 9px;
    margin-top: 60px;
    transform: scale(0.9); }
    .r-side-share___1KFQ3 .common-share-top___28GKk .topicon___YsLsZ {
      display: block;
      transform: scale(-1);
      margin: 0 auto;
      text-align: center; }
      .r-side-share___1KFQ3 .common-share-top___28GKk .topicon___YsLsZ::after {
        content: '';
        display: block;
        margin: 2px auto;
        width: 16px;
        height: 2px;
        background-color: #333; }
    .r-side-share___1KFQ3 .common-share-top___28GKk:hover {
      color: #f97203; }
      .r-side-share___1KFQ3 .common-share-top___28GKk:hover .topicon___YsLsZ::after {
        background-color: #f97203; }
