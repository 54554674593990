@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
/*
 * @Author: yeungtg 
 * @Date: 2021-09-09 16:04:31 
 * @Last Modified by: sunyanan
 * @Last Modified time: 2022-04-01 16:54:33
 */
.error___yzsIU {
  width: 1200px;
  margin: auto;
  padding: 160px 0 50px; }
  .error___yzsIU .errimg {
    width: 320px;
    height: 200px;
    margin: 0 auto 15px; }
    .error___yzsIU .errimg .pic {
      width: 100%;
      height: 100%;
      display: block; }
  .error___yzsIU .errbtn_warp {
    text-align: center;
    white-space: pre-line; }
    .error___yzsIU .errbtn_warp li {
      display: inline-block;
      width: 110px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      color: #fff;
      font-size: 17px;
      border-radius: 4px;
      margin: 0 20px;
      background: #FFA14D;
      cursor: pointer;
      overflow: hidden; }
      .error___yzsIU .errbtn_warp li a {
        color: #fff;
        display: block;
        height: 100%;
        background: #FF6B23; }
