@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-city {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 59px; }
  .r-city .r-city__choose {
    display: inline-block;
    margin: 0 10px 0 26px;
    font-size: 16px;
    color: #222; }
    .r-city .r-city__choose::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      margin-top: 33px;
      position: absolute;
      border-right: solid 5px rgba(125, 125, 125, 0.5);
      border-top: solid 5px transparent;
      border-left: solid 5px transparent; }
    .r-city .r-city__choose:hover {
      color: #fd521d; }

.r-cities {
  position: absolute;
  width: 100%;
  top: 59px;
  left: 0;
  line-height: normal;
  min-width: 1200px; }
  .r-cities--show {
    display: block; }
  .r-cities--hidden {
    display: none; }
  .r-cities .r-cities__box {
    width: 1200px;
    margin: 0 auto;
    border: 1px solid #ececec;
    background-color: #fff;
    padding-bottom: 30px; }
    .r-cities .r-cities__box-inner {
      max-height: 550px;
      overflow-y: auto; }
      .r-cities .r-cities__box-inner .box-item {
        font-size: 14px;
        margin: 10px 0 4px 16px; }
        .r-cities .r-cities__box-inner .box-item .title {
          display: inline-block;
          width: 10px;
          margin-right: 10px;
          line-height: 20px;
          color: #f96b25;
          vertical-align: top; }
          .r-cities .r-cities__box-inner .box-item .title.zb {
            width: 50px; }
        .r-cities .r-cities__box-inner .box-item .cities {
          width: 550px;
          display: inline-block;
          overflow: hidden;
          line-height: 20px;
          vertical-align: top; }
          .r-cities .r-cities__box-inner .box-item .cities span {
            display: inline-block;
            margin: 0 14px 4px 0; }
            .r-cities .r-cities__box-inner .box-item .cities span:hover > a {
              color: #f96b25; }
            .r-cities .r-cities__box-inner .box-item .cities span a {
              color: #333; }
            .r-cities .r-cities__box-inner .box-item .cities span .citySelect {
              color: #f96b25; }
      .r-cities .r-cities__box-inner .letter {
        border-top: 1px solid #ececec;
        position: relative; }
        .r-cities .r-cities__box-inner .letter-column {
          box-sizing: border-box;
          width: 50%;
          float: left; }
