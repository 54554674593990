@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-detail-qa {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 70px; }
  .r-detail-qa .r-detail-qa__title {
    font-size: 22px;
    color: #333;
    margin-bottom: 40px; }
  .r-detail-qa .r-detail-qa__main .item {
    overflow: hidden;
    display: inline-block;
    float: left;
    min-height: 80px;
    margin-bottom: 30px; }
    .r-detail-qa .r-detail-qa__main .item:nth-of-type(odd) {
      width: 650px; }
    .r-detail-qa .r-detail-qa__main .item:nth-of-type(even) {
      width: 550px; }
    .r-detail-qa .r-detail-qa__main .item-icon {
      width: 52px;
      height: 52px;
      line-height: 52px;
      margin-right: 30px;
      float: left;
      text-align: center; }
      .r-detail-qa .r-detail-qa__main .item-icon i {
        font-size: 51px;
        color: #d1a171; }
    .r-detail-qa .r-detail-qa__main .item-txt {
      width: 460px;
      float: left; }
      .r-detail-qa .r-detail-qa__main .item-txt h4 {
        font-size: 18px;
        margin-bottom: 8px;
        color: #8c5313; }
      .r-detail-qa .r-detail-qa__main .item-txt p {
        font-size: 14px;
        color: #666; }
