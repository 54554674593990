@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-partner-container___3GkBc {
  padding-bottom: 50px;
  width: 1200px;
  margin: 0 auto; }
  .r-partner-container___3GkBc .img-wrap.energize {
    height: 680px; }
  .r-partner-container___3GkBc .img-wrap.brand {
    height: 936px; }
  .r-partner-container___3GkBc .img-wrap.flow, .r-partner-container___3GkBc .img-wrap.zoology {
    height: 791px; }
  .r-partner-container___3GkBc .img-wrap.participate {
    height: 929px; }
  .r-partner-container___3GkBc .img-wrap.join {
    height: 1296px; }

.r-partner-submit___EH6m7 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 74px;
  background-color: #fff1d5;
  z-index: 1; }
  .r-partner-submit___EH6m7 .partner-container {
    position: relative;
    width: 1200px;
    height: 74px;
    margin: 0 auto;
    background-image: url("//img1.rrcimg.com/partner/hehuoren-btn.png?imageView2/2/w/990/interlace/1");
    background-position: center; }
    .r-partner-submit___EH6m7 .partner-container .partner-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-20%, -50%);
      width: 450px; }
      .r-partner-submit___EH6m7 .partner-container .partner-wrapper .partner-input {
        width: 310px;
        height: 58px;
        font-size: 20px;
        padding: 5px;
        float: left;
        border: 1px solid;
        outline: none; }
      .r-partner-submit___EH6m7 .partner-container .partner-wrapper .partner-button {
        margin-left: 10px;
        float: left;
        height: 58px;
        line-height: 58px;
        text-align: center;
        width: 130px;
        cursor: pointer;
        background-color: #f50000;
        background: linear-gradient(#ff6000, #f50000) !important;
        outline: none;
        border: none;
        font-size: 18px;
        color: #fff; }

.r-partner-popup-mask___3kvca {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99; }

.r-partner-tip___3mSnh {
  line-height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  opacity: 0.7;
  min-width: 200px;
  max-width: 290px;
  text-align: center;
  z-index: 30;
  position: fixed;
  color: #fff;
  font-size: 18px; }
