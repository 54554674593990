@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.login-pop-over-wraper .ant-popover-inner-content {
  padding: 0 !important; }
  .login-pop-over-wraper .ant-popover-inner-content .ant-popover-arrow {
    display: none !important; }

.r-login-tooltip {
  width: 204px;
  height: 438px;
  text-align: center; }
  .r-login-tooltip .r-login-tooltip__img {
    position: relative;
    height: 134px;
    margin-top: -10px;
    background: url(//img1.rrcimg.com/_next/static/src/assets/logged-banner_FGJ9E.png); }
  .r-login-tooltip .r-login-tooltip__img-app {
    width: 240px;
    height: 45px;
    position: absolute;
    top: 134px;
    left: -18px;
    color: #fff;
    line-height: 32px;
    background: url(//img1.rrcimg.com/_next/static/src/assets/logged-mid_3NYkg.png); }
    .r-login-tooltip .r-login-tooltip__img-app .ad-title {
      font-size: 12px;
      font-weight: 700;
      margin-right: 10px; }
  .r-login-tooltip .r-login-tooltip__qrcode {
    width: 150px;
    height: 150px;
    margin: 50px 0 20px 26px;
    background-size: 100%;
    background-image: url(//img1.rrcimg.com/_next/static/src/assets/qr-wechat_3WXBy.png); }
  .r-login-tooltip .r-login-tooltip__logout {
    width: 154px;
    height: 40px;
    line-height: 38px;
    margin: 16px auto;
    font-size: 16px;
    background-color: #fff;
    color: #fd521d;
    border: 1px solid #fd521d; }
    .r-login-tooltip .r-login-tooltip__logout:hover {
      background-color: #fd521d;
      color: #fff; }

.header-shadow {
  width: 1200px;
  height: 59px;
  margin: 0 auto; }

.header-wrapper {
  width: 100%;
  height: 59px;
  line-height: 20px;
  background: transparent;
  transition: position 0.5s;
  position: relative;
  z-index: 10; }
  .header-wrapper .header {
    width: 1200px;
    height: 59px;
    margin: 0 auto;
    user-select: none; }
    .header-wrapper .header_logo {
      float: left;
      width: 100px;
      height: 59px;
      background-image: url(//img1.rrcimg.com/_next/static/src/assets/header_1ltlf.png);
      background-position: 0px 0px;
      background-size: 161.000px 177.000px;
      background-position-y: 0px;
      transition: background-position-y 0.1s;
      cursor: pointer; }
      .header-wrapper .header_logo:hover {
        background-position-y: -59px; }
    .header-wrapper .header_nav {
      height: 59px;
      line-height: 59px; }
      .header-wrapper .header_nav_menu {
        display: inline; }
        .header-wrapper .header_nav_menu li {
          display: inline;
          position: relative;
          margin-left: 45px;
          font-size: 16px; }
          .header-wrapper .header_nav_menu li a {
            color: #222;
            cursor: pointer; }
            .header-wrapper .header_nav_menu li a:hover {
              color: #fd521d; }
          .header-wrapper .header_nav_menu li.nav_sale::before {
            content: "";
            width: 55px;
            height: 20px;
            position: absolute;
            top: -14px;
            left: 5px;
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/header_1ltlf.png);
            background-position: -106px 0px;
            background-size: 161.000px 177.000px; }
      .header-wrapper .header_nav_tel {
        float: right;
        height: 59px;
        line-height: 59px;
        color: #4a4a4a; }
        .header-wrapper .header_nav_tel :nth-child(1) {
          display: inline-block;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          padding-right: 20px;
          border-right: 1px solid #d8d8d8;
          margin-right: 18px;
          cursor: pointer; }
        .header-wrapper .header_nav_tel_phone {
          font-size: 20px;
          user-select: text; }

.handFixed {
  min-width: 1200px;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 99999999; }

.colorlight .header_nav_menu li a, .colorlight .r-city .r-city__choose, .colorlight .header_nav_tel {
  color: #fff; }

.colorlight.handFixed .header_nav_menu li a, .colorlight.handFixed .r-city .r-city__choose, .colorlight.handFixed .header_nav_tel {
  color: #222; }
