@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-sales-add-info___1jOp1 {
  width: 1200px;
  margin: 0 auto;
  font-size: 14px; }
  .r-sales-add-info___1jOp1 .r-sales-add-info__crumb {
    width: 100%;
    height: 42px;
    margin-top: 30px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .triangle-right {
      width: 0;
      height: 0;
      float: right;
      border-top: 21px solid transparent;
      border-left: 10px solid #999;
      border-bottom: 21px solid transparent;
      margin-right: -10px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .crumb-title {
      padding-left: 20px;
      float: left; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .triangle-left {
      width: 0;
      height: 0;
      float: left;
      border-top: 21px solid transparent;
      border-left: 10px solid #fff;
      border-bottom: 21px solid transparent; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .crumb-part {
      float: left;
      width: 386px;
      height: 42px;
      background-color: #999;
      color: #fff;
      line-height: 42px;
      margin-right: 20px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .crumb-part.last-child {
        margin-right: 0; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .now {
      background-color: #fd521d; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .now .triangle-right {
        border-left-color: #fd521d; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .end {
      background-color: #ff9f81; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__crumb .end .triangle-right {
        border-left-color: #ff9f81; }
  .r-sales-add-info___1jOp1 .r-sales-add-info__form {
    width: 100%;
    border: 1px solid #bbb;
    margin: 30px 0;
    min-height: 575px;
    padding: 0 40px 0 190px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__form .parameter-err {
      color: #fd521d;
      vertical-align: middle; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .parameter-err i {
        font-size: 16px;
        margin-right: 4px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__form .title {
      margin: 30px 0; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .title em {
        background: #ff6b23;
        border-radius: 3px;
        width: 6px;
        height: 20px;
        display: inline-block;
        vertical-align: middle; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .title .txt {
        font-size: 18px;
        color: #393939;
        padding-left: 10px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 20px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr {
      height: 60px;
      line-height: 60px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-name {
        width: 90px;
        color: #393939; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input {
        width: 360px;
        padding-left: 20px;
        position: relative; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .brands-input .ant-select-selection__rendered {
          line-height: 40px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .brands-input .ant-select-selection.ant-select-selection--single,
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input input,
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input select {
          width: 300px;
          border-radius: 2px;
          height: 40px;
          line-height: 40px;
          box-shadow: none;
          border-color: #ddd; }
          .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .brands-input .ant-select-selection.ant-select-selection--single:focus,
          .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input input:focus,
          .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input select:focus {
            border-color: #bbb; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .trigger-text,
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .select2-container {
          border-radius: 2px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .mil-input {
          border-radius: 0px; }
          .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .mil-input input:hover, .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .mil-input input:focus {
            box-shadow: 0 0 0 0 #000; }
          .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td.parameter-input .mil-input .ant-input-suffix {
            right: 56px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td .licensed-year {
        width: 145px;
        margin-right: 10px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td .licensed-year .ant-select-selection--single {
          height: 40px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .sales-table tr td .licensed-year .ant-select-selection__rendered {
          line-height: 40px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__form .inspect-place-title {
      display: inline-block;
      line-height: 40px;
      width: 90px;
      float: left; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__form .inspect-place-input {
      float: left;
      position: relative; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .inspect-place-input .placeinput input {
        display: inline-block;
        width: 300px;
        border-radius: 2px;
        height: 40px;
        line-height: 40px;
        box-shadow: none;
        border-color: #ddd;
        border: 1px solid #ddd;
        box-shadow: none;
        border-radius: 2px;
        padding-left: 1em; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__form .inspect-place-input .placeinput input:focus-visible {
          outline: none; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .inspect-place-input .placeinput.ant-select-auto-complete.ant-select
.ant-select-selection__rendered {
        line-height: 40px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__form .inspect-place-input .place-info {
        position: absolute;
        top: -20px;
        color: #fd521d; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__form .inspect-place .placeErr {
      margin-left: 10px;
      color: red; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__form .submit-btn {
      margin: 40px auto;
      display: block;
      width: 330px;
      height: 46px;
      border-radius: 3px;
      background-color: #fd521d;
      font-size: 16px;
      color: #fff; }
  .r-sales-add-info___1jOp1 .r-sales-add-info__success {
    width: 100%;
    border: 1px solid #bbb;
    margin: 30px 0;
    min-height: 575px;
    padding: 0 40px 0 190px;
    text-align: center; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__success .success {
      margin: 60px 0 30px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__success .success .success-h1 {
        font-size: 20px;
        color: #38b361;
        text-align: center;
        line-height: 23px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__success .success .success-h1 .iconfont {
          font-size: 23px;
          vertical-align: middle;
          margin-right: 17px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__success .success .success-h2 {
        line-height: 20px;
        margin-top: 10px;
        font-size: 14px;
        color: #666; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__success .prepare {
      margin: 0 auto;
      text-align: left;
      box-sizing: border-box;
      margin-top: 33px;
      padding: 41px 0 31px 50px;
      width: 430px;
      height: 254px;
      background: #fff;
      border: 1px solid #f2f2f2;
      box-shadow: 0 5px 10px 0 #f2f2f2;
      border-radius: 4px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__success .prepare .title {
        height: 16px;
        font-weight: bold;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.78);
        line-height: 16px;
        margin-top: 0;
        margin-bottom: 20px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__success .prepare p {
        font-size: 14px;
        color: #393939;
        line-height: 38px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__success .prepare p em {
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          border-radius: 50%;
          background-image: linear-gradient(-180deg, #ff7923 4%, #ff6b23 100%);
          margin-right: 10px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__success .prepare p span {
          display: inline-block;
          min-width: 107px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__success .prepare .info {
        margin-top: 20px;
        font-size: 11px;
        color: #a3a3a3;
        line-height: 12px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin {
      margin: 53px 0px 0px 328px; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin .erweima {
        width: 100px;
        height: 100px;
        float: left;
        margin-right: 25px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin .erweima img {
          width: 100%; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin .txt-info {
        display: inline-block;
        float: left; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin .txt-info .info-title {
          font-size: 20px;
          margin: -4px auto 10px;
          color: #393939; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin .txt-info .info-item {
          font-size: 15px;
          color: #666;
          letter-spacing: 0.41px;
          line-height: 27px;
          text-align: left; }
          .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin .txt-info .info-item .iconfont {
            font-size: 15px;
            color: #ff5317;
            margin-right: 10px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__success .weixin .txt-info img {
          display: inline-block;
          width: 62px;
          height: 20px;
          margin: -2px 2px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__success .button-layout {
      margin: 40px 0;
      width: 330px;
      height: 46px;
      font-size: 16px;
      border-radius: 3px; }
  .r-sales-add-info___1jOp1 .r-sales-add-info__fail {
    width: 100%;
    border: 1px solid #bbb;
    margin: 30px 0;
    min-height: 575px;
    padding: 0 40px 0 190px;
    text-align: center; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__fail .send-bad-car {
      margin: 34px auto 19px;
      width: 115px;
      height: 141px;
      background: url(//img1.rrcimg.com/_next/static/src/assets/send-car-bad_3f86N.png) no-repeat;
      background-size: 100% 100%; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__fail .fail-reason {
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #222;
      line-height: 30px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__fail .fail-collection-us {
      width: 100%;
      text-align: center;
      color: #999;
      font-size: 14px;
      margin: 16px auto 25px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__fail .car-standard {
      width: 615px;
      height: 110px;
      border: 1px solid #ffd3c5;
      margin: auto;
      background: #fff7f5; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__fail .car-standard p {
        margin: 19px auto;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        color: #222;
        text-align: center; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__fail .car-standard ul .left-info {
        width: 165px;
        text-align: left; }
      .r-sales-add-info___1jOp1 .r-sales-add-info__fail .car-standard ul li {
        float: left;
        margin-left: 50px;
        font-size: 14px;
        color: #666;
        margin-bottom: 5px; }
        .r-sales-add-info___1jOp1 .r-sales-add-info__fail .car-standard ul li span {
          color: #fff;
          background-color: #ffb7a1;
          display: inline-block;
          width: 16px;
          height: 16px;
          font-size: 12px;
          text-align: center;
          line-height: 16px;
          border-radius: 50%;
          margin-right: 10px; }
    .r-sales-add-info___1jOp1 .r-sales-add-info__fail .button-layout {
      margin: 40px 0;
      width: 330px;
      height: 46px;
      font-size: 16px;
      border-radius: 3px;
      background-color: #fd521d; }

.dropdown-wrapper .brands-wrapper {
  min-height: 316px;
  position: relative; }
  .dropdown-wrapper .brands-wrapper .letter-menu {
    width: 20px;
    text-align: center;
    float: left;
    padding: 6px 0;
    background-color: #f6f6f6;
    overflow: hidden; }
    .dropdown-wrapper .brands-wrapper .letter-menu .ant-anchor-ink {
      display: none; }
    .dropdown-wrapper .brands-wrapper .letter-menu .letter-item {
      padding: 0;
      height: 14px;
      line-height: 14px;
      cursor: pointer;
      color: #222; }
      .dropdown-wrapper .brands-wrapper .letter-menu .letter-item:hover {
        color: #fd521d; }
  .dropdown-wrapper .brands-wrapper .brands-menu {
    overflow: auto;
    max-height: 386px; }
    .dropdown-wrapper .brands-wrapper .brands-menu .ant-select-dropdown-menu {
      max-height: unset;
      padding: 0; }
    .dropdown-wrapper .brands-wrapper .brands-menu .ant-select-dropdown-menu-item-group:first-of-type .ant-select-dropdown-menu-item-group-list {
      position: relative;
      overflow: hidden; }
    .dropdown-wrapper .brands-wrapper .brands-menu .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-title {
      background-color: #f6f6f6; }
    .dropdown-wrapper .brands-wrapper .brands-menu .hot-item {
      display: inline-block;
      float: left; }

.brand-series-select ul.ant-cascader-menu {
  height: 386px; }
  .brand-series-select ul.ant-cascader-menu:first-child {
    min-width: unset;
    width: 32px;
    overflow: hidden; }
    .brand-series-select ul.ant-cascader-menu:first-child li {
      height: 16px;
      line-height: 16px;
      padding: 0 12px; }
      .brand-series-select ul.ant-cascader-menu:first-child li:first-child {
        padding: 7px;
        height: 28px; }
      .brand-series-select ul.ant-cascader-menu:first-child li.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon {
        display: none; }
  .brand-series-select ul.ant-cascader-menu .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .brand-series-select ul.ant-cascader-menu .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    color: #ff6b23; }
