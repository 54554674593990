@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.img-erweima___2eIkR {
  width: 94px;
  height: 94px; }

.r-detail-similar-sold {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px; }
  .r-detail-similar-sold .r-detail-similar-sold__title {
    font-size: 22px;
    color: #333;
    margin-bottom: 40px; }
    .r-detail-similar-sold .r-detail-similar-sold__title span {
      float: right;
      font-size: 16px;
      color: #ff6b23;
      font-weight: 400;
      cursor: pointer; }
      .r-detail-similar-sold .r-detail-similar-sold__title span i {
        display: inline-block;
        margin-left: 10px;
        color: #ff6b23;
        transition: all 0.5s; }
      .r-detail-similar-sold .r-detail-similar-sold__title span:hover i {
        transform: rotate(90deg); }
  .r-detail-similar-sold .r-detail-similar-sold__main .item {
    position: relative;
    width: 600px;
    height: 80px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    float: left;
    text-align: center; }
    .r-detail-similar-sold .r-detail-similar-sold__main .item:hover {
      background-color: #f6f6f6; }
    .r-detail-similar-sold .r-detail-similar-sold__main .item-img {
      width: 80px;
      height: 53px;
      margin: 14.5px 20px 0;
      float: left; }
    .r-detail-similar-sold .r-detail-similar-sold__main .item-detail {
      width: 310px;
      margin-top: 16px;
      float: left;
      text-align: left; }
      .r-detail-similar-sold .r-detail-similar-sold__main .item-detail__title {
        color: #222;
        font-size: 16px;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .r-detail-similar-sold .r-detail-similar-sold__main .item-detail__desc {
        font-size: 14px;
        color: #aaa;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .r-detail-similar-sold .r-detail-similar-sold__main .item-other {
      width: 120px;
      margin-top: 8px;
      float: left;
      text-align: right;
      position: relative; }
      .r-detail-similar-sold .r-detail-similar-sold__main .item-other__price {
        font-size: 18px;
        color: #333;
        font-weight: bold;
        margin-bottom: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .r-detail-similar-sold .r-detail-similar-sold__main .item-other__date {
        font-size: 14px;
        color: #aaa;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .r-detail-similar-sold .r-detail-similar-sold__main .item-tag {
      width: 0;
      height: 0;
      border-top: 51px solid #ededed;
      border-left: 53px solid transparent;
      position: absolute;
      right: 0;
      top: 0; }
    .r-detail-similar-sold .r-detail-similar-sold__main .item-tag-txt {
      position: absolute;
      right: 0px;
      top: 10px;
      transform: rotate(45deg);
      color: #aaa; }
