@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-basic {
  width: 1200px;
  height: 520px;
  margin: 0 auto; }
  .r-basic .r-basic__pic {
    width: 600px;
    float: left;
    overflow: hidden; }
    .r-basic .r-basic__pic-sold {
      width: 600px;
      height: 400px;
      background: url(//img1.rrcimg.com/_next/static/src/assets/loading_TgPe0.png) no-repeat;
      background-size: cover;
      position: relative; }
      .r-basic .r-basic__pic-sold img {
        width: 100%; }
      .r-basic .r-basic__pic-sold .tip {
        position: absolute;
        width: 180px;
        height: 180px;
        line-height: 180px;
        font-size: 28px;
        text-align: center;
        margin: -90px 0 0 -90px;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff; }
      .r-basic .r-basic__pic-sold .recommend {
        position: absolute;
        height: 25px;
        line-height: 25px;
        top: 11px;
        right: 11px; }
        .r-basic .r-basic__pic-sold .recommend .car-id {
          height: 25px;
          float: right;
          border-radius: 13px;
          background-color: #000;
          opacity: 0.8;
          color: #fff;
          margin-right: 10px;
          font-size: 12px;
          padding: 0 20px; }
  .r-basic .r-basic__info {
    width: 540px;
    margin-left: 60px;
    float: left; }
    .r-basic .r-basic__info .title {
      font-size: 18px;
      color: #222;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
      .r-basic .r-basic__info .title img {
        display: inline-block;
        width: 26px;
        height: 13px;
        margin-right: 5px;
        margin-top: 4px; }
    .r-basic .r-basic__info .tags {
      height: 17px;
      overflow: hidden;
      margin: 13px 0;
      color: #fff; }
      .r-basic .r-basic__info .tags .tag {
        float: left;
        border-radius: 2px;
        margin-left: 4px;
        padding: 0 5px;
        height: 17px;
        max-width: 80px; }
    .r-basic .r-basic__info .price {
      background-color: #fafafa;
      padding: 0 20px;
      font-size: 14px;
      padding-bottom: 10px; }
      .r-basic .r-basic__info .price .carprice {
        height: 60px;
        line-height: 60px;
        border-bottom: 2px dashed #ddd; }
        .r-basic .r-basic__info .price .carprice .newcar-price {
          color: #ff5317; }
          .r-basic .r-basic__info .price .carprice .newcar-price .num {
            display: inline-block;
            min-width: 2em;
            font-size: 30px; }
        .r-basic .r-basic__info .price .carprice .newcar-price-tax {
          color: #aaa;
          margin-left: 20px; }
          .r-basic .r-basic__info .price .carprice .newcar-price-tax .num-tax {
            display: inline-block;
            min-width: 2em; }
          .r-basic .r-basic__info .price .carprice .newcar-price-tax i {
            font-size: 18px;
            vertical-align: -2px;
            margin-left: 4px; }
            .r-basic .r-basic__info .price .carprice .newcar-price-tax i:hover {
              color: #ff5317; }
        .r-basic .r-basic__info .price .carprice .cut-tip {
          display: inline-block;
          height: 27px;
          line-height: 27px;
          padding: 0 7px;
          float: right;
          color: #ff6b23;
          background-color: #fff;
          border: 1px solid #ffa14d;
          border-radius: 4px;
          margin-top: 17px;
          cursor: pointer; }
          .r-basic .r-basic__info .price .carprice .cut-tip:hover {
            color: #fff;
            background-color: #ffa14d; }
      .r-basic .r-basic__info .price .servicefee {
        color: #bbb;
        line-height: 32px; }
        .r-basic .r-basic__info .price .servicefee .fee {
          color: #333;
          margin-left: 20px; }
        .r-basic .r-basic__info .price .servicefee .detail {
          float: right;
          font-size: 12px;
          cursor: pointer; }
      .r-basic .r-basic__info .price .service {
        display: inline-block;
        margin-left: 62px;
        color: #777; }
        .r-basic .r-basic__info .price .service-item {
          display: inline-block;
          margin-right: 34px;
          float: left; }
          .r-basic .r-basic__info .price .service-item .iconfont {
            display: inline-block;
            font-size: 12px;
            font-weight: bold;
            margin-right: 6px;
            color: #bdbdbd; }
    .r-basic .r-basic__info .parameters {
      display: inline-block;
      margin-top: 25px; }
      .r-basic .r-basic__info .parameters .parameter {
        display: inline-block;
        float: left;
        min-width: 125px;
        padding: 0 13px;
        margin: 11px 0;
        text-align: center; }
        .r-basic .r-basic__info .parameters .parameter .value {
          font-size: 18px;
          color: #333; }
          .r-basic .r-basic__info .parameters .parameter .value i {
            color: #b6b6b6;
            margin-left: 4px;
            font-size: 18px; }
        .r-basic .r-basic__info .parameters .parameter .name {
          font-size: 14px;
          color: #bbb; }
          .r-basic .r-basic__info .parameters .parameter .name .qian {
            color: #4a90e2; }
    .r-basic .r-basic__info .actions {
      margin-top: 30px; }
      .r-basic .r-basic__info .actions .item {
        display: inline-block;
        width: 200px;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        border-radius: 4px; }
        .r-basic .r-basic__info .actions .item.order {
          margin-right: 20px;
          background-color: #ff6b23; }
          .r-basic .r-basic__info .actions .item.order:hover {
            background-color: #e5601f; }
          .r-basic .r-basic__info .actions .item.order.sold {
            cursor: pointer;
            background-color: #ccc; }
        .r-basic .r-basic__info .actions .item.bargain {
          background-color: #ffa14d; }
          .r-basic .r-basic__info .actions .item.bargain:hover {
            background-color: #e59045; }
      .r-basic .r-basic__info .actions .tel {
        display: inline-block;
        vertical-align: middle;
        margin-left: 18px;
        font-size: 14px;
        color: #bbb; }

.r-price-tip {
  width: 210px; }
  .r-price-tip .price {
    padding-left: 20px; }
  .r-price-tip .tax {
    padding-left: 44px; }
  .r-price-tip .msg {
    color: #aaa;
    border-top: 1px solid #eee;
    padding-top: 4px;
    margin-top: 4px; }

.r-outer-tip {
  width: 200px;
  font-size: 14px;
  color: #222; }
