@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-bangmai-empty___2sdB2 {
  width: 1200px;
  margin: 0 auto;
  position: relative; }
  .r-bangmai-empty___2sdB2 .brush-wrapper {
    margin-left: -17%; }
  .r-bangmai-empty___2sdB2 img.zhixin-bg {
    float: left;
    width: 260px;
    height: auto;
    margin: 70px 0px 0 280px; }
  .r-bangmai-empty___2sdB2 .bangmai-form {
    float: left;
    min-height: 410px;
    width: 390px;
    display: inline-block;
    padding: 30px 50px;
    background-color: #efeff0; }
    .r-bangmai-empty___2sdB2 .bangmai-form .ant-col {
      margin-bottom: 10px; }
    .r-bangmai-empty___2sdB2 .bangmai-form .title {
      font-size: 16px;
      color: #222;
      text-align: left;
      margin-bottom: 10px; }
      .r-bangmai-empty___2sdB2 .bangmai-form .title .icon-haoche {
        color: #fd521d; }
    .r-bangmai-empty___2sdB2 .bangmai-form .hot-line {
      margin-bottom: 10px;
      font-size: 12px;
      color: #666;
      float: right; }
    .r-bangmai-empty___2sdB2 .bangmai-form .form-item {
      width: 100%;
      border-radius: 0; }
    .r-bangmai-empty___2sdB2 .bangmai-form .submit {
      position: relative; }
      .r-bangmai-empty___2sdB2 .bangmai-form .submit .phone {
        display: inline-block;
        width: 100%;
        float: left;
        margin-bottom: 10px; }
      .r-bangmai-empty___2sdB2 .bangmai-form .submit .btn {
        display: inline-block;
        background-color: #fd521d;
        color: #fff;
        float: left;
        height: 40px;
        line-height: 40px;
        width: 100%;
        font-size: 16px; }
        .r-bangmai-empty___2sdB2 .bangmai-form .submit .btn img {
          display: inline-block;
          vertical-align: middle;
          width: 26px;
          height: 26px;
          position: relative;
          top: -1px;
          margin-right: 4px; }
    .r-bangmai-empty___2sdB2 .bangmai-form .err {
      color: red; }
