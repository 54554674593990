@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-story___18-Yg {
  width: 1190px;
  min-height: 645px;
  margin: 0 auto 120px;
  text-align: center;
  position: relative; }
  .r-story___18-Yg .r-story-logo {
    width: 180px;
    line-height: 80px;
    float: left;
    font-size: 80px;
    line-height: 80px;
    margin-top: 300px;
    padding-right: 16px;
    box-sizing: border-box;
    border-right: 1px solid #FF6B23;
    font-weight: 900;
    margin-right: 70px; }
  .r-story___18-Yg .r-story-map {
    width: 925px;
    height: 636px;
    float: left;
    background: url(//img1.rrcimg.com/rrcc-p/sell/map-a0fa.png) no-repeat;
    background-position: bottom right; }
    .r-story___18-Yg .r-story-map .city {
      position: absolute;
      cursor: pointer; }
      .r-story___18-Yg .r-story-map .city.city-hrb {
        top: 276px;
        left: 1042px; }
      .r-story___18-Yg .r-story-map .city.city-cc {
        top: 308px;
        left: 996px; }
      .r-story___18-Yg .r-story-map .city.city-dl {
        top: 340px;
        left: 901px; }
      .r-story___18-Yg .r-story-map .city.city-bj {
        top: 317px;
        left: 815px; }
      .r-story___18-Yg .r-story-map .city.city-tj {
        top: 334px;
        left: 843px; }
      .r-story___18-Yg .r-story-map .city.city-qd {
        top: 376px;
        left: 839px; }
      .r-story___18-Yg .r-story-map .city.city-wf {
        top: 370px;
        left: 798px; }
      .r-story___18-Yg .r-story-map .city.city-sh {
        top: 448px;
        left: 811px; }
      .r-story___18-Yg .r-story-map .city.city-hz {
        top: 470px;
        left: 753px; }
      .r-story___18-Yg .r-story-map .city.city-nj {
        top: 442px;
        left: 762px; }
      .r-story___18-Yg .r-story-map .city.city-xm {
        top: 514px;
        left: 718px; }
      .r-story___18-Yg .r-story-map .city.city-fz {
        top: 500px;
        left: 687px; }
      .r-story___18-Yg .r-story-map .city.city-hf {
        top: 424px;
        left: 732px; }
      .r-story___18-Yg .r-story-map .city.city-zz {
        top: 394px;
        left: 695px; }
      .r-story___18-Yg .r-story-map .city.city-wh {
        top: 424px;
        left: 658px; }
      .r-story___18-Yg .r-story-map .city.city-yc {
        top: 405px;
        left: 625px; }
      .r-story___18-Yg .r-story-map .city.city-cs {
        top: 442px;
        left: 598px; }
      .r-story___18-Yg .r-story-map .city.city-cq {
        top: 414px;
        left: 558px; }
      .r-story___18-Yg .r-story-map .city.city-xt {
        top: 473px;
        left: 576px; }
      .r-story___18-Yg .r-story-map .city.city-dg {
        top: 548px;
        left: 596px; }
      .r-story___18-Yg .r-story-map .city.city-gz {
        top: 524px;
        left: 556px; }
      .r-story___18-Yg .r-story-map .city.city-fs {
        top: 536px;
        left: 522px; }
      .r-story___18-Yg .r-story-map .city.city-xa {
        top: 350px;
        left: 660px; }
      .r-story___18-Yg .r-story-map .city .landmark-bg {
        width: 24px;
        height: 8px;
        transition: transform 1s ease-in-out;
        background: url(//img1.rrcimg.com/_next/static/src/assets/circle_IOkJc.png) no-repeat;
        background-size: cover; }
        .r-story___18-Yg .r-story-map .city .landmark-bg.over {
          transition: transform 1s ease-in-out;
          transform: scale(3);
          position: relative;
          z-index: 1; }
      .r-story___18-Yg .r-story-map .city .landmark {
        width: 70px;
        height: 55px;
        line-height: 55px;
        font-size: 14px;
        background: url(//img1.rrcimg.com/_next/static/src/assets/landmark-sml_WiDMT.png) no-repeat;
        color: #666;
        transition: transform 1s ease-in-out;
        margin-top: -55px;
        margin-left: -24px; }
        .r-story___18-Yg .r-story-map .city .landmark.over {
          transition: transform 1s ease-in-out;
          transform: translate(0, 0) scale(0); }
      .r-story___18-Yg .r-story-map .city .city-content-big {
        transition: all 1s ease-in-out;
        transform: translate(-68px, 150px) scale(0);
        position: relative;
        z-index: 1; }
        .r-story___18-Yg .r-story-map .city .city-content-big.over {
          transition: all 1s ease-in-out;
          transform: translate(0, 0) scale(1); }
        .r-story___18-Yg .r-story-map .city .city-content-big .city-content {
          width: 572px;
          height: 309px;
          cursor: pointer;
          margin-top: -312px;
          margin-left: -409px; }
          .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-intro {
            width: 253px;
            height: 145px;
            margin-top: 90px;
            margin-left: 30px;
            float: left; }
            .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-intro .seller-intro-top {
              width: 250px;
              height: 32px; }
              .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-intro .seller-intro-top p {
                float: left; }
              .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-intro .seller-intro-top .seller-place {
                width: 64px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: #f94006;
                font-size: 18px;
                color: #fff; }
              .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-intro .seller-intro-top .seller-car-type {
                width: 122px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: #676262;
                font-size: 18px;
                color: #fff; }
              .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-intro .seller-intro-top .seller-name {
                width: 59px;
                height: 31px;
                line-height: 31px;
                text-align: right;
                font-size: 18px;
                color: #101010;
                border-bottom: 1px solid #676262; }
            .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-intro .seller-intro-bottom {
              margin-top: 14px;
              width: 233px;
              height: 99px;
              color: #555;
              font-size: 14px;
              text-align: left; }
          .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-img {
            width: 285px;
            height: 100%;
            float: left; }
            .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-img .seller-pic {
              width: 285px;
              height: 315px;
              background: url(//img1.rrcimg.com/_next/static/src/assets/landmark-bg_2IGJg.png) no-repeat; }
              .r-story___18-Yg .r-story-map .city .city-content-big .city-content .seller-img .seller-pic .pic {
                width: 250px;
                height: 250px;
                position: relative;
                top: 13px;
                left: 13px; }
          .r-story___18-Yg .r-story-map .city .city-content-big .city-content .circle-car-bg {
            margin: 0; }
