@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.appence-item {
  display: inline-block;
  width: 590px;
  vertical-align: top;
  border: 1px solid #ececec;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 30px 20px; }
  .appence-item:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03); }
  .appence-item .appearance-item-content .content-item {
    position: relative;
    float: left;
    width: 163px;
    height: 40px; }
    .appence-item .appearance-item-content .content-item:hover {
      overflow: initial; }
      .appence-item .appearance-item-content .content-item:hover .test-item-name {
        color: #ff6b23; }
    .appence-item .appearance-item-content .content-item .report-case-des-box {
      position: absolute;
      bottom: 39px;
      left: -38px;
      width: 239px;
      padding-bottom: 10px;
      z-index: 9999; }
      .appence-item .appearance-item-content .content-item .report-case-des-box .report-case-des-wraper {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 199px;
        padding: 20px 20px 26px;
        background: #ffffff;
        box-shadow: 0 0 8px 0 rgba(221, 221, 221, 0.5); }
        .appence-item .appearance-item-content .content-item .report-case-des-box .report-case-des-wraper .report-case-des-content:not(:first-of-type) {
          margin-top: 20px; }
      .appence-item .appearance-item-content .content-item .report-case-des-box .report-case-des-arrow {
        position: absolute;
        bottom: -16px;
        left: 112px;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top-color: #eee; }
      .appence-item .appearance-item-content .content-item .report-case-des-box .report-case-arrow-inside {
        position: absolute;
        top: -8px;
        left: -7px;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: #fff; }
      .appence-item .appearance-item-content .content-item .report-case-des-box .report-case-des-title {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.78);
        line-height: 18px;
        word-break: break-all;
        white-space: normal; }
      .appence-item .appearance-item-content .content-item .report-case-des-box .report-case-des-word {
        margin-top: 10px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.36);
        line-height: 18px;
        word-break: break-all;
        white-space: normal; }
  .appence-item .appearance-item-content .anomaly {
    color: #ffa14d; }
    .appence-item .appearance-item-content .anomaly .icon-tip-icon2-07d8d303 {
      vertical-align: middle;
      margin-right: 10px; }
  .appence-item .appearance-item-content .content-item:nth-child(3n + 2),
  .appence-item .appearance-item-content .content-item:nth-child(3n + 3) {
    margin-left: 19px; }
  .appence-item .defectNum {
    color: #ffa14d;
    vertical-align: middle; }
  .appence-item .icon-waiguan,
  .appence-item .icon-neishi {
    font-size: 60px;
    color: #848494; }
  .appence-item .detail_wrapper {
    margin-top: 50px; }
  .appence-item .appearance-outside-img {
    margin-left: 32px;
    position: relative;
    width: 100%; }
  .appence-item .points-block .twinkle-dot-block {
    width: 12px;
    height: 12px;
    position: relative;
    top: 0;
    left: 0; }
    .appence-item .points-block .twinkle-dot-block .solid-dot {
      width: 12px;
      height: 12px;
      border-radius: 16px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      background: #f90; }
    .appence-item .points-block .twinkle-dot-block .twinkle-dot {
      position: absolute;
      left: -6px;
      top: -6px;
      width: 24px;
      height: 24px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      background: #f90;
      animation: twinkling1___2nynl 1s infinite ease-out alternate;
      -webkit-animation: twinkling1___2nynl 1s infinite ease-out alternate; }

@keyframes twinkling1___2nynl {
  0% {
    opacity: 0.2;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px; }
  100% {
    opacity: 0.6;
    left: -6px;
    top: -6px;
    width: 24px;
    height: 24px; } }

@-webkit-keyframes twinkling1___2nynl {
  0% {
    opacity: 0.2;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px; }
  100% {
    opacity: 0.6;
    left: -6px;
    top: -6px;
    width: 24px;
    height: 24px; } }
  .appence-item .points-block .defect-img {
    width: 480px;
    height: 320px;
    padding: 10px;
    border: 1px solid #ffd18b;
    background-color: #fffbf2;
    position: absolute;
    top: -120px;
    z-index: 200;
    background-image: none;
    box-sizing: content-box;
    display: none; }
    .appence-item .points-block .defect-img img {
      width: 480px;
      height: 320px; }
    .appence-item .points-block .defect-img .type-banner {
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      height: 40px;
      line-height: 40px;
      z-index: 100; }
      .appence-item .points-block .defect-img .type-banner .type-title {
        display: block;
        float: left;
        font-size: 16px;
        text-align: center;
        color: white;
        line-height: 40px;
        padding-left: 30px;
        padding-right: 30px;
        margin-right: 20px;
        background-color: black; }
      .appence-item .points-block .defect-img .type-banner .type-desc {
        display: block;
        font-size: 16px;
        color: yellow;
        line-height: 40px;
        padding-left: 20px;
        background-color: rgba(0, 0, 0, 0.5); }
  .appence-item .points-block .twinkle-dot-block:hover .defect-img {
    display: block; }
  .appence-item .points-block .defect-img-l {
    left: 25px; }
  .appence-item .points-block .defect-img-r {
    left: -522px; }
  .appence-item .points-block .point-popup-arrow {
    position: absolute;
    top: 117px;
    background-color: transparent;
    border: 9px solid transparent;
    border-right: 9px solid #fffbf2;
    left: -18px; }
  .appence-item .points-block .point-popup-arrow-outer {
    position: absolute;
    top: 116px;
    background-color: transparent;
    border: 10px solid transparent;
    border-right: 10px solid #ffd18b;
    left: -20px; }
  .appence-item .points-block .point-popup-arrow-right {
    position: absolute;
    top: 117px;
    background-color: transparent;
    border: 9px solid transparent;
    border-left: 9px solid #fffbf2;
    right: -18px; }
  .appence-item .points-block .point-popup-arrow-right-outer {
    position: absolute;
    top: 116px;
    background-color: transparent;
    border: 10px solid transparent;
    border-left: 10px solid #ffd18b;
    right: -20px; }
  .appence-item .defect-img {
    position: absolute;
    background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-red.png"); }
    .appence-item .defect-img.blue-img {
      background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-blue.png"); }
    .appence-item .defect-img.gray-img {
      background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-gray.png"); }
    .appence-item .defect-img.yellow-img {
      background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-yellow.png"); }
    .appence-item .defect-img .defect-text {
      position: absolute;
      left: 20px;
      top: 20px;
      font-size: 12px;
      color: #fd5400;
      background-color: #fffbf1;
      border: 1px solid #ffd280;
      padding: 5px;
      text-align: center;
      display: none;
      z-index: 103; }
    .appence-item .defect-img:hover .defect-text {
      display: block;
      white-space: nowrap; }
  .appence-item .car-bottom {
    width: 468px;
    height: 298px; }
  .appence-item .defect-车顶 {
    height: 67px;
    width: 89px;
    background-position: 0 -109px;
    top: 116px;
    left: 163px; }
  .appence-item .defect-前保险杠 {
    height: 104px;
    width: 16px;
    background-position: -22px 0;
    top: 98px;
    left: 440px; }
  .appence-item .defect-发动机舱盖表面 {
    height: 93px;
    width: 82px;
    background-position: -63px 0;
    top: 103px;
    left: 288px; }
  .appence-item .defect-左前翼子板 {
    height: 34px;
    width: 70px;
    background-position: -184px -274px;
    left: 286px;
    top: 20px; }
  .appence-item .defect-右前翼子板 {
    height: 34px;
    width: 70px;
    background-position: 0 -312px;
    left: 286px;
    top: 245px; }
  .appence-item .defect-左后翼子板 {
    height: 38px;
    width: 92px;
    background-position: -92px -274px;
    top: 21px;
    left: 97px; }
  .appence-item .defect-右后翼子板 {
    height: 38px;
    width: 92px;
    background-position: 0 -274px;
    top: 240px;
    left: 97px; }
  .appence-item .defect-左A柱 {
    background-position: -105px -109px;
    height: 62px;
    width: 44px;
    left: 249px;
    top: 20px; }
  .appence-item .defect-左B柱 {
    height: 63px;
    width: 16px;
    background-position: -230px 0;
    left: 211px;
    top: 20px; }
  .appence-item .defect-左C柱 {
    height: 60px;
    width: 38px;
    background-position: -194px -109px;
    left: 154px;
    top: 20px; }
  .appence-item .defect-右A柱 {
    background-position: -149px -109px;
    height: 62px;
    width: 44px;
    left: 249px;
    top: 219px; }
  .appence-item .defect-右B柱 {
    height: 63px;
    width: 16px;
    top: 215px;
    left: 211px;
    background-position: -89px -109px; }
  .appence-item .defect-右C柱 {
    height: 60px;
    width: 38px;
    background-position: 0 -176px;
    left: 154px;
    top: 219px; }
  .appence-item .defect-左前车门 {
    height: 38px;
    width: 69px;
    background-position: -131px -236px;
    top: 20px;
    left: 219px; }
  .appence-item .defect-左后车门 {
    height: 38px;
    width: 62px;
    background-position: 0 -236px;
    top: 21px;
    left: 160px; }
  .appence-item .defect-右前车门 {
    height: 38px;
    width: 69px;
    background-position: -62px -236px;
    left: 219px;
    top: 242px; }
  .appence-item .defect-右后车门 {
    height: 38px;
    width: 62px;
    background-position: -183px -176px;
    left: 160px;
    top: 240px; }
  .appence-item .defect-左底大边 {
    height: 11px;
    width: 124px;
    background-position: -123px -346px;
    top: 11px;
    left: 179px; }
  .appence-item .defect-右底大边 {
    height: 11px;
    width: 124px;
    background-position: 0 -346px;
    left: 179px;
    top: 276px; }
  .appence-item .defect-后备箱盖 {
    height: 77px;
    width: 31px;
    background-position: -199px 0;
    top: 111px;
    left: 102px; }
  .appence-item .defect-后保险杠 {
    height: 109px;
    width: 22px;
    background-position: 0 0;
    top: 95px;
    left: 19px; }
  .appence-item .defect-右后翼子板内衬板 {
    height: 57px;
    width: 36px;
    background-position: -38px -176px;
    top: 196px;
    left: 138px; }
  .appence-item .defect-左后翼子板内衬板 {
    height: 57px;
    width: 36px;
    background-position: -74px -176px;
    top: 15px;
    left: 138px; }
  .appence-item .defect-右前翼子板内衬板 {
    height: 57px;
    width: 36px;
    background-position: -38px -176px;
    top: 196px;
    left: 210px; }
  .appence-item .defect-左前翼子板内衬板 {
    height: 57px;
    width: 36px;
    background-position: -74px -176px;
    top: 15px;
    left: 210px; }
  .appence-item .defect-后备箱底板 {
    height: 85px;
    width: 54px;
    background-position: -145px 0;
    top: 92px;
    left: 52px; }
  .appence-item .defect-后备箱围板 {
    height: 96px;
    width: 25px;
    background-position: -38px 0;
    top: 89px;
    left: 1px; }
  .appence-item .detail-blemish-inspect {
    margin: 40px 0 40px 38px; }
    .appence-item .detail-blemish-inspect li {
      display: inline-block;
      height: 13px;
      line-height: 13px; }
      .appence-item .detail-blemish-inspect li i {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin: 0;
        vertical-align: middle; }
      .appence-item .detail-blemish-inspect li .penqi {
        background-color: #e2e3ff; }
      .appence-item .detail-blemish-inspect li .banjin {
        background-color: #dbeefe; }
      .appence-item .detail-blemish-inspect li .genghuan {
        background-color: #ffdcd2; }
      .appence-item .detail-blemish-inspect li .secha {
        background-color: #fff0cc; }
      .appence-item .detail-blemish-inspect li i.circle {
        background-color: #f3993e;
        width: 13px;
        height: 13px;
        border: 3px solid #ffc877; }
    .appence-item .detail-blemish-inspect span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 12px;
      margin: 0 0 0 6px; }
    .appence-item .detail-blemish-inspect li:not(:first-child) {
      margin-left: 26px; }
  .appence-item .detail_wrapper_inside {
    margin-top: 50px;
    height: 291px;
    padding: 26px 0 0 79px;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .appence-item .detail_wrapper_inside .appearance-outside-img {
      margin-left: 32px;
      display: inline-block;
      position: relative; }
      .appence-item .detail_wrapper_inside .appearance-outside-img .inside-car-img {
        width: 344px;
        height: 268px; }
  .appence-item .suv .defect-img {
    position: absolute;
    background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-suv-red.png"); }
    .appence-item .suv .defect-img.blue-img {
      background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-suv-blue.png"); }
    .appence-item .suv .defect-img.gray-img {
      background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-suv-gray.png"); }
    .appence-item .suv .defect-img.yellow-img {
      background-image: url("https://img1.rrcimg.com/pc/detail-report/defect-suv-yellow.png"); }
  .appence-item .suv .defect-后保险杠 {
    height: calc(204px * 0.5);
    width: calc(37px * 0.5);
    left: calc(48px * 0.5);
    top: calc(195px * 0.5);
    background-position: calc(-39px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-后备箱盖 {
    height: calc(204px * 0.5);
    width: calc(101px * 0.5);
    left: calc(80px * 0.5);
    top: calc(195px * 0.5);
    background-position: calc(-76px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-前保险杠 {
    height: calc(203px * 0.5);
    width: calc(32px * 0.5);
    left: calc(859px * 0.5);
    top: calc(195px * 0.5);
    background-position: calc(-177px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-发动机机舱盖 {
    height: calc(174px * 0.5);
    width: calc(129px * 0.5);
    left: calc(598px * 0.5);
    top: calc(210px * 0.5);
    background-position: calc(-209px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左A柱 {
    height: calc(141px * 0.5);
    width: calc(153px * 0.5);
    left: calc(436px * 0.5);
    top: calc(37px * 0.5);
    background-position: calc(-338px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右A柱 {
    height: calc(141px * 0.5);
    width: calc(153px * 0.5);
    left: calc(436px * 0.5);
    top: calc(415px * 0.5);
    background-position: calc(0px * 0.5) calc(-231px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右B柱 {
    height: calc(134px * 0.5);
    width: calc(27px * 0.5);
    left: calc(431px * 0.5);
    top: calc(421px * 0.5);
    background-position: calc(-518px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左B柱 {
    height: calc(134px * 0.5);
    width: calc(27px * 0.5);
    left: calc(431px * 0.5);
    top: calc(37px * 0.5);
    background-position: calc(-491px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-车顶 {
    height: calc(132px * 0.5);
    width: calc(219px * 0.5);
    left: calc(298px * 0.5);
    top: calc(231px * 0.5);
    background-position: calc(-153px * 0.5) calc(-231px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右后车门 {
    height: calc(91px * 0.5);
    width: calc(118px * 0.5);
    left: calc(329px * 0.5);
    top: calc(465px * 0.5);
    background-position: calc(-372px * 0.5) calc(-231px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左后车门 {
    height: calc(91px * 0.5);
    width: calc(118px * 0.5);
    left: calc(329px * 0.5);
    top: calc(37px * 0.5);
    background-position: calc(0px * 0.5) calc(-372px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左前车门 {
    height: calc(86px * 0.5);
    width: calc(126px * 0.5);
    left: calc(440px * 0.5);
    top: calc(37px * 0.5);
    background-position: calc(-118px * 0.5) calc(-372px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右前车门 {
    height: calc(86px * 0.5);
    width: calc(126px * 0.5);
    left: calc(440px * 0.5);
    top: calc(470px * 0.5);
    background-position: calc(-244px * 0.5) calc(-372px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左C柱 {
    height: calc(49px * 0.5);
    width: calc(115px * 0.5);
    left: calc(322px * 0.5);
    top: calc(129px * 0.5);
    background-position: calc(-485px * 0.5) calc(-372px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右C柱 {
    height: calc(49px * 0.5);
    width: calc(115px * 0.5);
    left: calc(322px * 0.5);
    top: calc(415px * 0.5);
    background-position: calc(-370px * 0.5) calc(-372px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左前翼子板 {
    height: calc(41px * 0.5);
    width: calc(85px * 0.5);
    left: calc(642px * 0.5);
    top: calc(41px * 0.5);
    background-position: calc(-490px * 0.5) calc(-231px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右前翼子板 {
    height: calc(41px * 0.5);
    width: calc(85px * 0.5);
    left: calc(642px * 0.5);
    top: calc(512px * 0.5);
    background-position: calc(-490px * 0.5) calc(-272px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右D柱 {
    height: calc(39px * 0.5);
    width: calc(103px * 0.5);
    left: calc(251px * 0.5);
    top: calc(415px * 0.5);
    background-position: calc(-372px * 0.5) calc(-322px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左D柱 {
    height: calc(39px * 0.5);
    width: calc(103px * 0.5);
    left: calc(251px * 0.5);
    top: calc(139px * 0.5);
    background-position: calc(-475px * 0.5) calc(-322px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左后翼子板 {
    height: calc(30px * 0.5);
    width: calc(75px * 0.5);
    left: calc(205px * 0.5);
    top: calc(48px * 0.5);
    background-position: calc(-338px * 0.5) calc(-141px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右后翼子板 {
    height: calc(30px * 0.5);
    width: calc(76px * 0.5);
    left: calc(205px * 0.5);
    top: calc(517px * 0.5);
    background-position: calc(-413px * 0.5) calc(-141px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-右底大边 {
    height: calc(13px * 0.5);
    width: calc(201px * 0.5);
    left: calc(368px * 0.5);
    top: calc(556px * 0.5);
    background-position: calc(-209px * 0.5) calc(-174px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-左底大边 {
    height: calc(13px * 0.5);
    width: calc(201px * 0.5);
    left: calc(368px * 0.5);
    top: calc(26px * 0.5);
    background-position: calc(-209px * 0.5) calc(-187px * 0.5);
    background-size: 300px 231.5px; }
  .appence-item .suv .defect-后备箱围板 {
    height: calc(231px * 0.5);
    width: calc(39px * 0.5);
    left: calc(0px * 0.5);
    top: calc(156px * 0.5);
    background-position: calc(0px * 0.5) calc(0px * 0.5);
    background-size: 300px 231.5px; }
