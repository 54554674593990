@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-menu___1MZ3W {
  width: 140px;
  color: #333;
  float: left;
  position: relative; }
  .r-menu___1MZ3W .r-menu__sidebar-navbar li {
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    border-left: 4px solid #848f9a; }
    .r-menu___1MZ3W .r-menu__sidebar-navbar li a {
      color: #fff;
      display: block;
      text-decoration: none; }
    .r-menu___1MZ3W .r-menu__sidebar-navbar li.active {
      border-left: 4px solid #dc3c00;
      background-color: #fff;
      color: #333; }
      .r-menu___1MZ3W .r-menu__sidebar-navbar li.active a {
        color: #333; }
