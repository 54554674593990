@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-installment-apply___2U-9j {
  background: #f8f8f8; }
  .r-installment-apply___2U-9j .installment-table-tip .ant-popover-inner-content {
    background-color: #fffaf3;
    border: 1px solid #f3e1b0;
    padding: 8px;
    width: 240px;
    line-height: 18px;
    color: #666; }
  .r-installment-apply___2U-9j .r-installment-apply__content {
    margin: 0 auto 164px;
    padding: 26px 0;
    width: 1190px;
    color: #222;
    font-size: 14px; }
    .r-installment-apply___2U-9j .r-installment-apply__content .car-detail {
      float: left;
      width: 290px;
      box-shadow: 0 2px 4px #ddd;
      background: #fff;
      position: relative; }
      .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-img {
        width: 100%;
        height: 190px;
        margin-bottom: 20px; }
      .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-wrapper {
        padding: 0 20px; }
      .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-title {
        font-size: 16px;
        margin-bottom: 16px; }
        .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-title a {
          color: #222; }
          .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-title a:hover {
            color: #fd521d; }
      .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-info {
        font-size: 14px;
        margin-bottom: 22px;
        color: #999; }
        .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-info span {
          padding-right: 2px; }
      .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .detail-price {
        margin-bottom: 30px;
        font-size: 16px;
        color: #fd531e; }
      .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .number {
        font-size: 23px;
        font-weight: bold; }
      .r-installment-apply___2U-9j .r-installment-apply__content .car-detail .credit-apply-box {
        position: absolute;
        bottom: -190px;
        left: 0;
        margin-left: 0;
        z-index: 9; }
    .r-installment-apply___2U-9j .r-installment-apply__content .credit-table {
      float: left;
      padding: 23px 23px 18px;
      margin-left: 10px;
      border: 1px solid #eee;
      box-shadow: 0 2px 4px #ddd;
      background-color: #fff; }
      .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table {
        width: 841px; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table .tip {
          font-size: 12px;
          color: #999;
          margin-left: 9px; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table .bold {
          color: #000;
          font-size: 14px;
          font-weight: bold;
          padding-left: 0; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table .highlight {
          color: #fd521d;
          cursor: pointer; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table tr {
          border-bottom: 1px solid #eee; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table td,
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table th {
          padding: 0 18px;
          line-height: 60px; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table thead {
          color: #999;
          font-size: 12px;
          border-bottom: 1px solid #eee; }
          .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table thead th {
            line-height: 40px;
            position: relative; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table .icon-tuijianjinrongfangan {
          color: #f9521e;
          margin: 0 11px 0 5px;
          font-weight: normal;
          font-size: 18px;
          vertical-align: bottom; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table table .icon-type-part-type-icon {
          color: #ffb200;
          margin-left: 5px; }
      .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .credit-product {
        padding-left: 0;
        font-weight: bold;
        color: #222; }
      .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .bottom-box {
        margin-top: 30px; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .bottom-box:after {
          content: "";
          display: block;
          clear: both; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .bottom-box .btn {
          width: 180px;
          height: 40px;
          font-size: 16px;
          float: left;
          margin-right: 30px;
          background-color: #fd521d;
          border-radius: 0px; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .bottom-box .inquiries-phone {
          float: left; }
          .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .bottom-box .inquiries-phone p {
            font-size: 12px;
            color: #999; }
          .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .bottom-box .inquiries-phone p.tel {
            color: #fd521d;
            font-size: 21px; }
        .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .bottom-box a {
          float: right;
          color: #999;
          font-size: 12px;
          line-height: 70px; }
      .r-installment-apply___2U-9j .r-installment-apply__content .credit-table .special-tag {
        color: #999999;
        font-size: 12px; }
  .r-installment-apply___2U-9j .r-installment-apply__banner {
    height: 110px;
    line-height: 110px;
    border-top: 1px solid #ebebeb;
    box-shadow: 0 2px 10px #dddddd;
    margin-bottom: 40px;
    background-color: #fff; }
    .r-installment-apply___2U-9j .r-installment-apply__banner ul {
      width: 960px;
      margin: 0 auto;
      position: relative; }
      .r-installment-apply___2U-9j .r-installment-apply__banner ul li {
        width: 240px;
        float: left;
        position: relative; }
        .r-installment-apply___2U-9j .r-installment-apply__banner ul li .num {
          font-size: 52px;
          width: 50px;
          float: left;
          color: #a7a7a7; }
        .r-installment-apply___2U-9j .r-installment-apply__banner ul li .txt {
          float: left;
          line-height: normal; }
          .r-installment-apply___2U-9j .r-installment-apply__banner ul li .txt .title {
            margin-top: 28px;
            font-size: 24px;
            color: #222; }
          .r-installment-apply___2U-9j .r-installment-apply__banner ul li .txt .desc {
            color: #a7a7a7; }

.credit-product-detail .credit-box {
  padding: 0 20px 20px 10px; }
  .credit-product-detail .credit-box .title {
    padding-top: 20px;
    padding-bottom: 18px;
    border-bottom: 1px solid #fd521d;
    overflow: auto;
    margin-bottom: 28px; }
    .credit-product-detail .credit-box .title .credit-name {
      font-size: 32px;
      color: #fd521d;
      display: inline-block; }
      .credit-product-detail .credit-box .title .credit-name .credit-type {
        font-size: 12px;
        padding: 1px 5px;
        border: 1px solid #49a3ff;
        color: #49a3ff;
        position: relative;
        bottom: 0; }
      .credit-product-detail .credit-box .title .credit-name .credit-nature {
        color: #666;
        font-size: 16px;
        margin-left: 15px;
        margin-right: 14px; }
  .credit-product-detail .credit-box .credit-detail-info ul.product-info {
    overflow: hidden;
    padding-bottom: 12px; }
    .credit-product-detail .credit-box .credit-detail-info ul.product-info li {
      overflow: hidden;
      float: left;
      width: 33.3%;
      padding-bottom: 10px; }
      .credit-product-detail .credit-box .credit-detail-info ul.product-info li .icon {
        margin-right: 10px;
        float: left;
        position: relative;
        top: 10px;
        background-repeat: no-repeat; }
      .credit-product-detail .credit-box .credit-detail-info ul.product-info li .info {
        overflow: auto;
        float: left; }
        .credit-product-detail .credit-box .credit-detail-info ul.product-info li .info p {
          font-size: 14px;
          color: #666; }
          .credit-product-detail .credit-box .credit-detail-info ul.product-info li .info p .proportion {
            color: #222;
            font-size: 26px; }
      .credit-product-detail .credit-box .credit-detail-info ul.product-info li .icon_info3 {
        width: 52px;
        height: 45px;
        background-image: url(//img1.rrcimg.com/_next/static/src/assets/installmentdetail_2YClg.png);
        background-position: 0px 0px;
        background-size: 97.500px 83.000px; }
      .credit-product-detail .credit-box .credit-detail-info ul.product-info li .icon_info2 {
        width: 58px;
        height: 47px;
        background-image: url(//img1.rrcimg.com/_next/static/src/assets/installmentdetail_2YClg.png);
        background-position: 0px -45.5px;
        background-size: 97.500px 83.000px; }
      .credit-product-detail .credit-box .credit-detail-info ul.product-info li .icon_info4 {
        width: 48px;
        height: 44px;
        background-image: url(//img1.rrcimg.com/_next/static/src/assets/installmentdetail_2YClg.png);
        background-position: -53.5px 0px;
        background-size: 97.500px 83.000px; }
  .credit-product-detail .credit-box .credit-detail-info ul.credentials li.info2 {
    width: 39.3%; }
  .credit-product-detail .credit-box .credit-detail-info ul.credentials li.info3 {
    width: 27.3%; }
  .credit-product-detail .credit-box .credit-detail-info .need-info,
  .credit-product-detail .credit-box .credit-detail-info .other-price {
    position: relative;
    border-top: 1px solid #f5f5f5;
    padding-top: 18px;
    margin-top: 18px; }
    .credit-product-detail .credit-box .credit-detail-info .need-info div,
    .credit-product-detail .credit-box .credit-detail-info .other-price div {
      color: #999;
      font-size: 14px;
      margin-bottom: 6px; }
    .credit-product-detail .credit-box .credit-detail-info .need-info p,
    .credit-product-detail .credit-box .credit-detail-info .other-price p {
      color: #222;
      margin-bottom: 10px; }
      .credit-product-detail .credit-box .credit-detail-info .need-info p .list-tip,
      .credit-product-detail .credit-box .credit-detail-info .other-price p .list-tip {
        position: absolute;
        font-size: 40px;
        font-weight: bold;
        top: -18px;
        left: 0;
        color: #ddd; }
  .credit-product-detail .credit-box .credit-detail-info .other-price p {
    position: relative;
    padding-left: 15px; }
  .credit-product-detail .credit-box .credit-detail-info .need-info p {
    margin-bottom: 0; }
