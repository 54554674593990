@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
/*
 * @Author: yeungtg
 * @Date: 2021-08-23 16:38:58
 * @Last Modified by: sunyanan
 * @Last Modified time: 2022-08-01 16:11:17
 */
 {
  /*
 * @Author: yeungtg 
 * @Date: 2021-09-22 17:56:58 
 * @Last Modified by: sunyanan
 * @Last Modified time: 2022-09-23 14:38:42
 */
  /*----------nprogress css----------*/ }
  * {
    touch-action: manipulation; }
  a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }
  ol, ul {
    list-style: none; }
  blockquote, q {
    quotes: none; }
  blockquote:after, blockquote:before, q:after, q:before {
    content: '';
    content: none; }
  table {
    border-collapse: collapse;
    border-spacing: 0; }
  body {
    -webkit-overflow-scrolling: touch; }
  body, button, input, select, textarea {
    font-size: 12px;
    font-family: "Microsoft YaHei", "PingFang SC",Arial,sans-serif; }
  a {
    text-decoration: none;
    color: #ff6b23; }
  img {
    display: block;
    width: 100%;
    height: 100%; }
  ::selection {
    background-color: rgba(165, 195, 245, 0.6) !important;
    color: inherit !important; }
  body {
    min-width: 1200px; }
  .r-content {
    min-height: calc(100vh - 60px - 400px); }
  #rc-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 300px;
    margin: -150px 0 0 -150px;
    text-align: center;
    line-height: 100px;
    font-size: 20px;
    border-radius: 50%;
    overflow: hidden; }
  .clearfix {
    zoom: 1;
    /*==for IE6/7 Maxthon2==*/ }
    .clearfix::after {
      /*==for FF/chrome/opera/IE8==*/
      clear: both;
      content: ".";
      display: block;
      width: 0;
      height: 0;
      visibility: hidden; }
  .single-line {
    white-space: nowrap;
    text-overflow: ellipsis; }
  .two-line {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 2.4em;
    line-height: 1.2em; }
    .two-line:before {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "...";
      z-index: -1; }

@-webkit-keyframes nprogress-spinner___ePstQ {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes nprogress-spinner___ePstQ {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  #nprogress {
    pointer-events: none; }
    #nprogress .bar {
      background: #ff6b23;
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px; }
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px #ff6b23, 0 0 5px #ff6b23;
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
      transform: rotate(3deg) translate(0px, -4px); }
    #nprogress .spinner {
      display: none;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px; }
    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: #ff6b23;
      border-left-color: #ff6b23;
      border-radius: 50%;
      -webkit-animation: nprogress-spinner___ePstQ 400ms linear infinite;
      animation: nprogress-spinner___ePstQ 400ms linear infinite; }
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative; }
    .nprogress-custom-parent #nprogress .spinner {
      position: absolute; }
    .nprogress-custom-parent #nprogress .bar {
      position: absolute; }
  input::-webkit-credentials-auto-fill-button {
    display: none !important;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    /* 避免占用 input 元素额外的 padding，正常情况下存在 display: none!; 就可以了 */
    right: 0; }
  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0; }
