@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-detail-consignment-banner-s {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  height: 85px;
  width: 85px;
  overflow: hidden;
  background: url(https://img1.rrcimg.com/Bitmap-Copy-22@2x.png);
  background-size: 100% 100%;
  transition: all 1s;
  cursor: pointer; }
  .r-detail-consignment-banner-s.close {
    left: -100%; }

.r-detail-consignment-banner-l {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  height: 85px;
  width: 100%;
  background: url(https://img1.rrcimg.com/youxuanche_concept_bg.png);
  background-size: 100% 100%;
  transition: all 1s; }
  .r-detail-consignment-banner-l .text {
    height: 30px;
    width: 550px;
    margin: 28px auto 0;
    background: url(https://img1.rrcimg.com/youxuanche_concept_img.png);
    background-size: 100% 100%; }
  .r-detail-consignment-banner-l .close-btn {
    font-size: 38px;
    color: #9e5b08;
    position: absolute;
    right: 20px;
    top: 20px; }
  .r-detail-consignment-banner-l.close {
    left: -100%; }
