@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-inocity {
  width: 792px; }
  .r-inocity .r-inocity-head {
    width: 100%;
    height: 34px;
    position: relative;
    background: url(//img1.rrcimg.com/_next/static/src/assets/not-open-logo_1edgY.png) no-repeat #e14800 51px 8px; }
    .r-inocity .r-inocity-head .close-con {
      float: right;
      width: 22px;
      height: 22px;
      margin: 5px 10px 0 0;
      cursor: pointer;
      color: #fff; }
  .r-inocity .r-inocity-city {
    padding: 40px 0;
    width: 700px;
    margin: 0 auto;
    font-size: 14px;
    position: relative; }
    .r-inocity .r-inocity-city .ip-address {
      position: relative;
      z-index: 1; }
      .r-inocity .r-inocity-city .ip-address .title {
        color: #666; }
        .r-inocity .r-inocity-city .ip-address .title .img-position {
          font-size: 18px;
          display: inline-block;
          color: #e14801; }
        .r-inocity .r-inocity-city .ip-address .title .nc {
          font-size: 18px;
          color: #222; }
        .r-inocity .r-inocity-city .ip-address .title .position-problem {
          color: #999;
          text-decoration: none;
          cursor: pointer;
          font-size: 12px;
          margin: 0 14px 0 0; }
        .r-inocity .r-inocity-city .ip-address .title .not-notice {
          color: #222; }
        .r-inocity .r-inocity-city .ip-address .title .change-position {
          width: 400px;
          position: absolute;
          left: 100px;
          border: #ccc solid 1px;
          background-color: #fff;
          z-index: 1; }
          .r-inocity .r-inocity-city .ip-address .title .change-position .close-change-position {
            width: 12px;
            height: 12px;
            position: absolute;
            right: 10px;
            top: 10px;
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/no-open-clean_2Hfjv.png);
            background-position: 0 0;
            cursor: pointer; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .close-change-position:hover {
              background-image: url(//img1.rrcimg.com/_next/static/src/assets/no-open-clean_2Hfjv.png);
              background-position: -12px 0; }
          .r-inocity .r-inocity-city .ip-address .title .change-position .change-tab {
            list-style: none;
            height: 36px; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-tab .province,
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-tab .city {
              width: 100px;
              height: 36px;
              float: left;
              background: #f2f2f3;
              text-align: center;
              line-height: 36px;
              border-width: 0 1px 1px 0;
              border-color: #ccc;
              border-style: solid; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-tab .province {
              cursor: pointer; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-tab .empty {
              float: left;
              width: 198px;
              height: 36px;
              background-color: #fff;
              border-width: 0 0 1px;
              border-color: #ccc;
              border-style: solid; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-tab .selected {
              color: #e14800;
              background-color: #fff;
              border-bottom: 0; }
          .r-inocity .r-inocity-city .ip-address .title .change-position .change-content {
            background: #fff; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .selected {
              display: block; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .provinces-area {
              margin: 14px 34px 23px 36px;
              width: 330px;
              position: relative; }
              .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .provinces-area .part-provinces {
                margin-left: 35px;
                position: relative; }
                .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .provinces-area .part-provinces .part {
                  position: absolute;
                  left: -35px;
                  top: 5px;
                  font-weight: bold; }
                .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .provinces-area .part-provinces a {
                  color: #333;
                  background-color: #fff;
                  display: inline-block;
                  height: 24px;
                  line-height: 24px;
                  padding: 0 10px;
                  margin: 3px;
                  text-decoration: none; }
                  .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .provinces-area .part-provinces a:hover {
                    color: #e14800; }
            .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .cities-area {
              margin: 14px 37px 25px;
              width: 326px;
              position: relative; }
              .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .cities-area a {
                color: #333;
                background-color: #fff;
                display: inline-block;
                height: 18px;
                line-height: 18px;
                padding: 0 10px;
                margin: 3px;
                text-decoration: none; }
                .r-inocity .r-inocity-city .ip-address .title .change-position .change-content .cities-area a:hover {
                  color: #e14800; }
      .r-inocity .r-inocity-city .ip-address__city-select {
        position: absolute;
        top: 28px;
        width: 701px;
        height: 435px;
        overflow: hidden; }
        .r-inocity .r-inocity-city .ip-address__city-select .city-select__change {
          width: 100%;
          height: 33px;
          background-color: #fff;
          border: 1px solid #ececec;
          border-bottom: none;
          text-align: left;
          padding-right: 20px; }
          .r-inocity .r-inocity-city .ip-address__city-select .city-select__change-city-list {
            width: 95%;
            display: inline-block;
            padding-left: 10px; }
            .r-inocity .r-inocity-city .ip-address__city-select .city-select__change-city-list li {
              display: inline;
              margin: 0 8px;
              cursor: pointer; }
              .r-inocity .r-inocity-city .ip-address__city-select .city-select__change-city-list li a {
                color: #333; }
                .r-inocity .r-inocity-city .ip-address__city-select .city-select__change-city-list li a:hover {
                  color: #e14800; }
          .r-inocity .r-inocity-city .ip-address__city-select .city-select__change .close-btn {
            line-height: 33px;
            display: inline-block;
            cursor: pointer; }
            .r-inocity .r-inocity-city .ip-address__city-select .city-select__change .close-btn:hover {
              color: #e14800; }
        .r-inocity .r-inocity-city .ip-address__city-select .change-city {
          width: 100%;
          height: 400px;
          overflow-y: auto;
          background-color: #fff;
          position: absolute;
          z-index: 1;
          border: 1px solid #ececec; }
          .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box {
            width: 100%;
            margin: 0 auto;
            background-color: #fff; }
            .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item {
              font-size: 14px;
              padding: 10px; }
              .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item .title {
                display: inline-block;
                width: 10px;
                margin-right: 10px;
                line-height: 20px;
                color: #f96b25;
                vertical-align: top; }
                .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item .title.zb {
                  width: 50px; }
              .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item .cities {
                width: 300px;
                display: inline-block;
                overflow: hidden;
                line-height: 20px;
                vertical-align: top; }
                .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item .cities span {
                  display: inline-block;
                  margin: 0 14px 4px 0; }
                  .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item .cities span:hover > a {
                    color: #f96b25; }
                  .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item .cities span a {
                    color: #333; }
                  .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .box-item .cities span .citySelect {
                    color: #f96b25; }
            .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .letter {
              position: relative;
              margin-top: 20px; }
              .r-inocity .r-inocity-city .ip-address__city-select .change-city .change-city__box .letter-column {
                box-sizing: border-box;
                width: 50%;
                float: left; }
    .r-inocity .r-inocity-city .introduce-cities-result {
      height: 60px;
      position: relative; }
      .r-inocity .r-inocity-city .introduce-cities-result .cities-result-left {
        float: left;
        width: 100px;
        padding: 16px 0 20px 0;
        font-size: 14px;
        line-height: 24px;
        color: #666; }
      .r-inocity .r-inocity-city .introduce-cities-result .cities-result-right {
        float: right;
        width: 102px;
        line-height: 37px;
        background-color: #e14800;
        text-align: center;
        color: #fff;
        margin-top: 10px;
        cursor: pointer; }
      .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center {
        position: absolute;
        top: 10px;
        left: 100px;
        width: 500px;
        margin-top: 4px; }
        .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .choose-city {
          height: 30px;
          cursor: pointer;
          float: left;
          font-size: 14px;
          color: #222;
          padding: 0 30px 0 10px;
          border: 1px solid #ddd;
          background-color: #fff;
          position: relative;
          line-height: 30px;
          margin-right: 5px; }
          .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .choose-city .result-close {
            width: 12px;
            height: 12px;
            position: absolute;
            right: 6px;
            top: 9px;
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/no-open-clean_2Hfjv.png);
            background-position: 0 0;
            cursor: pointer; }
          .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .choose-city:hover {
            border-color: #e14800; }
            .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .choose-city:hover .result-close {
              background-image: url(//img1.rrcimg.com/_next/static/src/assets/no-open-clean_2Hfjv.png);
              background-position: -12px 0; }
        .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box {
          float: left;
          position: relative; }
          .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .city-choose {
            color: #e14800;
            width: 50px;
            font-size: 12px;
            position: relative;
            top: 5px;
            cursor: pointer;
            margin-left: 15px; }
            .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .city-choose .div_city_img {
              width: 10px;
              height: 6px;
              position: absolute;
              right: -13px;
              top: 7px;
              background-image: url(//img1.rrcimg.com/_next/static/src/assets/no-open-clean_2Hfjv.png);
              background-position: 0 -12px; }
          .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city {
            text-align: left;
            width: 400px;
            height: 325px;
            overflow-y: auto;
            background-color: #fff;
            position: absolute;
            padding: 15px 37px 30px 30px;
            top: 40px;
            left: -150px;
            box-shadow: 0 0 10px #eee;
            border: 1px solid #eee;
            font-size: 14px;
            z-index: 11; }
            .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .close-area-city {
              width: 12px;
              height: 12px;
              position: absolute;
              right: 10px;
              top: 10px;
              background-image: url(//img1.rrcimg.com/_next/static/src/assets/no-open-clean_2Hfjv.png);
              background-position: 0 0;
              cursor: pointer; }
              .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .close-area-city:hover {
                background-image: url(//img1.rrcimg.com/_next/static/src/assets/no-open-clean_2Hfjv.png);
                background-position: -12px 0; }
            .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .area-line {
              margin-top: 8px;
              position: relative;
              padding-left: 42px; }
              .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .area-line:last-child {
                margin-bottom: 32px; }
              .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .area-line .area {
                left: 0;
                position: absolute;
                line-height: 29px;
                color: #999; }
              .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .area-line .province-item {
                color: #333;
                background-color: #fff;
                display: inline-block;
                height: 24px;
                line-height: 24px;
                padding: 0 10px;
                margin: 3px;
                text-decoration: none; }
                .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .area-line .province-item:hover {
                  color: #e14800; }
              .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .city-choose-box .area-city .area-line .active {
                color: #e14800; }
        .r-inocity .r-inocity-city .introduce-cities-result .cities-result-center .requirement {
          float: left;
          margin-top: 2px;
          margin-left: 20px;
          height: 24px;
          line-height: 24px;
          color: #666; }
    .r-inocity .r-inocity-city .remind {
      font-size: 12px;
      color: #999;
      margin-left: 100px; }
  .r-inocity .r-inocity-process {
    width: 100%;
    height: 172px;
    background: url(//img1.rrcimg.com/_next/static/src/assets/not-process_1LwAN.png) no-repeat center;
    background-size: contain; }
  .r-inocity .r-inocity-question {
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 14px; }
    .r-inocity .r-inocity-question .picture-area {
      width: 20%;
      float: left;
      padding-left: 65px; }
      .r-inocity .r-inocity-question .picture-area .question-img {
        width: 60px;
        height: 68px;
        background: url(//img1.rrcimg.com/_next/static/src/assets/question_852sB.png) center no-repeat; }
      .r-inocity .r-inocity-question .picture-area .question-text {
        color: #999;
        margin-top: 7px; }
    .r-inocity .r-inocity-question .content {
      width: 80%;
      height: 210px;
      float: left;
      overflow-y: auto; }
      .r-inocity .r-inocity-question .content .question {
        color: #fd521d;
        line-height: 30px;
        cursor: pointer;
        user-select: none; }
      .r-inocity .r-inocity-question .content .answer {
        width: 95%;
        display: none;
        color: #222;
        line-height: 30px; }
