@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-inspect-report-detail {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box; }
  .r-inspect-report-detail .ellips {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .r-inspect-report-detail .r-inspect-report-detail__lessV6 {
    width: 1200px;
    margin: 50px auto;
    border: 1px solid #ececec; }
    .r-inspect-report-detail .r-inspect-report-detail__lessV6-infol {
      width: 645px;
      float: left;
      padding: 89px 80px 0; }
      .r-inspect-report-detail .r-inspect-report-detail__lessV6-infol-title {
        font-size: 34px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 30px; }
      .r-inspect-report-detail .r-inspect-report-detail__lessV6-infol-desc {
        font-size: 14px;
        text-align: center;
        line-height: 21px; }
    .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor {
      float: left; }
      .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-erweima {
        width: 170px;
        height: 170px;
        overflow: hidden;
        margin: 79px 20px 65px 0px;
        float: left; }
        .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-erweima img {
          width: 100%; }
      .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-step {
        margin-top: 92px;
        float: left; }
        .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-step-title {
          margin-bottom: 17px;
          font-size: 22px;
          font-weight: 500;
          line-height: 30px; }
        .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-step .lessV6-step .ant-steps-item-icon {
          width: 24px;
          height: 24px;
          font-size: 18px;
          line-height: 24px; }
        .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-step .lessV6-step .ant-steps-item-content {
          min-height: 53px; }
          .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-step .lessV6-step .ant-steps-item-content .ant-steps-item-title {
            font-size: 14px;
            line-height: 24px;
            font-weight: unset; }
        .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-step .lessV6-step > .ant-steps-item
> .ant-steps-item-container
> .ant-steps-item-tail {
          position: absolute;
          top: 0;
          left: 12px;
          width: 1px;
          height: 100%;
          padding: 24px 0 0px; }
        .r-inspect-report-detail .r-inspect-report-detail__lessV6-infor-step .lessV6-step .ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-tail::after {
          background-color: #ff6b23; }
  .r-inspect-report-detail .major_acc_inves {
    font-size: 14px; }
    .r-inspect-report-detail .major_acc_inves .title {
      height: 58px;
      line-height: 58px; }
      .r-inspect-report-detail .major_acc_inves .title .acc_name {
        font-size: 22px;
        color: rgba(0, 0, 0, 0.78); }
      .r-inspect-report-detail .major_acc_inves .title .hg_sign {
        display: inline-block;
        width: 47px;
        height: 26px;
        margin-left: 25px;
        background: #71ceb5;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        line-height: 26px;
        text-align: center;
        vertical-align: text-bottom; }
      .r-inspect-report-detail .major_acc_inves .title .title_des {
        display: inline-block;
        max-width: 850px;
        margin-left: 25px;
        white-space: nowrap;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 14px; }
    .r-inspect-report-detail .major_acc_inves .icon-test_icon {
      vertical-align: middle;
      color: #71ceb5; }
    .r-inspect-report-detail .major_acc_inves .case_head {
      display: inline-block;
      margin-left: 26px; }
      .r-inspect-report-detail .major_acc_inves .case_head .case_title {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.78);
        line-height: 20px; }
      .r-inspect-report-detail .major_acc_inves .case_head .case_num {
        margin-top: 20px;
        height: 20px;
        line-height: 20px; }
        .r-inspect-report-detail .major_acc_inves .case_head .case_num .test-num {
          display: inline-block;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.78);
          line-height: 18px;
          margin-left: 11px;
          margin-right: 30px;
          vertical-align: middle; }
    .r-inspect-report-detail .major_acc_inves .icon-test_smicon {
      color: #71ceb5;
      vertical-align: middle;
      margin-left: 3px;
      margin-right: 6px;
      font-size: 15px; }
    .r-inspect-report-detail .major_acc_inves .major_block:nth-child(3) {
      margin: 0 19px; }
    .r-inspect-report-detail .major_acc_inves .major_block {
      display: inline-block;
      box-sizing: border-box;
      width: 385px;
      background: #fff;
      border: 1px solid #ececec;
      padding: 30px 28px;
      vertical-align: top; }
      .r-inspect-report-detail .major_acc_inves .major_block .paicIcon {
        font-size: 66px;
        color: #848494; }
      .r-inspect-report-detail .major_acc_inves .major_block .case_icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        vertical-align: top; }
      .r-inspect-report-detail .major_acc_inves .major_block .clearfixnew:after {
        display: block;
        height: 0;
        line-height: 0;
        clear: both; }
    .r-inspect-report-detail .major_acc_inves .module_content_box {
      overflow: hidden;
      border: 1px solid #ececec;
      padding: 20px 30px; }
      .r-inspect-report-detail .major_acc_inves .module_content_box .defectNum {
        color: #ffa14d;
        vertical-align: middle; }
      .r-inspect-report-detail .major_acc_inves .module_content_box .content_head {
        height: 40px;
        padding-top: 10px;
        box-sizing: border-box; }
        .r-inspect-report-detail .major_acc_inves .module_content_box .content_head .icon-test_icon {
          vertical-align: middle;
          color: #71ceb5; }
        .r-inspect-report-detail .major_acc_inves .module_content_box .content_head .test-num {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.78);
          line-height: 18px;
          margin-left: 11px;
          margin-right: 30px;
          vertical-align: middle; }
      .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content {
        padding-top: 20px; }
        .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item {
          position: relative;
          float: left;
          width: 163px;
          height: 40px; }
          .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item:hover {
            overflow: initial; }
            .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item:hover .test-item-name {
              color: #ff6b23; }
          .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .icon-test_smicon {
            color: #71ceb5;
            vertical-align: middle;
            margin-right: 10px; }
          .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .pass_item_name {
            vertical-align: middle;
            overflow: hidden; }
          .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .report-case-des-box {
            position: absolute;
            bottom: 39px;
            left: -38px;
            width: 239px;
            padding-bottom: 10px;
            z-index: 9999; }
            .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .report-case-des-box .report-case-des-wraper {
              position: absolute;
              bottom: 10px;
              left: 0;
              width: 199px;
              padding: 20px 20px 26px;
              background: #ffffff;
              box-shadow: 0 0 8px 0 rgba(221, 221, 221, 0.5); }
              .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .report-case-des-box .report-case-des-wraper .report-case-des-content:not(:first-of-type) {
                margin-top: 20px; }
            .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .report-case-des-box .report-case-des-arrow {
              position: absolute;
              bottom: -16px;
              left: 112px;
              width: 0;
              height: 0;
              border: 8px solid transparent;
              border-top-color: #eee; }
            .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .report-case-des-box .report-case-arrow-inside {
              position: absolute;
              top: -8px;
              left: -7px;
              width: 0;
              height: 0;
              border: 7px solid transparent;
              border-top-color: #fff; }
            .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .report-case-des-box .report-case-des-title {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.78);
              line-height: 18px;
              word-break: break-all;
              white-space: normal; }
            .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item .report-case-des-box .report-case-des-word {
              margin-top: 10px;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.36);
              line-height: 18px;
              word-break: break-all;
              white-space: normal; }
        .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item:nth-child(2n + 2) {
          margin-left: 20px; }
        .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item:nth-child(6n + 3) {
          margin-left: 50px; }
        .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .content_item:nth-child(6n + 5) {
          margin-left: 50px; }
        .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .anomaly {
          color: #ffa14d; }
          .r-inspect-report-detail .major_acc_inves .module_content_box .module_content_content .anomaly .icon-tip-icon2-07d8d303 {
            vertical-align: middle;
            margin-right: 10px; }
    .r-inspect-report-detail .major_acc_inves .appence-item:nth-child(3) {
      margin-left: 16px; }
    .r-inspect-report-detail .major_acc_inves .component_content {
      white-space: nowrap; }
      .r-inspect-report-detail .major_acc_inves .component_content .component_item:not(:first-child) {
        margin-left: 18px; }
      .r-inspect-report-detail .major_acc_inves .component_content .component_item {
        display: inline-block;
        width: 181px;
        box-sizing: border-box;
        border: 1px solid #ececec;
        background: #fff;
        padding: 30px 0 20px;
        text-align: center;
        vertical-align: top; }
        .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_title {
          margin-top: 16px;
          text-align: center;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.78);
          line-height: 20px; }
        .r-inspect-report-detail .major_acc_inves .component_content .component_item .icon {
          font-size: 60px;
          color: #848494; }
        .r-inspect-report-detail .major_acc_inves .component_content .component_item .defectNum {
          color: #ffa14d;
          vertical-align: middle; }
        .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_num {
          margin-top: 22px;
          margin-right: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.78); }
          .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_num .num {
            padding-left: 3px;
            vertical-align: middle; }
          .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_num .padd-l {
            padding-left: 8px; }
        .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list {
          margin: 22px 0 0 20px;
          text-align: left; }
          .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item {
            position: relative;
            height: 40px;
            max-width: 150px; }
            .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .tool_line {
              vertical-align: middle;
              color: #a3a3a3;
              margin-right: 5px; }
            .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .none {
              color: #a3a3a3; }
            .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .report-case-des-box {
              position: absolute;
              bottom: 39px;
              left: -38px;
              width: 239px;
              padding-bottom: 10px;
              z-index: 9999; }
              .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .report-case-des-box .report-case-des-wraper {
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 199px;
                padding: 20px 20px 26px;
                background: #ffffff;
                box-shadow: 0 0 8px 0 rgba(221, 221, 221, 0.5); }
                .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .report-case-des-box .report-case-des-wraper .report-case-des-content:not(:first-of-type) {
                  margin-top: 20px; }
              .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .report-case-des-box .report-case-des-arrow {
                position: absolute;
                bottom: -16px;
                left: 112px;
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-top-color: #eee; }
              .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .report-case-des-box .report-case-arrow-inside {
                position: absolute;
                top: -8px;
                left: -7px;
                width: 0;
                height: 0;
                border: 7px solid transparent;
                border-top-color: #fff; }
              .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .report-case-des-box .report-case-des-title {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.78);
                line-height: 18px;
                word-break: break-all;
                white-space: normal; }
              .r-inspect-report-detail .major_acc_inves .component_content .component_item .component_list .item .report-case-des-box .report-case-des-word {
                margin-top: 10px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.36);
                line-height: 18px;
                word-break: break-all;
                white-space: normal; }
