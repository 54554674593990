@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-footer {
  width: 100%;
  height: 400px;
  background-color: #4c4c4c; }
  .r-footer-container {
    width: 1200px;
    height: 320px;
    margin: 0 auto;
    padding-top: 30px; }
    .r-footer-container .baozhang {
      height: 74px;
      font-size: 16px;
      color: #fff; }
      .r-footer-container .baozhang ul {
        display: inline; }
        .r-footer-container .baozhang ul li {
          display: inline-block;
          height: 74px;
          line-height: 74px; }
          .r-footer-container .baozhang ul li:not(:nth-of-type(5)) {
            margin-right: 134px; }
          .r-footer-container .baozhang ul li .iconfont {
            font-size: 38px;
            position: relative;
            top: 8px; }
    .r-footer-container .link {
      margin: 40px 0; }
      .r-footer-container .link-left {
        float: left; }
        .r-footer-container .link-left_item {
          display: inline-block;
          vertical-align: top;
          margin-right: 60px; }
          .r-footer-container .link-left_item-title {
            font-size: 16px;
            color: #fff;
            margin-bottom: 6px; }
          .r-footer-container .link-left_item ul {
            display: inline-block;
            vertical-align: top;
            margin-right: 40px; }
            .r-footer-container .link-left_item ul li {
              line-height: 24px;
              font-size: 14px; }
              .r-footer-container .link-left_item ul li a {
                color: #adadad; }
                .r-footer-container .link-left_item ul li a:hover {
                  color: #fd521d; }
      .r-footer-container .link-right {
        float: right;
        width: 440px;
        position: relative; }
        .r-footer-container .link-right_tel {
          width: 160px;
          float: left;
          color: #fff; }
          .r-footer-container .link-right_tel .number {
            font-size: 22px;
            line-height: 34px;
            margin-top: 20px; }
          .r-footer-container .link-right_tel .time {
            font-size: 14px; }
          .r-footer-container .link-right_tel .text {
            font-size: 12px;
            line-height: 24px;
            margin: 5px 0 0;
            color: #979797; }
        .r-footer-container .link-right_sns {
          display: block;
          float: left; }
          .r-footer-container .link-right_sns .item {
            display: inline-block;
            font-size: 12px;
            line-height: 24px;
            color: #979797;
            position: relative;
            cursor: pointer; }
            .r-footer-container .link-right_sns .item a {
              color: #979797; }
              .r-footer-container .link-right_sns .item a:hover {
                color: #fd521d; }
            .r-footer-container .link-right_sns .item:not(:nth-last-of-type(1)) {
              margin-right: 36px; }
            .r-footer-container .link-right_sns .item span.img {
              width: 24px;
              height: 24px;
              background-color: #414141;
              border-radius: 4px;
              padding: 4px;
              display: block;
              float: left;
              margin-right: 4px; }
              .r-footer-container .link-right_sns .item span.img span {
                display: block;
                width: 16px;
                height: 16px; }
                .r-footer-container .link-right_sns .item span.img span.zixun {
                  background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
                  background-position: 0px -66px;
                  background-size: 130.000px 106.000px; }
                .r-footer-container .link-right_sns .item span.img span.weibo {
                  background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
                  background-position: -22px -66px;
                  background-size: 130.000px 106.000px; }
                .r-footer-container .link-right_sns .item span.img span.tousu {
                  background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
                  background-position: -44px -66px;
                  background-size: 130.000px 106.000px; }
                .r-footer-container .link-right_sns .item span.img span.jubao {
                  background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
                  background-position: 0px -89px;
                  background-size: 130.000px 106.000px; }
            .r-footer-container .link-right_sns .item .popover-img {
              width: 131px;
              height: 66px;
              max-width: 200%;
              position: absolute;
              top: -62px;
              left: -24px;
              background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
              background-position: 0px 0px;
              background-size: 130.000px 106.000px; }
        .r-footer-container .link-right_app {
          float: left; }
          .r-footer-container .link-right_app .item {
            width: 88px;
            float: left;
            text-align: center;
            margin-left: 32px; }
            .r-footer-container .link-right_app .item:nth-child(1) {
              margin-left: 64px; }
            .r-footer-container .link-right_app .item span {
              color: #bbbbbb;
              padding-bottom: 8px; }
            .r-footer-container .link-right_app .item img {
              display: block;
              margin: 8px 0 24px 0;
              width: 88px;
              height: 88px; }
  .r-footer-copyright {
    height: 80px;
    line-height: 80px;
    font-size: 12px;
    color: #fff;
    background-color: #2f2f2f;
    text-align: center; }
    .r-footer-copyright-container {
      width: 1200px;
      margin: 0 auto; }
      .r-footer-copyright-container a {
        display: inline-block;
        padding-right: 20px;
        color: #fff; }
        .r-footer-copyright-container a div {
          display: inline-block;
          width: 14px;
          height: 14px;
          position: relative;
          top: 3px;
          right: 3px; }
          .r-footer-copyright-container a div.public {
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
            background-position: -66px -66px;
            background-size: 130.000px 106.000px; }
          .r-footer-copyright-container a div.chengxin {
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
            background-position: -86px -66px;
            background-size: 130.000px 106.000px; }
          .r-footer-copyright-container a div.yingyezhizhao {
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/footer_2otQ7.png);
            background-position: -106px -66px;
            background-size: 130.000px 106.000px; }
