@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.rc-sms-verify___y93h8 {
  height: 34px;
  box-sizing: border-box;
  position: relative; }
  .rc-sms-verify___y93h8 .getsmscode-btn {
    background: transparent;
    color: white;
    border: none;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px; }
    .rc-sms-verify___y93h8 .getsmscode-btn--default {
      color: #fd521d;
      background-color: transparent; }
    .rc-sms-verify___y93h8 .getsmscode-btn--disabled {
      color: #a3a3a3; }
      .rc-sms-verify___y93h8 .getsmscode-btn--disabled .seconds {
        width: 20px; }
