@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.brand-series-select__value {
  text-align: left; }

.brand-series-select ul.ant-cascader-menu {
  height: 386px; }
  .brand-series-select ul.ant-cascader-menu:first-child {
    min-width: unset;
    width: 32px;
    overflow: hidden; }
    .brand-series-select ul.ant-cascader-menu:first-child li {
      height: 16px;
      line-height: 16px;
      padding: 0 12px; }
      .brand-series-select ul.ant-cascader-menu:first-child li:first-child {
        padding: 7px;
        height: 28px; }
      .brand-series-select ul.ant-cascader-menu:first-child li.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon {
        display: none; }
  .brand-series-select ul.ant-cascader-menu .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .brand-series-select ul.ant-cascader-menu .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    color: #ff6b23; }
