@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-img-verify___1jYAX {
  position: relative;
  width: 100%; }
  .r-img-verify___1jYAX .brush-code {
    width: 54%;
    float: left;
    box-sizing: border-box; }
  .r-img-verify___1jYAX .brush-wrapper {
    width: 40%;
    height: 38px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-left: 6%; }
    .r-img-verify___1jYAX .brush-wrapper .brush-img {
      width: 70%;
      float: left; }
    .r-img-verify___1jYAX .brush-wrapper .brush-refresh {
      width: 30%;
      height: 38px;
      line-height: 38px;
      float: right;
      cursor: pointer;
      text-align: center; }
      .r-img-verify___1jYAX .brush-wrapper .brush-refresh i {
        font-size: 20px;
        color: #979797; }
