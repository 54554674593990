@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-partner-add___uMMPa {
  padding: 20px 30px; }
  .r-partner-add___uMMPa .r-partner-add__header {
    text-align: center;
    margin-bottom: 30px; }
    .r-partner-add___uMMPa .r-partner-add__header h2 {
      font-size: 20px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.8);
      text-align: center;
      font-weight: 500;
      margin-bottom: 10px; }
    .r-partner-add___uMMPa .r-partner-add__header h3 {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      text-align: center; }
  .r-partner-add___uMMPa .r-partner-add__content li {
    margin-bottom: 10px; }
    .r-partner-add___uMMPa .r-partner-add__content li:after {
      display: block;
      content: '';
      clear: both; }
    .r-partner-add___uMMPa .r-partner-add__content li p {
      width: 219px;
      float: left;
      margin-right: 23px;
      line-height: 40px;
      text-align: right;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6); }
    .r-partner-add___uMMPa .r-partner-add__content li span {
      color: rgba(255, 0, 0, 0.6); }
    .r-partner-add___uMMPa .r-partner-add__content li .address,
    .r-partner-add___uMMPa .r-partner-add__content li .name,
    .r-partner-add___uMMPa .r-partner-add__content li .date,
    .r-partner-add___uMMPa .r-partner-add__content li .remark {
      float: left;
      display: inline-block;
      width: 360px;
      height: 40px;
      font-size: 14px;
      border-radius: 4px; }
    .r-partner-add___uMMPa .r-partner-add__content li .city-select {
      float: left;
      width: 360px; }
  .r-partner-add___uMMPa .r-partner-add__form-item label {
    font-size: 14px; }
  .r-partner-add___uMMPa .r-partner-add__button {
    text-align: center;
    margin-bottom: 0px; }
    .r-partner-add___uMMPa .r-partner-add__button .button {
      width: 220px;
      line-height: 40px;
      background: #FF6B23;
      border-radius: 4px;
      font-size: 18px;
      color: #FFF;
      text-align: center;
      cursor: pointer; }
