@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.ant-tooltip-inner {
  background-color: #fcc89e;
  white-space: nowrap; }

.ant-tooltip-arrow::before {
  background-color: #fcc89e; }

.ant-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -6px; }

.r-detail-discount-notify-guide {
  padding: 20px 80px 40px; }
  .r-detail-discount-notify-guide .logo {
    width: 90px;
    height: 90px;
    border-radius: 45px;
    margin: -65px auto 16px;
    text-align: center;
    background: url(//img1.rrcimg.com/_next/static/src/assets/popwin_sW-yB.png) no-repeat #fff;
    background-position-y: -6px;
    background-size: cover;
    transition: all 0.5s; }
    .r-detail-discount-notify-guide .logo.level1 {
      background-position-x: -2px; }
    .r-detail-discount-notify-guide .logo.level2 {
      background-position-x: -92px; }
    .r-detail-discount-notify-guide .logo.level3 {
      background-position-x: -182px; }
    .r-detail-discount-notify-guide .logo.level4 {
      background-position-x: -272px; }
  .r-detail-discount-notify-guide .title {
    text-align: center;
    font-size: 26px;
    color: #222; }
  .r-detail-discount-notify-guide .msg {
    text-align: center;
    color: #666;
    margin: 12px 0 8px;
    font-size: 16px; }
    .r-detail-discount-notify-guide .msg i {
      color: #f90;
      margin-right: 5px;
      font-size: 16px; }
  .r-detail-discount-notify-guide .price-slider .ant-slider-rail,
  .r-detail-discount-notify-guide .price-slider .ant-slider-track,
  .r-detail-discount-notify-guide .price-slider .ant-slider-rail {
    height: 9px; }
  .r-detail-discount-notify-guide .price-slider .ant-slider-dot {
    width: 13px;
    height: 13px; }
  .r-detail-discount-notify-guide .priceTo,
  .r-detail-discount-notify-guide .phone {
    margin-bottom: 12px; }
  .r-detail-discount-notify-guide .img-verify .brush-code {
    width: 200px;
    float: left; }
  .r-detail-discount-notify-guide .img-verify .brush-wrapper {
    width: 140px;
    height: 40px;
    display: inline-block;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-left: 20px; }
    .r-detail-discount-notify-guide .img-verify .brush-wrapper .brush-img {
      width: 100px;
      float: left; }
    .r-detail-discount-notify-guide .img-verify .brush-wrapper .brush-refresh {
      width: 38px;
      height: 38px;
      float: right;
      background: url(//img1.rrcimg.com/_next/static/src/assets/refresh_8gdJ5.png);
      cursor: pointer; }
  .r-detail-discount-notify-guide .err-msg {
    color: #f00; }
  .r-detail-discount-notify-guide .submit {
    font-size: 18px; }
  .r-detail-discount-notify-guide .tip {
    margin-top: 18px; }
    .r-detail-discount-notify-guide .tip .check {
      float: left;
      margin-right: 5px; }
    .r-detail-discount-notify-guide .tip .txt {
      overflow: hidden; }
