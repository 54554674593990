@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-appdown-container___3Li10 {
  background-color: #F4FAFF;
  height: 550px;
  position: relative; }
  .r-appdown-container___3Li10 .appdown-body {
    width: 1190px;
    margin: 0 auto;
    height: 498px;
    position: relative;
    background: url("//img1.rrcimg.com/dist/pc/images/appdown-newbg-ce801ec1.png") center center no-repeat; }
    .r-appdown-container___3Li10 .appdown-body .android, .r-appdown-container___3Li10 .appdown-body .appdown-qr, .r-appdown-container___3Li10 .appdown-body .ios, .r-appdown-container___3Li10 .appdown-body .promise-bg {
      position: absolute; }
    .r-appdown-container___3Li10 .appdown-body a:hover {
      outline: none; }
    .r-appdown-container___3Li10 .appdown-body .android, .r-appdown-container___3Li10 .appdown-body .ios {
      width: 143px;
      height: 40px;
      line-height: 40px;
      display: block;
      top: 414px;
      cursor: pointer;
      text-align: center; }
      .r-appdown-container___3Li10 .appdown-body .android .iconfont, .r-appdown-container___3Li10 .appdown-body .ios .iconfont {
        font-family: iconfont !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -webkit-text-stroke-width: 0.2px;
        -moz-osx-font-smoothing: grayscale; }
      .r-appdown-container___3Li10 .appdown-body .android .icon-android, .r-appdown-container___3Li10 .appdown-body .ios .icon-android {
        margin-right: 5;
        font-size: 21px;
        vertical-align: middle; }
        .r-appdown-container___3Li10 .appdown-body .android .icon-android::before, .r-appdown-container___3Li10 .appdown-body .ios .icon-android::before {
          content: "\E601"; }
      .r-appdown-container___3Li10 .appdown-body .android .icon-ios, .r-appdown-container___3Li10 .appdown-body .ios .icon-ios {
        margin-right: 3;
        font-size: 20px;
        vertical-align: middle; }
        .r-appdown-container___3Li10 .appdown-body .android .icon-ios::before, .r-appdown-container___3Li10 .appdown-body .ios .icon-ios::before {
          content: "\E605"; }
    .r-appdown-container___3Li10 .appdown-body .android {
      left: 682px;
      border: 1px solid #888e93;
      color: #5a5a5a;
      font-size: 15px; }
      .r-appdown-container___3Li10 .appdown-body .android:hover {
        border: 1px solid #fd6f1a;
        color: #fff;
        background-color: #fd6f1a;
        text-decoration: none; }
      .r-appdown-container___3Li10 .appdown-body .android .icon-android {
        margin-right: 5px;
        font-size: 21px;
        vertical-align: middle; }
    .r-appdown-container___3Li10 .appdown-body .ios {
      left: 870px;
      border: 1px solid #888e93;
      color: #5a5a5a;
      font-size: 15px; }
      .r-appdown-container___3Li10 .appdown-body .ios:hover {
        border: 1px solid #fd6f1a;
        color: #fff;
        background-color: #fd6f1a;
        text-decoration: none; }
      .r-appdown-container___3Li10 .appdown-body .ios .icon-ios {
        margin-right: 3px;
        font-size: 20px;
        vertical-align: middle; }
    .r-appdown-container___3Li10 .appdown-body .appdown-qr {
      width: 198px;
      height: 198px;
      padding: 5px;
      background-color: #fff;
      top: 120px;
      left: 750px; }
  .r-appdown-container___3Li10 .promise-bg {
    width: 100%;
    background-color: #62b1da;
    bottom: 0; }
    .r-appdown-container___3Li10 .promise-bg .promise-container {
      width: 1188px;
      height: 92px;
      margin: 0 auto;
      border-left: 1px solid #b1d8ed; }
      .r-appdown-container___3Li10 .promise-bg .promise-container .promise-box {
        width: 296px;
        height: 92px;
        float: left;
        border-right: 1px solid #b1d8ed; }
      .r-appdown-container___3Li10 .promise-bg .promise-container .promise-icon {
        float: left;
        margin: 12px 36px 0 24px;
        width: 67px;
        height: 67px;
        background: url("//img2.rrcimg.com/dist/pc/images/appdown-promise-icon-78e69c81.png") no-repeat; }
      .r-appdown-container___3Li10 .promise-bg .promise-container .promise-desc {
        color: #fff;
        font-size: 19px;
        height: 92px;
        line-height: 92px;
        float: left; }
      .r-appdown-container___3Li10 .promise-bg .promise-container .promise-icon-2 {
        background-position: -68px 0;
        margin-right: 16px; }
      .r-appdown-container___3Li10 .promise-bg .promise-container .promise-icon-3 {
        background-position: -135px 0;
        margin-right: 32px; }
      .r-appdown-container___3Li10 .promise-bg .promise-container .promise-icon-4 {
        background-position: -203px 0;
        margin-right: 40px; }
  .r-appdown-container___3Li10 .common-share {
    position: fixed;
    top: 30%;
    right: 0;
    background: #fcfcfc;
    box-shadow: 0 0 10px 0 black;
    z-index: 1000;
    cursor: pointer;
    width: 40px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 26px; }
