@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-sales___uV2Ux {
  padding-bottom: 58px; }
  .r-sales___uV2Ux .sell-submit-box-wrapper {
    width: 1200px;
    position: absolute;
    top: 82px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1; }
    .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box {
      float: right;
      width: 346px;
      min-height: 305px;
      border-radius: 6px;
      background-color: #fff;
      padding: 40px 20px 40px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06); }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .title-main {
        text-align: center;
        font-size: 30px; }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .title-sub {
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        color: #b3b3b8;
        margin: 5px auto 21px; }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit input,
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit .row {
        background-color: #f5f6f9;
        height: 48px; }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit input,
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit input:focus {
        border: none;
        box-shadow: none;
        border-radius: 2px;
        font-size: 18px; }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit .brush-wrapper {
        border: none; }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit .tip .check {
        margin-right: 8px; }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit .tip p {
        float: left;
        width: 280px; }
      .r-sales___uV2Ux .sell-submit-box-wrapper .sell-submit-box .sell-submit .submit-btn {
        margin: 14px auto 8px; }
  .r-sales___uV2Ux .r-sales__content .title-line {
    width: 48px;
    height: 2px;
    margin: 0 auto;
    background-color: #ff7e00;
    margin: 15px auto 38px; }
  .r-sales___uV2Ux .r-sales__content-service {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 60px 112px 60px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
    margin: -30px auto 0;
    position: relative; }
    .r-sales___uV2Ux .r-sales__content-service > .title {
      text-align: center;
      color: #ff7e00;
      font-size: 32px; }
    .r-sales___uV2Ux .r-sales__content-service .list-item {
      float: left;
      width: 33.33%;
      text-align: center; }
      .r-sales___uV2Ux .r-sales__content-service .list-item .icon {
        width: 100px;
        height: 100px;
        background-color: #fef0e0;
        border-radius: 50px;
        display: inline-block; }
        .r-sales___uV2Ux .r-sales__content-service .list-item .icon.dtnq {
          background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
          background-position: -232px 0px;
          background-size: 333.000px 333.000px; }
        .r-sales___uV2Ux .r-sales__content-service .list-item .icon.gjmc {
          background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
          background-position: -232px -107px;
          background-size: 333.000px 333.000px; }
        .r-sales___uV2Ux .r-sales__content-service .list-item .icon.sssx {
          background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
          background-position: 0px -232px;
          background-size: 333.000px 333.000px; }
      .r-sales___uV2Ux .r-sales__content-service .list-item .title {
        font-size: 26px;
        color: #262333;
        margin: 12px 0; }
      .r-sales___uV2Ux .r-sales__content-service .list-item .desc {
        font-size: 22px;
        color: #93939a; }
  .r-sales___uV2Ux .r-sales__content-process {
    width: 1190px;
    margin: 0 auto;
    padding: 102px 0 45px; }
    .r-sales___uV2Ux .r-sales__content-process .title {
      text-align: center;
      font-size: 34px;
      line-height: 36px;
      color: #333; }
    .r-sales___uV2Ux .r-sales__content-process .list {
      padding: 10px 0 0 56px; }
      .r-sales___uV2Ux .r-sales__content-process .list-item {
        height: 152px;
        display: inline-block;
        text-align: center; }
        .r-sales___uV2Ux .r-sales__content-process .list-item:not(.dot) {
          width: 156px; }
        .r-sales___uV2Ux .r-sales__content-process .list-item.dot {
          width: 56px;
          height: 30px;
          margin: 0 48px;
          background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
          background-position: -107px -232px;
          background-size: 333.000px 333.000px;
          position: relative;
          top: -86px; }
        .r-sales___uV2Ux .r-sales__content-process .list-item .icon {
          width: 110px;
          height: 110px;
          display: inline-block;
          background-size: cover; }
          .r-sales___uV2Ux .r-sales__content-process .list-item .icon.zxksyy {
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
            background-position: 0px 0px;
            background-size: 333.000px 333.000px; }
          .r-sales___uV2Ux .r-sales__content-process .list-item .icon.mfsmpg {
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
            background-position: -116px 0px;
            background-size: 333.000px 333.000px; }
          .r-sales___uV2Ux .r-sales__content-process .list-item .icon.dtsnck {
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
            background-position: 0px -116px;
            background-size: 333.000px 333.000px; }
          .r-sales___uV2Ux .r-sales__content-process .list-item .icon.sxdbgh {
            background-image: url(//img1.rrcimg.com/_next/static/src/assets/sell_33vS_.png);
            background-position: -116px -116px;
            background-size: 333.000px 333.000px; }
          .r-sales___uV2Ux .r-sales__content-process .list-item .icon.number-1::after, .r-sales___uV2Ux .r-sales__content-process .list-item .icon.number-2::after, .r-sales___uV2Ux .r-sales__content-process .list-item .icon.number-3::after {
            content: url(//img1.rrcimg.com/_next/static/src/assets/dot-arrow@2x_3jha-.png);
            position: relative;
            left: 248px;
            top: 16px; }
        .r-sales___uV2Ux .r-sales__content-process .list-item .title {
          font-size: 26px;
          line-height: unset;
          color: #262333;
          margin: 12px 0; }
  .r-sales___uV2Ux .r-sales__content-baomai {
    min-width: 1190px;
    padding: 34px 0 25px;
    background: linear-gradient(140deg, #ffeaab 0, #e3bd6c 100%);
    text-align: center; }
    .r-sales___uV2Ux .r-sales__content-baomai .title {
      text-align: center;
      line-height: 36px;
      font-size: 34px;
      color: #8c5313;
      margin-bottom: 30px; }
    .r-sales___uV2Ux .r-sales__content-baomai .list {
      width: 1100px;
      margin: 0 auto; }
      .r-sales___uV2Ux .r-sales__content-baomai .list-item {
        width: 33%;
        height: 178px;
        text-align: center;
        display: inline-block;
        background-color: white; }
        .r-sales___uV2Ux .r-sales__content-baomai .list-item .word {
          display: inline-block;
          margin: 68px 0 0 15px;
          text-align: left; }
          .r-sales___uV2Ux .r-sales__content-baomai .list-item .word .main {
            font-size: 20px;
            line-height: 20px;
            color: #8c5313; }
          .r-sales___uV2Ux .r-sales__content-baomai .list-item .word .sub {
            margin-top: 10px;
            font-size: 16px;
            line-height: 16px;
            color: #8c5313; }
        .r-sales___uV2Ux .r-sales__content-baomai .list-item::before {
          content: "";
          display: inline-block;
          width: 65px;
          height: 57px;
          position: relative;
          top: 8px; }
        .r-sales___uV2Ux .r-sales__content-baomai .list-item:nth-of-type(1)::before {
          background: url(//img1.rrcimg.com/_next/static/src/assets/dangtiannaqian_2HNIU.png);
          background-size: cover; }
        .r-sales___uV2Ux .r-sales__content-baomai .list-item:nth-of-type(2)::before {
          background: url(//img1.rrcimg.com/_next/static/src/assets/bimaibaozhang_1awgR.png);
          background-size: cover; }
        .r-sales___uV2Ux .r-sales__content-baomai .list-item:nth-of-type(3)::before {
          background: url(//img1.rrcimg.com/_next/static/src/assets/fangxintuoguan_17Qhl.png);
          background-size: cover; }
    .r-sales___uV2Ux .r-sales__content-baomai .show-detail {
      display: block;
      width: 181px;
      height: 46px;
      border: 1px solid #8c5313;
      margin: 27px auto 0;
      text-align: center;
      line-height: 46px;
      font-size: 16px;
      color: #8c5313; }
      .r-sales___uV2Ux .r-sales__content-baomai .show-detail::after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url(//img1.rrcimg.com/_next/static/src/assets/arrow-right_30exb.png) no-repeat;
        background-size: cover;
        position: relative;
        left: 4px; }
  .r-sales___uV2Ux .r-sales__content-route {
    width: 1190px;
    margin: 0 auto;
    padding-top: 187px; }
    .r-sales___uV2Ux .r-sales__content-route .part {
      margin: 0 auto;
      padding-top: 30px;
      border-bottom: 1px solid #c8cdce;
      position: relative; }
      .r-sales___uV2Ux .r-sales__content-route .part-left {
        float: left; }
        .r-sales___uV2Ux .r-sales__content-route .part-left .img3 {
          width: 404px;
          height: 350px;
          margin-left: 160px; }
      .r-sales___uV2Ux .r-sales__content-route .part-right {
        width: 530px;
        float: right;
        position: relative; }
        .r-sales___uV2Ux .r-sales__content-route .part-right.right1 {
          margin-top: 130px; }
        .r-sales___uV2Ux .r-sales__content-route .part-right.right2 {
          margin-top: 100px; }
        .r-sales___uV2Ux .r-sales__content-route .part-right img {
          float: left;
          width: 183px;
          height: 110px; }
          .r-sales___uV2Ux .r-sales__content-route .part-right img.img4 {
            margin-top: 12px; }
        .r-sales___uV2Ux .r-sales__content-route .part-right p {
          float: left;
          margin: 4px 0 2px 13px;
          font-size: 20px;
          color: #555; }
          .r-sales___uV2Ux .r-sales__content-route .part-right p span {
            font-size: 30px;
            color: #f78c02; }
  .r-sales___uV2Ux .r-sales__content-guide {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 60px 40px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06); }
    .r-sales___uV2Ux .r-sales__content-guide .title {
      text-align: center;
      color: #ff7e00;
      font-size: 32px;
      margin-bottom: 38px;
      clear: both; }
    .r-sales___uV2Ux .r-sales__content-guide-container {
      position: relative; }
      .r-sales___uV2Ux .r-sales__content-guide-container .child {
        width: 495px;
        box-sizing: border-box;
        float: left; }
        .r-sales___uV2Ux .r-sales__content-guide-container .child:first-of-type {
          margin-right: 90px; }
        .r-sales___uV2Ux .r-sales__content-guide-container .child .item:nth-of-type(1) .item-content {
          height: 150px; }
        .r-sales___uV2Ux .r-sales__content-guide-container .child .item:nth-of-type(2) .item-content {
          height: 250px; }
        .r-sales___uV2Ux .r-sales__content-guide-container .child .item:nth-of-type(3) .item-content {
          height: 150px; }
        .r-sales___uV2Ux .r-sales__content-guide-container .child .item-title {
          font-size: 19px;
          color: #666670;
          background-color: #f1f1f1;
          border-radius: 20px;
          padding: 10px 16px;
          display: inline-block;
          margin-bottom: 20px; }
        .r-sales___uV2Ux .r-sales__content-guide-container .child .item-content {
          color: #999;
          line-height: 28px;
          font-size: 14px;
          position: relative; }
          .r-sales___uV2Ux .r-sales__content-guide-container .child .item-content .left {
            float: left;
            width: 140px; }
          .r-sales___uV2Ux .r-sales__content-guide-container .child .item-content .right {
            float: left; }
