@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.ant-modal-content {
  border-radius: 4px !important; }

.r-detail-error {
  padding: 160px;
  text-align: center; }
  .r-detail-error .r-detail-error__err-img {
    width: 351px;
    height: 122px;
    overflow: hidden;
    margin: 0 auto; }
    .r-detail-error .r-detail-error__err-img img {
      width: 100%; }
  .r-detail-error h1 {
    font-size: 30px;
    color: #333; }
  .r-detail-error p {
    font-size: 20px;
    color: #dc3c00; }

.r-detail-wraper {
  margin: 0 auto; }
  .r-detail-wraper .r-detail-wraper__anchor {
    width: 100%;
    height: 61px;
    overflow: hidden;
    line-height: 60px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
    transition: all 0.5s; }
    .r-detail-wraper .r-detail-wraper__anchor.anchor-fixed {
      height: 0px;
      border: none; }
    .r-detail-wraper .r-detail-wraper__anchor .anchor-content {
      width: 1200px;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      margin: 0 auto; }
      .r-detail-wraper .r-detail-wraper__anchor .anchor-content .ant-anchor-ink-ball {
        display: none; }
      .r-detail-wraper .r-detail-wraper__anchor .anchor-content .anchor-link {
        display: inline-block;
        float: left;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
        float: left;
        text-align: center;
        padding: 0 14px;
        height: 60px;
        line-height: 60px;
        cursor: pointer; }
      .r-detail-wraper .r-detail-wraper__anchor .anchor-content .anchor-right {
        float: right;
        line-height: 60px; }
        .r-detail-wraper .r-detail-wraper__anchor .anchor-content .anchor-right .intent-btn {
          width: 120px;
          height: 40px;
          font-size: 18px;
          color: #fff;
          border: none;
          border-radius: 4px; }
          .r-detail-wraper .r-detail-wraper__anchor .anchor-content .anchor-right .intent-btn.consult {
            background-color: #ffa14d;
            margin-right: 20px; }
          .r-detail-wraper .r-detail-wraper__anchor .anchor-content .anchor-right .intent-btn.bargain {
            background-color: #ff6b23;
            margin-right: 40px; }
        .r-detail-wraper .r-detail-wraper__anchor .anchor-content .anchor-right .intent-phone {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.6); }
  .r-detail-wraper .r-detail-wraper__bottom-sale {
    width: 100%;
    height: 90px;
    text-align: center;
    background-color: #f8f8f8; }
    .r-detail-wraper .r-detail-wraper__bottom-sale .txt {
      display: inline-block;
      height: 90px;
      line-height: 90px;
      font-size: 28px;
      font-weight: 400; }
      .r-detail-wraper .r-detail-wraper__bottom-sale .txt .high {
        color: #ff6b23; }
    .r-detail-wraper .r-detail-wraper__bottom-sale .btn {
      display: inline-block;
      width: 160px;
      height: 50px;
      margin-left: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #ffa14d;
      border-radius: 4px;
      color: #f8f8f8;
      font-size: 16px;
      position: relative;
      top: -4px; }
