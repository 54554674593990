@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-intent-detail-gift {
  padding: 20px 80px; }
  .r-intent-detail-gift .logo {
    width: 328px;
    height: 137px;
    margin: -110px auto 0 0; }
    .r-intent-detail-gift .logo img {
      width: 100%; }
  .r-intent-detail-gift .title {
    text-align: center;
    font-size: 30px;
    color: #fd511e;
    margin-top: 16px; }
  .r-intent-detail-gift .sub-title {
    font-size: 18px;
    color: #999;
    text-align: center;
    margin-top: 20px; }
  .r-intent-detail-gift .phone {
    margin: 30px 0 12px; }
  .r-intent-detail-gift .img-verify .brush-code {
    width: 200px;
    float: left; }
  .r-intent-detail-gift .img-verify .brush-wrapper {
    width: 140px;
    height: 40px;
    display: inline-block;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-left: 20px; }
    .r-intent-detail-gift .img-verify .brush-wrapper .brush-img {
      width: 100px;
      float: left; }
    .r-intent-detail-gift .img-verify .brush-wrapper .brush-refresh {
      width: 38px;
      height: 38px;
      float: right;
      background: url(//img1.rrcimg.com/_next/static/src/assets/refresh_8gdJ5.png);
      cursor: pointer; }
  .r-intent-detail-gift .err-msg {
    color: #f00; }
  .r-intent-detail-gift .submit {
    font-size: 18px;
    margin: 12px auto; }
  .r-intent-detail-gift .tip {
    font-size: 14px;
    color: #b1b1b1; }
