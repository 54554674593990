@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-video {
  position: relative;
  overflow: hidden;
  display: inline-block; }
  .r-video .r-video-v {
    position: relative;
    background-color: #000;
    color: #ccc; }
  .r-video .r-video-p {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: .4; }
    .r-video .r-video-p:hover {
      opacity: .5; }
    .r-video .r-video-p:hover span {
      opacity: .9; }
    .r-video .r-video-p span {
      display: block;
      position: absolute;
      top: 50%;
      width: 50px;
      height: 50px;
      background: #fff;
      border-radius: 50%;
      left: 50%;
      margin: -25px 0 0 -25px;
      box-shadow: 0 0 4px #999;
      opacity: .5; }
      .r-video .r-video-p span i {
        display: block;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-left: 26px solid #000;
        margin: 10px 0 0 15px;
        opacity: .5; }
