@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-assurance-warp___2XG1g .r-assurance {
  width: 100%; }
  .r-assurance-warp___2XG1g .r-assurance .r-assurance__banner {
    width: 100%;
    height: 510px; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__banner .content {
      width: 100%;
      height: 612px;
      background: #052f74;
      overflow: hidden;
      position: relative;
      top: -161px;
      z-index: 1; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__banner .content .banner-pic {
        display: block;
        width: 1190px;
        height: 100%;
        margin: auto; }
  .r-assurance-warp___2XG1g .r-assurance .r-assurance__words {
    width: 1190px;
    height: 100px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 1px 1px 26px rgba(107, 116, 139, 0.2);
    margin-top: -109px;
    position: relative;
    z-index: 2; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__words-item {
      width: 297px;
      height: 100px;
      background-color: #fff;
      float: left;
      list-style-type: none;
      text-align: center;
      color: #c07c3d;
      line-height: 100px;
      font-size: 24px; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__words-item .words-item-after {
        position: relative; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__words-item .words-item-after::before {
          content: "";
          display: block;
          position: absolute;
          top: 16px;
          left: 36px;
          width: 64px;
          height: 1px;
          background-color: #ebd1b0; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__words-item .words-item-after::after {
          content: "";
          display: block;
          position: absolute;
          top: 14px;
          left: 100px;
          width: 5px;
          height: 5px;
          background-color: #ebd1b0;
          border-radius: 3px; }
  .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang {
    width: 1190px;
    margin: 0 auto;
    padding-top: 95px;
    position: relative;
    overflow: hidden; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin {
      width: 746px;
      height: 110px;
      margin: 0 auto 30px; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin .txt_icon {
        float: left;
        display: block;
        width: 135px;
        font-size: 118px;
        line-height: 110px;
        color: #ead0af;
        overflow: hidden; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin .txt_icon_r {
          transform: scaleX(-1); }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin-text {
        float: left;
        width: 470px;
        color: #cc996a;
        background: #fff;
        text-align: center; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin-text p {
          font-size: 36px; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin-text span {
          font-size: 18px;
          color: #e0be94; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin-btn-warp {
        text-align: center; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin-btn-warp .baozhangjin-btn {
          background: #cc996a;
          height: 25px;
          width: 105px;
          line-height: 25px;
          font-size: 14px;
          display: inline-block;
          color: #fff;
          margin-left: 18px;
          margin-right: 18px;
          margin-top: 10px;
          border-radius: 15px; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin-btn-warp .baozhangjin-btn .icon-right {
            display: inline-block;
            transform: scaleX(0.8) scaley(0.5);
            background: #fff;
            border: 2px solid #a28362;
            border-radius: 100%;
            margin-left: 3px;
            margin-top: -4px;
            position: relative;
            top: 1px; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhangjin-btn-warp .baozhangjin-btn .icon-right .icon {
              color: #a28362; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhengjin-promise {
      width: 90%;
      margin: 0 auto; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhengjin-promise .promise {
        width: 33%;
        height: 100%;
        float: left;
        text-align: center;
        border-left: 1px solid #e0be94;
        margin: 20px 0 40px; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhengjin-promise .promise:first-of-type {
          border-left: none; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhengjin-promise .promise .promise-content {
          display: inline-block; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhengjin-promise .promise .promise-content .promise-text {
            font-size: 16px;
            display: table-cell;
            vertical-align: middle; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhengjin-promise .promise .promise-content .promise-text .promise-title {
              color: #4a4c53;
              font-size: 30px;
              line-height: 30px;
              margin-bottom: 10px; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__baozhang .baozhengjin-promise .promise .promise-content .promise-text .promise-desc {
              color: #8b8f9c;
              font-size: 18px; }
  .r-assurance-warp___2XG1g .r-assurance .r-assurance__video {
    width: 100%;
    padding: 60px 0 20px 0; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__video.bg-grey {
      background: #fbfcff; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content {
      margin: 0 auto; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .title {
        margin: 0 auto;
        text-align: center;
        color: #ad6e34; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .title h3 {
          font-size: 36px;
          margin-bottom: 10px;
          color: #ad6e34; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .title p {
          font-size: 20px; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .title p .iconfont {
            font-size: 18px;
            color: #e4e8f5;
            display: inline-block;
            margin: auto 12px; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .title p .iconfont.reverse {
              transform: rotate(180deg); }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content {
        text-align: center;
        position: relative;
        padding-top: 40px; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .cheyuan {
          height: 204px;
          margin-top: 30px; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .chekuang {
          height: 313px;
          margin-top: 30px; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .bangzhang {
          height: 365px;
          margin-top: 30px; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img {
          width: 715px;
          height: auto;
          display: inline-block; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img-desc {
          margin: 30px auto 40px;
          font-size: 24px; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img-desc .icongouxuan {
            font-size: 38px;
            color: #b5c6e7; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img-desc .icon-guoxuankuang {
            font-size: 26px;
            color: #f37f48;
            position: relative;
            top: -2px; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img-desc li {
            width: 33%;
            float: left;
            line-height: 30px; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img-desc li img {
              display: inline-block; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img-desc li span {
              font-size: 22px;
              display: inline-block;
              position: relative;
              top: -4px;
              left: 6px; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__video-content .service-circle .content .img-desc .expand {
            font-weight: 700;
            position: relative;
            top: 0;
            left: 0;
            font-size: 40px;
            color: #4a4c53; }
  .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa {
    height: auto;
    background-color: #f9f9f9; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      padding: 30px 0; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .title {
        width: 400px;
        height: 100px;
        margin: 0 auto;
        text-align: center; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .title h3 {
          display: inline-block;
          width: 300px;
          position: relative;
          color: #ad6e34;
          top: 28px;
          font-size: 36px;
          background-color: #f9f9f9; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .title .text-line {
          height: 1px;
          background-color: #e5e5e5;
          margin: 15px 0 0; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid {
        position: relative;
        width: 100%; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .column {
          width: 50%;
          float: left;
          padding: 22px 50px; }
        .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .service-qa-block {
          padding-bottom: 40px; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .service-qa-block h5 {
            font-size: 18px;
            line-height: 28px;
            padding-bottom: 6px;
            color: #333; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .service-qa-block h5 span {
              color: #999;
              margin-right: 10px; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .service-qa-block p {
            font-size: 14px;
            color: #999999;
            padding-left: 40px;
            line-height: 24px; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .service-qa-block p a {
              color: #333; }
            .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .service-qa-block p span {
              float: right; }
          .r-assurance-warp___2XG1g .r-assurance .r-assurance__qa-content .row-fluid .service-qa-block li {
            font-size: 14px;
            color: #999999;
            list-style-type: disc;
            margin-left: 40px;
            line-height: 24px; }
  .r-assurance-warp___2XG1g .r-assurance .r-assurance__select {
    height: 160px;
    line-height: 160px;
    background-color: white; }
    .r-assurance-warp___2XG1g .r-assurance .r-assurance__select-content {
      height: 160px;
      text-align: center; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__select-content span {
        display: inline-block;
        width: 405px;
        line-height: 60px;
        font-size: 24px;
        color: #ad6e34;
        margin-right: 66px;
        vertical-align: middle; }
      .r-assurance-warp___2XG1g .r-assurance .r-assurance__select-content .start-select {
        display: inline-block;
        width: 140px;
        height: 50px;
        line-height: 50px;
        background: #cc996a;
        border: none;
        box-sizing: border-box;
        font-size: 16px;
        cursor: pointer;
        color: #fff;
        vertical-align: middle; }
