@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.test___3GwKA {
  color: red; }
  .test___3GwKA .collect {
    width: 150px;
    height: 150px;
    font-size: 72px;
    color: #000;
    background: #ccc; }
  .test___3GwKA .haggle {
    width: 150px;
    height: 150px;
    background: url(//img1.rrcimg.com/_next/static/src/assets/haggle__kCY3.png); }
