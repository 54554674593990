@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-basic-pic {
  width: 600px;
  min-height: 400px;
  float: left; }
  .r-basic-pic .r-basic-pic__gallery {
    width: 600px;
    height: 400px;
    background: url(//img1.rrcimg.com/_next/static/src/assets/loading_TgPe0.png) no-repeat;
    background-size: cover;
    position: relative; }
    .r-basic-pic .r-basic-pic__gallery .pre {
      float: left;
      width: 58px;
      line-height: 77px;
      cursor: pointer; }
      .r-basic-pic .r-basic-pic__gallery .pre .iconfont {
        font-size: 38px;
        color: #222;
        opacity: 0.3;
        display: block;
        transform: scale(-1);
        margin-right: 6px; }
      .r-basic-pic .r-basic-pic__gallery .pre:hover .iconfont {
        opacity: 0.5; }
    .r-basic-pic .r-basic-pic__gallery .next {
      float: left;
      width: 58px;
      line-height: 77px;
      cursor: pointer; }
      .r-basic-pic .r-basic-pic__gallery .next .iconfont {
        font-size: 38px;
        color: #222;
        opacity: 0.3;
        display: block;
        margin-left: 6px; }
      .r-basic-pic .r-basic-pic__gallery .next:hover .iconfont {
        opacity: 0.5; }
    .r-basic-pic .r-basic-pic__gallery:hover .pre,
    .r-basic-pic .r-basic-pic__gallery:hover .next {
      display: block; }
    .r-basic-pic .r-basic-pic__gallery .pre,
    .r-basic-pic .r-basic-pic__gallery .next {
      display: none;
      height: 89px;
      line-height: 89px;
      background: #000;
      opacity: 0.5;
      position: absolute;
      top: 150px; }
      .r-basic-pic .r-basic-pic__gallery .pre:hover,
      .r-basic-pic .r-basic-pic__gallery .next:hover {
        opacity: 0.9; }
        .r-basic-pic .r-basic-pic__gallery .pre:hover .iconfont,
        .r-basic-pic .r-basic-pic__gallery .next:hover .iconfont {
          opacity: 0.9; }
      .r-basic-pic .r-basic-pic__gallery .pre .iconfont,
      .r-basic-pic .r-basic-pic__gallery .next .iconfont {
        color: #fff;
        opacity: 0.9; }
    .r-basic-pic .r-basic-pic__gallery .pre {
      left: 0; }
    .r-basic-pic .r-basic-pic__gallery .next {
      right: 0; }
    .r-basic-pic .r-basic-pic__gallery a.img-box {
      display: block; }
    .r-basic-pic .r-basic-pic__gallery .img-box {
      width: 600px;
      height: 400px;
      overflow: hidden;
      position: relative;
      cursor: pointer; }
      .r-basic-pic .r-basic-pic__gallery .img-box img {
        width: 100%; }
      .r-basic-pic .r-basic-pic__gallery .img-box .photo-txt {
        width: 100%;
        height: 36px;
        line-height: 36px;
        color: #fff;
        font-size: 14px;
        padding: 0 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); }
        .r-basic-pic .r-basic-pic__gallery .img-box .photo-txt .photo-title {
          opacity: 1;
          float: left; }
        .r-basic-pic .r-basic-pic__gallery .img-box .photo-txt .photo-num {
          float: right; }
          .r-basic-pic .r-basic-pic__gallery .img-box .photo-txt .photo-num .iconfont {
            font-size: 12px;
            margin-right: 6px; }
      .r-basic-pic .r-basic-pic__gallery .img-box .vr-icon {
        width: 90px;
        height: 90px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 155px;
        left: 255px; }
        .r-basic-pic .r-basic-pic__gallery .img-box .vr-icon .panorama-animation {
          width: 100%;
          height: 100%; }
          .r-basic-pic .r-basic-pic__gallery .img-box .vr-icon .panorama-animation::after {
            content: "";
            background-image: url(//img1.rrcimg.com/web-www/common/panorama-2.png);
            background-repeat: no-repeat;
            animation: vrIcon___1n6nF 2s steps(34) infinite;
            width: 100%;
            height: 100%;
            display: block;
            background-size: 100% auto; }

@-webkit-keyframes vrIcon___1n6nF {
  0% {
    background-position: 0 0; }
  50% {
    background-position: 0 0; }
  to {
    background-position: 0 100%; } }

@keyframes vrIcon___1n6nF {
  0% {
    background-position: 0 0; }
  50% {
    background-position: 0 0; }
  to {
    background-position: 0 100%; } }
    .r-basic-pic .r-basic-pic__gallery .video {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      cursor: pointer; }
    .r-basic-pic .r-basic-pic__gallery .recommend {
      position: absolute;
      height: 25px;
      line-height: 25px;
      top: 11px;
      right: 11px; }
      .r-basic-pic .r-basic-pic__gallery .recommend .car-id {
        height: 25px;
        float: right;
        border-radius: 13px;
        background-color: #000;
        opacity: 0.8;
        color: #fff;
        margin-right: 10px;
        font-size: 12px;
        padding: 0 20px; }
      .r-basic-pic .r-basic-pic__gallery .recommend .favorite {
        float: right;
        width: 25px;
        height: 25px;
        overflow: hidden;
        border-radius: 13px;
        background-color: #000;
        opacity: 0.8;
        color: #fff;
        text-align: center;
        cursor: pointer; }
        .r-basic-pic .r-basic-pic__gallery .recommend .favorite .iconfont {
          font-size: 12px; }
  .r-basic-pic .r-basic-pic__thund {
    margin: 10px auto 20px; }
    .r-basic-pic .r-basic-pic__thund .pre {
      float: left;
      width: 58px;
      line-height: 77px;
      cursor: pointer; }
      .r-basic-pic .r-basic-pic__thund .pre .iconfont {
        font-size: 38px;
        color: #222;
        opacity: 0.3;
        display: block;
        transform: scale(-1);
        margin-right: 6px; }
      .r-basic-pic .r-basic-pic__thund .pre:hover .iconfont {
        opacity: 0.5; }
    .r-basic-pic .r-basic-pic__thund .next {
      float: left;
      width: 58px;
      line-height: 77px;
      cursor: pointer; }
      .r-basic-pic .r-basic-pic__thund .next .iconfont {
        font-size: 38px;
        color: #222;
        opacity: 0.3;
        display: block;
        margin-left: 6px; }
      .r-basic-pic .r-basic-pic__thund .next:hover .iconfont {
        opacity: 0.5; }
    .r-basic-pic .r-basic-pic__thund .main {
      width: 484px;
      overflow: hidden;
      float: left; }
      .r-basic-pic .r-basic-pic__thund .main ul {
        width: 9999px;
        transition: all 0.5s; }
      .r-basic-pic .r-basic-pic__thund .main .img-box {
        float: left;
        width: 118px;
        height: 77px;
        overflow: hidden;
        border: 2px solid #fff;
        background: url(//img1.rrcimg.com/_next/static/src/assets/loading_TgPe0.png) no-repeat;
        background-size: cover; }
        .r-basic-pic .r-basic-pic__thund .main .img-box:not(:first-child) {
          margin-left: 4px; }
        .r-basic-pic .r-basic-pic__thund .main .img-box.active {
          border: 2px solid #fd521d; }
        .r-basic-pic .r-basic-pic__thund .main .img-box img {
          width: 100%; }
