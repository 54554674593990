@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-partner-add-success___3WyaG {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  text-align: center; }
  .r-partner-add-success___3WyaG .r-partner-add-success__wrapper___2n4T1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 682px;
    padding: 80px 0 100px;
    border-radius: 4px; }
    .r-partner-add-success___3WyaG .r-partner-add-success__wrapper___2n4T1 .head {
      margin-bottom: 20px; }
      .r-partner-add-success___3WyaG .r-partner-add-success__wrapper___2n4T1 .head .head-icon {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-image: url(//img1.rrcimg.com/_next/static/src/assets/success_EZMlN.png);
        background-size: 100% 100%;
        margin: 0 auto 20px; }
      .r-partner-add-success___3WyaG .r-partner-add-success__wrapper___2n4T1 .head .head-text {
        text-align: center;
        font-size: 20px;
        color: #39B462; }
    .r-partner-add-success___3WyaG .r-partner-add-success__wrapper___2n4T1 .content {
      margin-bottom: 50px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6); }
    .r-partner-add-success___3WyaG .r-partner-add-success__wrapper___2n4T1 .return {
      display: block;
      margin: 0 auto;
      width: 220px;
      height: 38px;
      line-height: 38px;
      color: #fff;
      background-color: #ff6b23;
      border-color: #ff6b23;
      border-radius: 4px;
      font-size: 18px; }
