@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-bangmai___2F3cM {
  background-color: #ebebeb; }
  .r-bangmai___2F3cM .r-bangmai_wrapper {
    background: url(//img2.rrcimg.com/dist/pc/images/bangmai/bangmai-search-list-a4edd647.png) 17px bottom no-repeat;
    width: 1110px;
    min-height: 404px;
    margin: 0 auto;
    position: relative; }
    .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-txt {
      position: absolute;
      top: 90px;
      left: 268px; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-txt .title {
        font-size: 30px;
        color: #fd521d;
        margin-bottom: 27px; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-txt .desc {
        width: 220px;
        font-size: 18px;
        color: #999;
        line-height: 30px; }
    .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form {
      width: 500px;
      min-height: 240px;
      padding: 30px;
      background-color: #f8f8f9;
      position: absolute;
      left: 600px;
      top: 36px; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .ant-col {
        margin-bottom: 10px; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .title {
        font-size: 16px;
        color: #222;
        text-align: left;
        margin-bottom: 10px; }
        .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .title .icon-haoche {
          color: #fd521d; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .hot-line {
        margin-bottom: 10px;
        font-size: 12px;
        color: #666;
        float: right; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .form-item {
        width: 100%;
        border-radius: 0; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .submit {
        position: relative; }
        .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .submit .phone {
          display: inline-block;
          width: 50%;
          float: left; }
        .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .submit .btn {
          display: inline-block;
          background-color: #fd521d;
          color: #fff;
          float: left;
          height: 40px;
          width: 50%;
          font-size: 16px; }
          .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .submit .btn img {
            display: inline-block;
            vertical-align: middle;
            width: 26px;
            height: 26px;
            position: relative;
            top: -1px;
            margin-right: 4px; }
      .r-bangmai___2F3cM .r-bangmai_wrapper .bangmai-form .err {
        color: red; }
