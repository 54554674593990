@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.intentModalExt___2yxZX {
  min-width: 860px;
  padding: 40px; }
  .intentModalExt___2yxZX .title {
    font-size: 16px;
    margin-bottom: 15px; }
    .intentModalExt___2yxZX .title .iconfont,
    .intentModalExt___2yxZX .title .highlight {
      color: #ff5317; }
  .intentModalExt___2yxZX .car-info {
    background-color: #f2f2f2;
    padding: 20px 12px;
    border-radius: 5px; }
    .intentModalExt___2yxZX .car-info .car-img {
      float: left;
      width: 160px;
      height: 100px;
      margin-right: 16px;
      overflow: hidden; }
    .intentModalExt___2yxZX .car-info .car-basic {
      float: left;
      color: #222; }
      .intentModalExt___2yxZX .car-info .car-basic .car-title {
        font-size: 16px;
        font-weight: bold; }
      .intentModalExt___2yxZX .car-info .car-basic .car-price {
        font-size: 13px;
        margin-top: 2px; }
        .intentModalExt___2yxZX .car-info .car-basic .car-price .owner-price {
          display: inline-block;
          padding-right: 12px;
          margin-right: 12px;
          line-height: 14px;
          border-right: 1px solid #cecece; }
      .intentModalExt___2yxZX .car-info .car-basic .car-location {
        font-size: 14px;
        margin-top: 34px; }
        .intentModalExt___2yxZX .car-info .car-basic .car-location .iconfont {
          font-size: 15px;
          margin: 0 6px;
          color: #999; }
        .intentModalExt___2yxZX .car-info .car-basic .car-location .address {
          color: #ff5317; }
        .intentModalExt___2yxZX .car-info .car-basic .car-location .tip {
          color: #999; }
  .intentModalExt___2yxZX .order-info .form-item {
    margin: 10px 0; }
    .intentModalExt___2yxZX .order-info .form-item .label {
      width: 140px;
      float: left;
      color: #666;
      line-height: 42px;
      font-size: 16px; }
      .intentModalExt___2yxZX .order-info .form-item .label .required {
        color: #f00; }
      .intentModalExt___2yxZX .order-info .form-item .label.time {
        float: initial; }
    .intentModalExt___2yxZX .order-info .form-item .value {
      width: 312px;
      height: 42px;
      line-height: 42px;
      float: left; }
    .intentModalExt___2yxZX .order-info .form-item .tip {
      line-height: 42px;
      margin: 0 8px;
      color: #999; }
      .intentModalExt___2yxZX .order-info .form-item .tip.required {
        font-size: 14px;
        color: #ff5317; }
    .intentModalExt___2yxZX .order-info .form-item .inspect-table {
      border: 5px solid #f9f9f9;
      margin-bottom: 30px;
      font-size: 14px; }
      .intentModalExt___2yxZX .order-info .form-item .inspect-table tr {
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        overflow: hidden; }
        .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td {
          width: 140px;
          text-align: center;
          border-left: 1px solid #f0f0f0;
          border-top: 1px solid #f0f0f0; }
          .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td i {
            font-size: 15px; }
          .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td.time {
            color: #ccc; }
          .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td.title-checked {
            color: #27b255; }
          .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td.is-checked {
            background-color: #e7fbe6; }
          .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td.can-checked {
            color: #27b255;
            cursor: pointer; }
          .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td.ask-time {
            text-align: initial;
            font-size: 13px; }
            .intentModalExt___2yxZX .order-info .form-item .inspect-table tr td.ask-time .check-box {
              margin: 0 8px; }
    .intentModalExt___2yxZX .order-info .form-item .c2-add-price {
      padding: 0 12px;
      height: 50px;
      position: relative;
      margin-bottom: 40px; }
      .intentModalExt___2yxZX .order-info .form-item .c2-add-price .price-name {
        display: inline-block;
        width: 120px;
        color: #666;
        line-height: 42px;
        font-size: 16px; }
      .intentModalExt___2yxZX .order-info .form-item .c2-add-price .price-input {
        width: 120px;
        height: 40px;
        border: 1px solid #ddd;
        padding-right: 30px;
        padding-left: 10px; }
      .intentModalExt___2yxZX .order-info .form-item .c2-add-price .price-unit {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: #222;
        left: -25px; }
      .intentModalExt___2yxZX .order-info .form-item .c2-add-price .notes {
        color: #b8b7b7;
        font-size: 12px;
        line-height: 42px; }
      .intentModalExt___2yxZX .order-info .form-item .c2-add-price .price-add {
        cursor: pointer;
        margin-left: 20px;
        margin-top: 5px;
        padding: 5px; }
      .intentModalExt___2yxZX .order-info .form-item .c2-add-price .err-msg {
        color: #fd521d;
        margin: 0 5px; }
  .intentModalExt___2yxZX .order-info .form .submit-btn {
    display: block;
    margin: 0 auto;
    width: 220px;
    height: 50px;
    font-size: 18px;
    background-color: #fd521d; }
