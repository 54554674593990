@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.inspectTit {
  margin: 70px auto 30px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.78);
  text-align: center; }
  .inspectTit .headLine {
    margin-top: 20px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 14px;
    text-align: center; }
    .inspectTit .headLine .line-pd {
      padding-right: 26px; }

.basic_info {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.78);
  line-height: 58px; }
  .basic_info .basic_table {
    border: 1px solid #ececec;
    background: #fff;
    padding: 20px 30px; }
    .basic_info .basic_table .title {
      max-width: 600px; }
    .basic_info .basic_table .table {
      vertical-align: top;
      margin-right: 30px;
      color: #222;
      font-size: 14px; }
      .basic_info .basic_table .table .tr {
        height: 40px; }
        .basic_info .basic_table .table .tr .td {
          display: inline-block;
          width: 173px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #f2f2f2;
          box-sizing: border-box; }
        .basic_info .basic_table .table .tr .td:nth-child(3),
        .basic_info .basic_table .table .tr .td:nth-child(5) {
          margin-left: 35px; }
      .basic_info .basic_table .table .tr:nth-child(odd) .td {
        background-color: #f7f7f7; }
    .basic_info .basic_table .icon-tip-icon2-07d8d303 {
      font-size: 14px;
      padding-left: 3px;
      color: #ABABAB; }
