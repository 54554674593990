@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-gallery {
  width: 1200px;
  margin: 22px auto 0 auto; }
  .r-gallery > h2 {
    font-size: 22px;
    color: #333;
    margin: 12px auto 30px auto; }
  .r-gallery > div {
    border: 1px solid #ededed;
    padding: 40px 20px 110px; }
    .r-gallery > div .seller {
      width: 50%;
      min-height: 300px;
      margin-bottom: 20px;
      float: left;
      border-right: 1px dashed #ddd; }
      .r-gallery > div .seller .owner-img {
        width: 75px;
        height: 75px;
        border-radius: 40px;
        overflow: hidden;
        margin: 0 25px 0 20px;
        float: left; }
        .r-gallery > div .seller .owner-img img {
          width: 100%; }
      .r-gallery > div .seller .owner-desc .name {
        font-size: 18px;
        color: #333;
        font-weight: 400;
        height: 75px;
        line-height: 75px; }
      .r-gallery > div .seller .owner-desc .other {
        font-size: 12px;
        color: #adadad; }
        .r-gallery > div .seller .owner-desc .other i {
          font-size: 12px; }
      .r-gallery > div .seller .desc {
        width: 520px;
        padding-left: 20px;
        font-size: 14px;
        color: #6e6e6e;
        line-height: 25px;
        margin-top: 30px; }
    .r-gallery > div .car-info {
      width: 50%;
      min-height: 330px;
      margin-bottom: 20px;
      float: left; }
      .r-gallery > div .car-info > p {
        font-size: 18px;
        font-weight: 400;
        color: #383838;
        margin-left: 30px;
        margin-top: 20px; }
      .r-gallery > div .car-info > table {
        width: 480px;
        margin-left: 30px;
        margin-top: 40px;
        font-size: 14px;
        color: #6e6e6e; }
        .r-gallery > div .car-info > table tr {
          height: 40px;
          line-height: 40px; }
          .r-gallery > div .car-info > table tr td:nth-child(even) {
            width: 19%; }
          .r-gallery > div .car-info > table tr td:nth-child(odd) {
            width: 31%; }
          .r-gallery > div .car-info > table tr i {
            font-size: 15px;
            color: #bbb;
            margin-left: 4px;
            cursor: pointer; }
            .r-gallery > div .car-info > table tr i:hover {
              color: #ffa14d; }
    .r-gallery > div .car-appearance {
      width: 100%;
      float: left; }
      .r-gallery > div .car-appearance > h2 {
        font-size: 22px;
        color: #222;
        margin-bottom: 15px; }
        .r-gallery > div .car-appearance > h2.mt-60 {
          margin-top: 60px; }
        .r-gallery > div .car-appearance > h2 span {
          margin-left: 10px;
          font-size: 14px;
          color: #979797; }
      .r-gallery > div .car-appearance .img-box {
        float: left;
        background: url(//img1.rrcimg.com/_next/static/src/assets/loading_TgPe0.png) no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative; }
        .r-gallery > div .car-appearance .img-box.big {
          width: 568px;
          height: 378px; }
        .r-gallery > div .car-appearance .img-box.small {
          width: 275px;
          height: 183px; }
        .r-gallery > div .car-appearance .img-box.xsmall {
          width: 160px;
          height: 125px; }
        .r-gallery > div .car-appearance .img-box.ml-10 {
          margin-left: 10px; }
        .r-gallery > div .car-appearance .img-box.ml-20 {
          margin-left: 18px; }
        .r-gallery > div .car-appearance .img-box.mt-10 {
          margin-top: 10px; }
        .r-gallery > div .car-appearance .img-box.mt-20 {
          margin-top: 18px; }
        .r-gallery > div .car-appearance .img-box img {
          width: 100%;
          transition: all 1s; }
          .r-gallery > div .car-appearance .img-box img.blur {
            filter: blur(10px); }
        .r-gallery > div .car-appearance .img-box:hover img {
          transform: scale(1.1); }
        .r-gallery > div .car-appearance .img-box p {
          width: 100%;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          bottom: 0;
          color: #fff;
          font-size: 15px; }
          .r-gallery > div .car-appearance .img-box p .title {
            margin-right: 20px; }
          .r-gallery > div .car-appearance .img-box p.more {
            height: 100%;
            line-height: 183px;
            text-align: center;
            font-size: 20px;
            opacity: 0.7;
            font-weight: normal; }
      .r-gallery > div .car-appearance > ul {
        width: 100%;
        margin-top: 40px; }
        .r-gallery > div .car-appearance > ul li {
          width: 386px;
          float: left;
          padding-left: 28px; }
          .r-gallery > div .car-appearance > ul li p {
            text-align: center;
            font-size: 22px;
            color: #222; }
            .r-gallery > div .car-appearance > ul li p i {
              font-size: 20px;
              margin-right: 8px;
              color: #fd521d;
              text-align: center; }

.r-outer-tip {
  width: 200px;
  font-size: 14px;
  color: #222; }
