@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-partner-popup-wrap___M-sra {
  padding: 40px 50px; }
  .r-partner-popup-wrap___M-sra .title {
    text-align: center;
    font-size: 20px;
    box-sizing: border-box;
    margin-bottom: 30px; }
  .r-partner-popup-wrap___M-sra .content {
    width: 100%; }
    .r-partner-popup-wrap___M-sra .content .twocode-wrap {
      margin-bottom: 25px;
      width: 100%; }
      .r-partner-popup-wrap___M-sra .content .twocode-wrap .code-input {
        width: 230px;
        height: 50px;
        padding: 12px;
        float: left;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
        border: 1px solid #c4c4c4; }
      .r-partner-popup-wrap___M-sra .content .twocode-wrap .code-img {
        float: right;
        height: 50px;
        border: 1px solid #ccc;
        cursor: pointer; }
        .r-partner-popup-wrap___M-sra .content .twocode-wrap .code-img .img-container {
          width: 125px;
          height: 100%;
          float: left; }
        .r-partner-popup-wrap___M-sra .content .twocode-wrap .code-img .refresh-icon {
          width: 30px;
          height: 30px;
          background: url(//img1.rrcimg.com/_next/static/src/assets/refresh-icon_2XY1D.png);
          background-size: 100% 100%;
          margin: 10px 10px 0;
          display: inline-block; }
  .r-partner-popup-wrap___M-sra .submit-btn {
    border: none;
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 18px;
    outline: 0;
    background-color: #f50000;
    background: linear-gradient(to bottom, #ff6000, #f50000); }
