@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-refund {
  width: 100%; }
  .r-refund-content {
    border: 10px solid #ebe3d4;
    width: 1190px;
    margin: 10px auto;
    padding: 10px;
    box-sizing: border-box; }
    .r-refund-content-inner {
      border: 1px solid #ebe3d4;
      padding: 50px; }
      .r-refund-content-inner .part1 {
        color: #c59e5a;
        text-align: center; }
        .r-refund-content-inner .part1 .icon-refund {
          display: inline-block;
          font-size: 143px;
          margin: 50px auto 30px; }
        .r-refund-content-inner .part1 .title {
          text-align: center;
          font-size: 32px;
          font-weight: 700; }
        .r-refund-content-inner .part1 .message {
          text-align: center;
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 50px;
          line-height: 26px; }
      .r-refund-content-inner .part2 {
        border-top: 1px dashed #ebe3d4;
        padding-bottom: 40px;
        color: #222222; }
        .r-refund-content-inner .part2 .title {
          text-align: center;
          font-size: 26px;
          margin-top: 40px;
          font-weight: 700; }
        .r-refund-content-inner .part2 .title-h2 {
          display: inline-block;
          font-size: 18px;
          margin: 20px 0 10px;
          font-weight: 700; }
          .r-refund-content-inner .part2 .title-h2 .icon-bangzhang {
            font-size: 28px;
            color: #c69e65;
            position: relative;
            top: 4px;
            left: -6px; }
        .r-refund-content-inner .part2 .message {
          font-size: 14px;
          margin-left: 30px;
          color: #666;
          line-height: 26px; }
