@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-bangmai-small___DJ4BX {
  width: 300px;
  mim-height: 290px;
  background-color: #ebebeb; }
  .r-bangmai-small___DJ4BX .bangmai-form {
    width: 100%;
    padding: 14px;
    background-color: #f8f8f9;
    min-height: 290px;
    background-color: #efeff0;
    background-image: url(//img1.rrcimg.com/_next/static/src/assets/bm-s-bj_6EnIs.png);
    background-position: 100px 8px;
    background-repeat: no-repeat;
    margin-top: 10px; }
    .r-bangmai-small___DJ4BX .bangmai-form .ant-col {
      margin-bottom: 10px; }
    .r-bangmai-small___DJ4BX .bangmai-form .title {
      font-size: 16px;
      color: #222;
      text-align: left;
      margin-bottom: 10px; }
      .r-bangmai-small___DJ4BX .bangmai-form .title .icon-haoche {
        color: #fd521d; }
    .r-bangmai-small___DJ4BX .bangmai-form .form-item {
      width: 100%;
      border-radius: 0; }
    .r-bangmai-small___DJ4BX .bangmai-form .submit {
      position: relative; }
      .r-bangmai-small___DJ4BX .bangmai-form .submit .phone {
        display: inline-block;
        width: 50%;
        float: left; }
      .r-bangmai-small___DJ4BX .bangmai-form .submit .btn {
        display: inline-block;
        background-color: #fd521d;
        color: #fff;
        float: left;
        height: 40px;
        width: 50%;
        font-size: 16px; }
    .r-bangmai-small___DJ4BX .bangmai-form .err {
      color: red; }
