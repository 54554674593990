@charset "UTF-8";
/* 配色表 */
/*-----变量 超出隐藏-----*/
/* BEM
  -------------------------- */
.r-history___2cdwQ {
  width: 1200px;
  margin: 76px auto 0;
  text-align: center; }
  .r-history___2cdwQ .title {
    color: #262333;
    text-align: center;
    font-size: 34px; }
  .r-history___2cdwQ .container {
    position: relative; }
    .r-history___2cdwQ .container .iconfont {
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background-color: #fff;
      display: inline-block;
      line-height: 48px;
      float: left;
      margin-top: 145px; }
      .r-history___2cdwQ .container .iconfont.left {
        transform: rotate(180deg); }
    .r-history___2cdwQ .container .content {
      margin: 0 12px;
      position: relative;
      float: left; }
      .r-history___2cdwQ .container .content .item {
        float: left;
        display: none;
        transition: display 2s ease-out;
        position: relative; }
        .r-history___2cdwQ .container .content .item.selected {
          display: block; }
        .r-history___2cdwQ .container .content .item-child {
          float: left;
          width: 250px;
          box-sizing: border-box;
          padding: 8px;
          background-color: #fff;
          border-radius: 6px;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12); }
          .r-history___2cdwQ .container .content .item-child:not(:first-child) {
            margin-left: 26px; }
          .r-history___2cdwQ .container .content .item-child .pic {
            width: 100%;
            position: relative; }
            .r-history___2cdwQ .container .content .item-child .pic .sales-search-pic {
              margin: 0;
              width: 100%;
              height: 100%; }
            .r-history___2cdwQ .container .content .item-child .pic .sales-date {
              width: 152px;
              height: 78px;
              margin: 0;
              position: absolute;
              left: 0;
              top: 0; }
              .r-history___2cdwQ .container .content .item-child .pic .sales-date.day2 {
                background-image: url(//img1.rrcimg.com/_next/static/src/assets/2day_1IHqh.png); }
              .r-history___2cdwQ .container .content .item-child .pic .sales-date.day3 {
                background-image: url(//img1.rrcimg.com/_next/static/src/assets/3day_36UE7.png); }
              .r-history___2cdwQ .container .content .item-child .pic .sales-date.day4 {
                background-image: url(//img1.rrcimg.com/_next/static/src/assets/4day_3JAb8.png); }
              .r-history___2cdwQ .container .content .item-child .pic .sales-date.day5 {
                background-image: url(//img1.rrcimg.com/_next/static/src/assets/5day_2OpgX.png); }
              .r-history___2cdwQ .container .content .item-child .pic .sales-date.day7 {
                background-image: url(//img1.rrcimg.com/_next/static/src/assets/7day_1MEil.png); }
          .r-history___2cdwQ .container .content .item-child .intro {
            text-align: left; }
            .r-history___2cdwQ .container .content .item-child .intro .version {
              color: #262333;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 19px;
              margin: 8px 0;
              font-weight: bold; }
            .r-history___2cdwQ .container .content .item-child .intro .time {
              color: #afafaf;
              font-size: 14px; }
            .r-history___2cdwQ .container .content .item-child .intro .pc {
              position: relative;
              overflow: hidden;
              line-height: 32px;
              margin: 14px auto 30px; }
              .r-history___2cdwQ .container .content .item-child .intro .pc .price {
                float: left;
                color: #ff6b23;
                font-size: 28px; }
              .r-history___2cdwQ .container .content .item-child .intro .pc .compare {
                float: right;
                width: 133px;
                height: 32px;
                background: url(//img1.rrcimg.com/_next/static/src/assets/sell-more@2x_nt8mw.png) no-repeat;
                background-size: cover; }
                .r-history___2cdwQ .container .content .item-child .intro .pc .compare .c-price {
                  color: #fff;
                  text-align: center;
                  display: inline-block;
                  width: 91px;
                  font-size: 20px;
                  height: 32px;
                  line-height: 32px;
                  margin: 0 0 1px 1px; }
